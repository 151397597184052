import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";
import services from "../../services/services";

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [tokenValid, setTokenValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const token = query.get("token");
  const [apiEndPoint, setAPIEndpoint] = useState(services.fetchServerAPIEndPoint());

  const [showLoader, setShowLoader] = useState(0);

  const handleClickNavigate = () => {
    window.location.href = '/login';
  }

  useEffect(() => {
    const verifyToken = async () => {
      try {

        await axios.post(
          `${apiEndPoint}/auth/verify-resetpwd-token`,
          { token },
          { headers: { 'Content-Type': 'application/json' } }
        );
        setTokenValid(true);
      } catch (error) {
        if (error.response) {
          setErrorMessage('Invalid or expired token. Please request a new password reset.');
        } else {
          setErrorMessage('An error occurred. Please try again later.');
        }
      }
    };

    if (token) {
      verifyToken();
    } else {
      setErrorMessage('Invalid or missing token.');
    }
  }, [token]);

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!password.trim()) {
      newErrors.password = 'Password is required.';
      valid = false;
    }

    if (!confirmPassword.trim()) {
      newErrors.confirmPassword = 'Confirm Password is required.';
      valid = false;
    } else if (password.trim() !== confirmPassword.trim()) {
      newErrors.confirmPassword = 'Passwords do not match.';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleResetPassword = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      setShowLoader(1);
      await axios.post(
        `${apiEndPoint}/auth/update-password-with-token/`,
        { token, newPassword: password.trim() },
        { headers: { 'Content-Type': 'application/json' } }
      );
      setShowLoader(0);
      setSuccessMessage('Your password has been reset successfully.');
      setErrors({});
      setPassword('');
      setConfirmPassword('');

      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      setShowLoader(0);
      if (error.response) {
        setErrorMessage('Failed to reset password. Please try again.');
      } else {
        setErrorMessage('An error occurred. Please try again later.');
      }
      setSuccessMessage('');
    }
  };

  return (
    <main>
      <section>
        <div className="container">
          <div className="d-flex justify-content-center my-5">.


            <div className="loginwrap border p-4">

              <div className="text-center d-grid justify-content-center mb-4">
                <img src="images/logo.svg" alt="" className="m-auto" />
                <h3 className="text-center">Reset password</h3>

              </div>




              <div className='loginmain'>

              {errorMessage && <div className='text-danger mb-1'>{errorMessage}</div>}
              {tokenValid ? (
                <>
                  <div className="mb-3">
                    <form className='d-grid'>
                    {showLoader === 1 ?
                    <div class="loadwrapper">
                        <div class="clock"><div class="circle"></div></div>
                    </div>
                    : '' }

                      <label>
                        <input
                          type='password'
                          className={`form-control mb-3 ${errors.password ? 'is-invalid' : ''}`}
                          placeholder='New Password *'
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        {errors.password && <div className='invalid-feedback'>{errors.password}</div>}
                      </label>
                      <label>
                        <input
                          type='password'
                          className={`form-control mb-3 ${errors.confirmPassword ? 'is-invalid' : ''}`}
                          placeholder='Confirm Password *'
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        {errors.confirmPassword && <div className='invalid-feedback'>{errors.confirmPassword}</div>}
                      </label>


                    </form>
                  </div>
                  <button className="btn btn-primary w-100 mb-2" onClick={handleResetPassword}>
                    Reset Password
                  </button>

                  <button onClick={() => handleClickNavigate()} className="btn btn-primary w-100">Login</button>

                    {/* <div class="w-100 text-center">
                      <a href="/Login">Login</a>
                    </div> */}

                  {successMessage && <div className='text-success mt-3'>{successMessage}</div>}
                </>
              ) : (
                <div className='text-start text-danger'> <button onClick={() => handleClickNavigate()} className="btn btn-primary w-100 mb-2">Login</button></div>
              )}
            </div>



            </div>





          </div>
        </div>
      </section>
    </main>
  );
};

export default ResetPassword;

import React,{ useEffect, useState } from "react";
import UserDashBoardDateNavigator from "./userDashboardDay";
import UserDashBoardDateWeekNavigator from "./userDashboardWeek";
import UserDashBoardDateMonthNavigator from "./userDashboardMonth";
import UserDashBoardDateQuarterNavigator from "./userDashboardFQuarter";
import Notification from "../../common/notification";
import services from "../../../services/services";


const UserDashboard = () => {
    const [apiEndPoint, setAPIEndpoint] = useState(services.fetchServerAPIEndPoint());
    const [useridval, setuseridval] = useState(sessionStorage.getItem('userIdval'));
    const [companyFilterData, setCompaniesFilterData] = useState([]);
    const [showLoader, setShowLoader] = useState(0);

    useEffect(() => {
        // Fetch notifications when the component mounts
        
        //fetchNotifications();
        getCompanyId(sessionStorage.getItem("userIdval"));
      }, []); // Add any dependencies if required

      const getCompanyId = (logid) => {
        services.getCompanyList(1).then((res) => {
            if(res.data.type === "success"){
                var fullcompanyList = res.data.data;
                setCompaniesFilterData(res.data.data);
                
                services.getUsersInfoById(logid).then((res) => {
                    if(res.data.type === 'success'){
                        for(var i=0; i < fullcompanyList.length; i++){
                            if(fullcompanyList[i].id === res.data.data.company_id){
                                //setUserCompanyFilterData(fullcompanyList[i].company_name);
                                sessionStorage.setItem("userCompanyName", fullcompanyList[i].company_name);
                                sessionStorage.setItem("userCompanyId", fullcompanyList[i].id);
                                
                            }
                        }
                    }
                });
  
            }
        });
    }

    
    
    
      


   useEffect(() => 
   {

   },[]);  
  return(
  <>
    <main>


<section>
   <div class="container">
        {/* <h2 class="mainHeadline">
            Dashboard
        </h2> */}

        {/* <div class="rejpop-nitification">
            <div class="d-flex justify-content-start align-items-center gap-4">
                <span>Timesheet: <span>12 – 18 Jan 2024</span> | <span>Rejected for resubmission</span></span>
                <a href="#" class="review">Click here to review </a>
            </div>
            <a href="#" class="close">X</a>
        </div> */}

                      <div className="notification-list mb-5">
                         
                      </div>
                      

        <div class="dashbmainuser mb-5">
            {/* <div class="db-wrap">
                 <div class="d-flex align-items-center justify-content-between mb-3">
                    <div class="d-flex justify-content-start">
                        <div class="prev-after-wrap">
                            <a href="#"> <span> <img src="images/left.png" alt="" /></span></a>
                            <a href="#"><span> <img src="images/right.png" alt="" /></span></a>
                        </div>
                        <div class="d-grid">
                            <h2>Wednesday, 14 Feb</h2>
                        </div>
                    </div>
                    <strong>Day</strong>
                </div> 
                

                <img src="images/addition/adddayimg.png" width="100%" alt="" />

                <div class="btn-group mt-3 ">
                    <a href="# " class="btn btn-primary ">Add Day Entry</a>
                </div>

            </div> */}
            <UserDashBoardDateNavigator></UserDashBoardDateNavigator>


            <UserDashBoardDateWeekNavigator></UserDashBoardDateWeekNavigator>
            {/* <div class="db-wrap">
                <div class="d-flex align-items-center justify-content-between mb-3">
                    <div class="d-flex justify-content-start">
                        <div class="prev-after-wrap">
                            <a href="#"> <span> <img src="images/left.png" alt="" /></span></a>
                            <a href="#"><span> <img src="images/right.png" alt="" /></span></a>
                        </div>
                        <div class="d-grid">
                            <h2>Wednesday, 14 Feb</h2>
                        </div>
                    </div>
                    <strong>Week</strong>
                </div>

                <img src="images/addition/addweekimg.png" width="100%" alt="" />

                <div class="btn-group mt-3 ">
                    <a href="# " class="btn btn-primary ">Add Week Entry</a>
                </div>

            </div> */}
            {/* <div class="db-wrap">
                <div class="d-flex align-items-center justify-content-between mb-3">
                    <div class="d-flex justify-content-start">
                        <div class="prev-after-wrap">
                            <a href="#"> <span> <img src="images/left.png" alt="" /></span></a>
                            <a href="#"><span> <img src="images/right.png" alt="" /></span></a>
                        </div>
                        <div class="d-grid">
                            <h2>12 Feb to 16 Feb </h2>
                        </div>
                    </div>
                    <strong>Month</strong>
                </div>

                <img src="images/addition/month.png" width="100%" alt="" />

              

            </div> */}


            <UserDashBoardDateMonthNavigator></UserDashBoardDateMonthNavigator>
    
            {/* <div class="db-wrap">
                <div class="d-flex align-items-center justify-content-between mb-3">
                    <div class="d-flex justify-content-start">
                        <div class="prev-after-wrap">
                            <a href="#"> <span> <img src="images/left.png" alt="" /></span></a>
                            <a href="#"><span> <img src="images/right.png" alt="" /></span></a>
                        </div>
                        <div class="d-grid">
                            <h2>Jan -Mar (2024)</h2>
                        </div>
                    </div>
                    <div class="d-flex justify-content-start align-items-center gap-2">
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                            <small class="form-check-label" for="flexRadioDefault1">
                                Quarterly
                            </small>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                            <small class="form-check-label" for="flexRadioDefault1">
                                Monthly
                            </small>
                        </div>
                        <div class="form-check">
                            <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                            <small class="form-check-label" for="flexRadioDefault1">
                                Yearly
                            </small>
                        </div>
                    </div>
                </div>

                <img src="images/addition/yearly.png" width="100%" alt=""/>

                

            </div> */}
            <UserDashBoardDateQuarterNavigator></UserDashBoardDateQuarterNavigator>
        </div>
        {showLoader === 1 ?
        <div class="loadwrapper">
            <div class="clock"><div class="circle"></div></div>
        </div>
        : '' }
    </div>


</section>

</main>
  </>
)};

UserDashboard.propTypes = {};

UserDashboard.defaultProps = {};

export default UserDashboard;

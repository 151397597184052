import React, { useState, useEffect } from "react";
import services from "../../services/services";
import $ from 'jquery';

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const [showLoader, setShowLoader] = useState(0);

  const handleClickNavigate = () => {
    window.location.href = '/login';
  }

  useEffect(() => {
    sessionStorage.setItem("userRole", "");
    sessionStorage.setItem("userIdval", "");
  }, []);

  const validateEmail = (email) => {
    // Simple regex for email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email.trim()) {
      setError("Email is required.");
      setMessage("");
      return;
    }

    if (!validateEmail(email.trim())) {
      setError("Please enter a valid email address.");
      setMessage("");
      return;
    }

    try {

      setShowLoader(1);
      const trimmedEmail = email.trim();
      const requestJsonForgotPwd = {
        email_address: trimmedEmail,
      };
      const response = await services.forgotPasswordReq(requestJsonForgotPwd);
      const data = response;//await response.json();
      if (data.message == "Password updated successfully" || data.message == "Password reset token sent successfully") {
        setMessage(data.message);
        setShowLoader(0);
        $("#mysuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
          $("#mysuccessMessage").slideUp(500);
        });
        setError(""); // Clear any previous errors

        // setTimeout(() => {
        //     window.location.href = "/profile";
        // }, 5000);

      } else {
        setShowLoader(0);
        setError(data.message);
      }



    } catch (err) {
      setShowLoader(0);
      setError('An error occurred. Please try again later.');
      setMessage(""); // Clear any previous success message
    }
  };

  return (
    <>
      <main>
        <section>
          <div className="container">
            <div className="d-flex justify-content-center py-5">
              <div className="loginwrap border p-4" style={{ maxWidth: "400px" }}>
                <div className="text-center d-grid justify-content-center mb-4">
                  <img src="images/timesheet-logo.png" alt="" className="m-auto mb-2" />
                  <h5 className="text-center my-2">Forgot your password?</h5>
                  <p className="py-2">
                    Enter the email address you use to sign in, and we’ll send you a link to reset your password.
                  </p>
                </div>
                <form onSubmit={handleSubmit}>

                  {showLoader === 1 ?
                    <div class="loadwrapper">
                      <div class="clock"><div class="circle"></div></div>
                    </div>
                    : ''}

                  <div className="mb-3">
                    <input
                      type="text"
                      className={`form-control ${error ? 'is-invalid' : ''}`}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Work Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}

                    />
                    {error && <div className="invalid-feedback">{error}</div>}


                  </div>
                  {message && <div id="mysuccessMessage" className="alert alert-success">{message}</div>}
                  <div className="d-flex justify-content-start gap-2">
                    <button type="submit" className="btn btn-primary">Send Link</button>
                    {/* <a onClick={() => handleClickNavigate()} className="btn btn-primary">Login</a> */}
                    <button type="button" onClick={() => handleClickNavigate()} className="btn btn-primary">Login</button>
                    {/* <a href="/Login">Login</a> */}


                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default ForgotPassword;

import React,{useEffect, useState} from "react";

export function DeleteConfirmationPopup({ message, onConfirm, onCancel }) {
  return (
    <div className="popup">
      <div className="popup-content">
        <p>{message}</p>
        <button onClick={onConfirm} class="btn btn-primary me-2">Yes</button>
        <button onClick={onCancel} class="btn btn-outline-dark mx-0">No</button>
      </div>
    </div>
  );
}
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, addYears, subYears, startOfYear, endOfYear } from 'date-fns';
import services from '../../../services/services';
import ReactECharts from 'echarts-for-react';

const UserDashBoardDateQuarterNavigator = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [viewType, setViewType] = useState('Quarterly');

  const [barChartOptions, setBarChartOptions] = useState(null);
  const [weekStartDatePost, setWeekStartDatePost] = useState(null);
  const [weekEndDatePost, setWeekEndDatePost] = useState(null);
  const [loading, setLoading] = useState(false);
  const [resourceProject, setResourceProject] = useState("");
  const [projectWiseStatsData, setProjectWiseStatsData] = useState([]);
  const [pieChartOptions, setPieChartOptions] = useState(null);
  const [doughnutChartOptions, setDoughnutChartOptions] = useState(null);
  const [useridval, setuseridval] = useState(sessionStorage.getItem('userIdval'));
  const [toDayDate, settoDayDate] = useState(new Date(new Date().toISOString().split('T')[0]));


  // Utility function to convert "HH:MM" to minutes
  const convertToMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours * 60 + minutes;
  };
  // Helper function to convert "HH:MM" format to minutes
  const convertTimeToMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };
  /*
    useEffect(() => {
      const weekStart = startOfMonth(currentDate, { startOfMonth: 1 });
      const weekEnd = endOfMonth(currentDate, { endOfMonth: 1 });
      setWeekStartDatePost(format(weekStart, 'yyyy-MM-dd'));
      setWeekEndDatePost(format(weekEnd, 'yyyy-MM-dd'));
    }, [currentDate,viewType]);
  */
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const requestJson4 = {
        weekStartDate: weekStartDatePost,
        weekEndDate: weekEndDatePost,
        isBillable: "",
        projectIds: resourceProject ? [resourceProject] : [],
        userId: useridval,
        //companyId: "2"
      };

      try {

        //const result4 = await services.fetchProjectWiseStatsUser(requestJson4);
        //const result5 =   await services.fetchProjectWiseStatsUser(requestJson4);
        const result5 =   await services.fetchProjectWiseStatsUserDashboard(requestJson4);
        const result4 = result5.projects;

        if (result4 && Array.isArray(result4) && result4.length > 0) {

          const projectNames = result4.map(item => item.projectName);
          const billableHours = result4.map(item => convertTimeToMinutes(item.projectWiseBillableHours));
          const nonBillableHours = result4.map(item => convertTimeToMinutes(item.projectWiseNonBillableHours));



          const pieChartData = result4.map((item) => ({
            name: item.projectName,
            value: convertToMinutes(item.projectWiseTotalHours),
            //value: item.totalHours,
          }));
          const doughnutChartOptions = {
            title: {
              text: '',
              left: 'center',
            },
            tooltip: {
              trigger: 'item',
              formatter: function (params) {
                      const totalMinutes = params.value;
                      const hours = Math.floor(totalMinutes / 60);
                      const minutes = totalMinutes % 60;
                      const percentage = params.percent.toFixed(2); // Get the percentage directly
                    // return `${params.name}: ${hours}h ${minutes}m (${percentage}%)`;
                    return `${params.name}: ${hours}h ${minutes}m`;
                  },
            },
            legend: {
              orient: 'horizontal',
              left: 'left',
              top: '2%', // Center the legend vertically
              padding: [0, 20], // Add padding on the right side
              formatter: function (name) {
                // Find the data item by name
                const dataItem = pieChartData.find(item => item.name === name);
                if (dataItem) {
                  const totalMinutes = dataItem.value;
                  const hours = Math.floor(totalMinutes / 60);
                  const minutes = totalMinutes % 60;
                  const percentage = (dataItem.value / pieChartData.reduce((acc, item) => acc + item.value, 0) * 100).toFixed(2);
                  return `${name}: ${hours}h ${minutes}m (${percentage}%)`;
                }
                return name;
              },
            },
            series: [
              {
                name: 'Total Hours',
                top: '15%',
                type: 'pie',
                radius: ['40%', '90%'], // Adjust inner and outer radius
                avoidLabelOverlap: true,
                data: pieChartData,
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                  },
                },
                label: {
                  formatter: '{d}%',

                  position: 'inside', // Position label inside the sector
                  color: '#fff', // Text color inside the sector
                  fontSize: 14, // Font size for the label
                },
                labelLine: {
                  show: false, // Hide the line connecting the label to the sector
                },
              },
            ],
          };

          // Set the options to state or directly render them in the component
          setBarChartOptions(barChartOptions);
          setPieChartOptions(pieChartOptions);
          setDoughnutChartOptions(doughnutChartOptions)


        } else {
          setBarChartOptions(null);
          setDoughnutChartOptions(null);
          console.error('Invalid data structure:', result4);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    if (weekStartDatePost && weekEndDatePost) {
      fetchData();
    }
  }, [weekStartDatePost, weekEndDatePost, resourceProject, viewType]);

  const handlePrevious = () => {
    if (viewType === 'Quarterly') {
      setCurrentDate((prevDate) => new Date(subMonths(prevDate, 3)));
    } else if (viewType === 'Monthly') {
      setCurrentDate((prevDate) => new Date(subMonths(prevDate, 1)));
    } else if (viewType === 'Yearly') {
      setCurrentDate((prevDate) => new Date(subYears(prevDate, 1)));
    }
  };

  const handleNext = () => {

    if (viewType === 'Quarterly') {
      const nextQuarterDate = new Date(currentDate);
      nextQuarterDate.setMonth(nextQuarterDate.getMonth() + 3);

      if (nextQuarterDate.getMonth() > toDayDate.getMonth() && nextQuarterDate.getFullYear() >= toDayDate.getFullYear()) {
        //dot allow
      } else {
        setCurrentDate((prevDate) => new Date(addMonths(prevDate, 3)));
      }
      
    } else if (viewType === 'Monthly') {

      const nextMonthDate = new Date(currentDate);
      nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);

      if (nextMonthDate.getMonth() > toDayDate.getMonth() && nextMonthDate.getFullYear() >= toDayDate.getFullYear()) {
        //dot allow
      } else {
        setCurrentDate((prevDate) => new Date(addMonths(prevDate, 1)));
      }
      
    } else if (viewType === 'Yearly') {
      const nextYearDate = new Date(currentDate);
      nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);

      if (nextYearDate.getFullYear() > toDayDate.getFullYear()) {
        // Prevent moving to next year
        //dot allow
      } else {
        setCurrentDate((prevDate) => new Date(addYears(prevDate, 1)));
      }
      
    }
  };

  const handleViewTypeChange = (event) => {
    setViewType(event.target.value);
  };
  /*
    let displayDate;
    if (viewType === 'Quarterly') {
      const startQuarterMonth = Math.floor(currentDate.getMonth() / 3) * 3;
      const startQuarter = new Date(currentDate.getFullYear(), startQuarterMonth, 1);
      const endQuarter = new Date(currentDate.getFullYear(), startQuarterMonth + 2, endOfMonth(startQuarter).getDate());
      displayDate = `${format(startQuarter, 'MMM')} - ${format(endQuarter, 'MMM yyyy')}`;
    } else if (viewType === 'Monthly') {
      const monthStart = startOfMonth(currentDate);
      const monthEnd = endOfMonth(currentDate);
      displayDate = `${format(monthStart, 'MMM yyyy')}`;
    } else if (viewType === 'Yearly') {
      const yearStart = startOfYear(currentDate);
      const yearEnd = endOfYear(currentDate);
      displayDate = `${format(yearStart, 'yyyy')}`;
    }
      */


  const displayDate = useMemo(() => {
    if (viewType === 'Quarterly') {
      const startQuarterMonth = Math.floor(currentDate.getMonth() / 3) * 3;
      const startQuarter = new Date(currentDate.getFullYear(), startQuarterMonth, 1);
      //const endQuarter = new Date(currentDate.getFullYear(), startQuarterMonth + 2, endOfMonth(startQuarter).getDate());

      const endQuarter = new Date(currentDate.getFullYear(), startQuarterMonth + 2, new Date(currentDate.getFullYear(), startQuarterMonth + 3, 0).getDate());

      //const weekStart = startOfMonth(currentDate, { startQuarterMonth: 1 });
      //const weekEnd = endOfMonth(currentDate, { endOfMonth: 1 });
      setWeekStartDatePost(format(startQuarter, 'yyyy-MM-dd'));
      setWeekEndDatePost(format(endQuarter, 'yyyy-MM-dd'));
//alert(endQuarter);
      return `${format(startQuarter, 'MMM')} - ${format(endQuarter, 'MMM, yyyy')}`;

    } else if (viewType === 'Monthly') {
      const monthStart = startOfMonth(currentDate);
      const monthEnd = endOfMonth(currentDate);

      setWeekStartDatePost(format(monthStart, 'yyyy-MM-dd'));
      setWeekEndDatePost(format(monthEnd, 'yyyy-MM-dd'));

      return format(monthStart, 'MMM yyyy');
    } else if (viewType === 'Yearly') {
      const yearStart = startOfYear(currentDate);
      const yearEnd = endOfYear(currentDate);

      setWeekStartDatePost(format(yearStart, 'yyyy-MM-dd'));
      setWeekEndDatePost(format(yearEnd, 'yyyy-MM-dd'));

      return format(yearStart, 'yyyy');
    }
  }, [currentDate, viewType]);

  return (




    <div class="db-wrap">
      <div class="d-flex align-items-center justify-content-between mb-3">
        <div class="d-flex justify-content-start">
          <div class="prev-after-wrap">
            <a onClick={handlePrevious}> <span> <img src="images/left.png" alt="" /></span></a>
            <a onClick={handleNext}><span> <img src="images/right.png" alt="" /></span></a>
          </div>
          <div class="d-grid">
            <h2>{displayDate}</h2>
          </div>
        </div>
        <div class="d-flex justify-content-start align-items-center gap-2">
          <div class="form-check">
            <input class="form-check-input" type="radio"
              value="Quarterly"
              checked={viewType === 'Quarterly'}
              onChange={handleViewTypeChange} />
            <small class="form-check-label" for="flexRadioDefault1">
              Quarterly
            </small>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio"
              value="Monthly"
              checked={viewType === 'Monthly'}
              onChange={handleViewTypeChange} />
            <small class="form-check-label" for="flexRadioDefault1">
              Monthly
            </small>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio"
              value="Yearly"
              checked={viewType === 'Yearly'}
              onChange={handleViewTypeChange} />
            <small class="form-check-label" for="flexRadioDefault1">
              Yearly
            </small>
          </div>
        </div>
      </div>

      {/* <img src="images/addition/yearly.png" width="100%" alt="" /> */}

      {doughnutChartOptions ? <ReactECharts option={doughnutChartOptions} style={{ height: 400, width: '100%' }} />
      : 
      <div className='d-flex justify-content-center align-items-center' style={{"height":"400px"}}>
        <div className="d-grid text-center py-3">
          <img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
          <span> No Data Available</span>
        </div>
      </div>
      }



    </div>

  );
};

export default UserDashBoardDateQuarterNavigator;

import React, { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import services from "../../../services/services";
import AddClient from "../../modals/add-client";
import AddProject from "../../modals/add-project";
import AddTask from "../../modals/add-task";
import $ from 'jquery';
import Pagination from "../../../utility/Pagination/Pagination";
import { useTable, useSortBy, useExpanded } from 'react-table';
import ProjectManagementHeader from "./project-management-header";

function ConfirmationPopup({ message, onConfirm, onCancel }) {
    return (
        <div className="popup">
            <div className="popup-content ">
                <p>{message}</p>
                <button onClick={onConfirm} class="btn btn-primary me-2">Yes</button>
                <button onClick={onCancel} class="btn btn-outline-dark">No</button>
            </div>
        </div>
    );
}

const ManageTasks = () => {

    const projectTabRef = useRef(null);
    const clientTabRef = useRef(null);
    const taskTabRef = useRef(null);

    const location = useLocation();

    const useridval = useState(sessionStorage.getItem('userIdval'));
    const [loggedUser, setLoggedUser] = useState(sessionStorage.getItem('userRole'));
    const [companyid, setCompanyId] = useState(sessionStorage.getItem('userCompanyId'));
    const [adminCompanyid, setAdminCompanyId] = useState(sessionStorage.getItem('adminCompany'));
    const [clientStatus, setClientStatus] = useState(1);
    const [clientStatusIsChecked, setClientStatusIsChecked] = useState(0);
    const [projectStatusIsChecked, setProjectStatusIsChecked] = useState(0);
    const [clientListInfo, setClientListInfo] = useState([]);
    const [selectedClientIdForEdit, setSelectedClientIdForEdit] = useState(null);
    const [selectedProjectIdForEdit, setSelectedProjectIdForEdit] = useState(null);
    const [selectedTaskIdForEdit, setSelectedTaskIdForEdit] = useState(null);
    const [selectedDynamicValue, setSelectedDynamicValue] = useState(null);
    const [sortTaskConfig, setSortTaskConfig] = useState({ key: 'task.name', direction: 'ASC' });

    const [projectListInfo, setProjectListInfo] = useState([]);
    const [ProjectStatus, setProjectStatus] = useState(1);
    const pagenum = 1;
    const pagesize = 20;
    const ITEMS_PER_PAGE = 20; // Per page show the data pagination
    //const Pagesize=5;
    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const [clientIdforUpdate, setClientIdforUpdate] = useState(null);
    const [clientNameforUpdate, setClientNameforUpdate] = useState(null);
    const [companyIdforUpdate, setCompanyIdforUpdate] = useState(null);
    const [clientStatustoUpdate, setClientStatustoUpdate] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    const [projectIdforUpdate, setProjectIdforUpdate] = useState(null);
    const [projectStatustoUpdate, setProjectStatustoUpdate] = useState(null);

    const [taskIdforUpdate, setTaskIdforUpdate] = useState(null);
    const [taskStatustoUpdate, setTaskStatustoUpdate] = useState(null);
    const [taskStatus, setTaskStatus] = useState('');

    const [taskListInfo, setTaskListInfo] = useState([]);
    const [taskHoursListInfo, setTaskHoursListInfo] = useState([]);
    const [taskFullDetails, setTaskFullDetails] = useState([]);
    const [mergedData, setMergedData] = useState([]);
    const [activeInactiveIsChecked, setActiveInactiveIsChecked] = useState(false);

    const [showLoader, setShowLoader] = useState(0);
    const [clientForProject, setClientForProject] = useState('');


    const [searchTxt, setSearchTxt] = useState('');
    const [searchProj, setSearchProj] = useState('');
    const [searchTaskWithProjectId, setSearchTaskWithProjectId] = useState('');
    const [searchTaskWithBillable, setSearchTaskWithBillable] = useState('');
    const [searchTaskWithStatus, setSearchTaskWithStatus] = useState([]);
    const [formMode, setFormMode] = useState(null);
    const [projectidfortask, setprojectidfortask] = useState(null);


    function getClientsList(mycompanyid, clientstatusval, pagenumval, itemsperpage, managerid) {
        setShowLoader(1);
        services.getClientsList(mycompanyid, clientstatusval, pagenumval, itemsperpage, managerid).then((res) => {
            if (res.data.type === 'success') {
                setClientListInfo(res.data.data);
                setTotalItems(res.data.totalRecords);
                settotalPages(Math.ceil(res.data.totalRecords / ITEMS_PER_PAGE));
                setCurrentPage(pagenumval);
            }
            setShowLoader(0);
        });
    }

    const handleTaskPageChange2 = (offset, triggerevent) => {
        setCurrentPage(offset);
    }

    useEffect(() => {
        var clientFilterCompanyVal = '';
        var managerid='';
        var clientForProject = '';
        var projectStatus = '';
        var clientStatus = '';
        if(loggedUser.toLowerCase() === 'manager'){
            clientFilterCompanyVal = companyid;
        }
        if(loggedUser.toLowerCase() === 'admin'){
            clientFilterCompanyVal = adminCompanyid;
        }
        //alert('1');
        getProjects(clientForProject, projectStatus, 'asc', currentPage, 1000, managerid, clientStatus, clientFilterCompanyVal);

    },[]);

    useEffect(() => {
        var clientFilterCompanyVal = '';
        var managerid='';
        var clientlistid = '';
        var clientstatus = '';
        if(loggedUser.toLowerCase() === 'manager'){
            clientFilterCompanyVal = companyid;
        }
        if(loggedUser.toLowerCase() === 'admin'){
            clientFilterCompanyVal = adminCompanyid;
        }
        //alert(searchProj);
        myTasksInfo(taskStatus, searchProj, searchTxt, currentPage, ITEMS_PER_PAGE, clientFilterCompanyVal);

    }, [location, searchTxt, taskStatus, searchProj, currentPage,projectListInfo,sortTaskConfig]);


    const isEmpty = (value) => {
        return value === null || String(value).trim() === '';
    }

    const handleCancel = () => {
        setShowPopup(false);
    };

    const getProjects = async (clientlistid, projectstatusval, sortingorder, pagenumval, itemsperpage, managerid, clientStatus, usercompanyval) => {
        setShowLoader(1);
        setSearchProj('');
        try {
            const res = await services.getProjectsDetailsList(clientlistid, projectstatusval, sortingorder, pagenumval, itemsperpage, managerid, clientStatus, usercompanyval);
            if (res.data.type === 'success') {
                setProjectListInfo(res.data.data);
                //alert('157 - '+res.data.data.length);
            }
        } catch (error) {
            console.error('Error fetching project details:', error);
        }
        finally {
            setShowLoader(0);
        }
    };

    const loadTasks = async (taskstatus) => {
        //alert("378")
        var reqCompany = '';
        if (loggedUser.toLowerCase() === 'manager') {
            reqCompany = companyid;
        }

        if (loggedUser.toLowerCase() === 'admin') {
            reqCompany = adminCompanyid;
        }

        if (sessionStorage.getItem('userRole') === 'Admin') {
            getClientsList(companyid, 1, 1, ITEMS_PER_PAGE, '');
        }
        else {
            getClientsList(companyid, 1, 1, ITEMS_PER_PAGE, sessionStorage.getItem('userIdval'));
        }
        setClientForProject('');
        var managerid = '';
        /*if(loggedUser.toLowerCase() === 'manager'){
            managerid = sessionStorage.getItem('userIdval');
        } */
        //alert(managerid);
        // getProjects('', 1, 'asc', 1, '', managerid);
        // getAllTasksInfo(taskstatus, searchProj, 1, ITEMS_PER_PAGE);

        await getProjects('', '', 'asc', 1, '', managerid);
        // Now that projects are fetched and state is updated, call getAllTasksInfo
        getAllTasksInfo(taskstatus, searchProj, 1, ITEMS_PER_PAGE);
    }

    const getAllTasksInfo = (taskstatus, searchProj, pagenumval, itemsperpage) => {
        //alert("taskstatus:"+taskstatus+" searchProj:"+searchProj+" pagenumval:"+pagenumval+" itemsperpage:"+itemsperpage)
        var taskstatusses = [];
        if (!isEmpty(taskstatus)) {
            if (taskstatus === 1) {
                taskstatusses.push("New");
                taskstatusses.push("In Progress");
                taskstatusses.push("Re-opened");
            }
            else if (taskstatus === 0) {
                taskstatusses.push("Completed");
                taskstatusses.push("Disabled");
                taskstatusses.push("Deleted");
            }
            else {
                taskstatusses.push(taskstatus);
            }
        } else {
            taskstatusses.push("New");
            taskstatusses.push("In Progress");
            taskstatusses.push("Re-opened");
            taskstatusses.push("Completed");
            taskstatusses.push("Disabled");
            taskstatusses.push("Deleted");
        }


        var myProjectIdsFilter = [];
        if (isEmpty(searchProj)) {
            if (loggedUser.toLowerCase() === 'manager') {

                projectListInfo.forEach((project) => {

                    if (project.projectManagerStatus == 'assigned') {


                        myProjectIdsFilter.push(project.projectId);
                    }
                });
            }
            if (loggedUser.toLowerCase() === 'admin') {
                projectListInfo.forEach((project) => {
                    myProjectIdsFilter.push(project.projectId);
                });
            }
        } else {
            myProjectIdsFilter.push(searchProj);
        }
        console.log(myProjectIdsFilter);

        if (loggedUser.toLowerCase() === 'manager' && myProjectIdsFilter.length > 0) {
            myTasksInfo(taskstatusses, myProjectIdsFilter, searchTxt, pagenumval, itemsperpage, companyid);
        }

        if (loggedUser.toLowerCase() === 'admin') {
            myTasksInfo(taskstatusses, myProjectIdsFilter, searchTxt, pagenumval, itemsperpage, adminCompanyid);
        }

    }

    const myTasksInfo = (taskStatusval, searchProjVal, searchTxtVal, pagenumval, itemsperpageVal, mycompanyval) => {
        setTotalItems(0);        
        var taskstatusses = [];
        if (!isEmpty(taskStatusval)) {
            if (taskStatusval === 1) {
                taskstatusses.push("New");
                taskstatusses.push("In Progress");
                taskstatusses.push("Re-opened");
            }
            else if (taskStatusval === 0) {
                taskstatusses.push("Completed");
                taskstatusses.push("Disabled");
                taskstatusses.push("Deleted");
            }
            else {
                taskstatusses.push(taskStatusval);
            }
        } else {
            taskstatusses.push("New");
            taskstatusses.push("In Progress");
            taskstatusses.push("Re-opened");
            taskstatusses.push("Completed");
            taskstatusses.push("Disabled");
            taskstatusses.push("Deleted");
        }

        //alert('searchProjVal - '+searchProjVal);
        var myProjectIdsFilter = [];
        if (!isEmpty(searchProjVal)) {
            myProjectIdsFilter.push(searchProjVal);
        } else {
            if (loggedUser.toLowerCase() === 'manager') {
                //alert('289 - '+ projectListInfo.length);
                projectListInfo.forEach((project) => {
                    //if (project.projectManagerStatus == 'assigned') {
                        myProjectIdsFilter.push(project.projectId);
                    //}
                });
            }
            if (loggedUser.toLowerCase() === 'admin') {
                projectListInfo.forEach((project) => {
                    myProjectIdsFilter.push(project.projectId);
                });
            }
        }
        //alert(JSON.stringify(projectListInfo));
        //alert(JSON.stringify(myProjectIdsFilter));

        setShowLoader(1);
        //alert(sortTaskConfig.direction);
        const requestObject =
        {
            "status": taskstatusses,
            "billable": searchTaskWithBillable,
            "projectId": myProjectIdsFilter,
            "search": searchTxtVal,
            "pageNumber": pagenumval,
            "pageSize": itemsperpageVal,
            "companyId": mycompanyval,
            "clientId":[],
            "sortOrder":sortTaskConfig.direction,
            "sortBy":sortTaskConfig.key

        }
        var accessTokenIn = sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer "+accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };
        services.getAllTasksInfo(requestObject).then((res) => {

            if (res.type === 'success') {
                var team_ids = [];
                var teamInfo = res.data;
                for (var i = 0; i < teamInfo.length; i++) {
                    team_ids.push(teamInfo[i].id);
                }
                setTaskListInfo(res.data);

                setTotalItems(res.totalRecords);
                settotalPages(Math.ceil(res.totalRecords / ITEMS_PER_PAGE));
                setCurrentPage(pagenumval);

                const requestObject = {
                    "userId": "",
                    "projectId": "",
                    "taskStatus": [],
                    "taskName": "",
                    "taskId": team_ids,
                    "pageNumber": pagenumval,
                    "pageSize": ITEMS_PER_PAGE,
                    "sortOrder":sortTaskConfig.direction,
                    "sortBy":sortTaskConfig.key
                    //"sortOrder":"DESC",
                    //"sortBy":"burnedHours"
                };
                var accessTokenIn = sessionStorage.getItem("accToken");
                // const requestOptions = {
                //     method: 'POST',
                //     headers: {
                //     'Content-Type': 'application/json',
                //     'Authorization': "Bearer "+accessTokenIn
                //     },
                //     body: JSON.stringify(requestObject)
                // };
                services.getTaskHours(requestObject).then((res) => {
                    if (res.type === 'success') {

                        setTaskHoursListInfo(res.data);
                        var taskhours = res.data

                        // Merge the data
                        var combinedData = [];
                        for (var i = 0; i < teamInfo.length; i++) {
                            for (var j = 0; j < taskhours.length; j++) {
                                if (teamInfo[i].id === taskhours[j].taskId) {

                                    combinedData.push({
                                        "id": teamInfo[i].id,
                                        "name": teamInfo[i].name,
                                        "project_id": teamInfo[i].project_id,
                                        "sprint": teamInfo[i].sprint,
                                        "description": teamInfo[i].description,
                                        "duration": teamInfo[i].duration,
                                        "is_billable": teamInfo[i].is_billable,
                                        "task_status": teamInfo[i].task_status,
                                        "task_started_time": teamInfo[i].task_started_time,
                                        "task_ended_time": teamInfo[i].task_ended_time,
                                        "created_time": teamInfo[i].created_time,
                                        "updated_time": teamInfo[i].updated_time,
                                        "project": teamInfo[i].project,
                                        "estimated_hours": teamInfo[i].estimated_hours,
                                        "estimatedHours": taskhours[j].estimatedHours,
                                        "burnedHours": taskhours[j].burnedHours,
                                        "remainingHours": taskhours[j].remainingHours,
                                        "users": taskhours[j].users
                                    });
                                }
                            }
                        }
                //         setTotalItems(combinedData.length);
                // settotalPages(Math.ceil(combinedData.length / ITEMS_PER_PAGE));
                // setCurrentPage(pagenumval);
                if(combinedData.length == 0){
                    setTotalItems(0);
                    settotalPages(0);
                    setCurrentPage(1);
                    setMergedData(combinedData);
                }else{
                    setMergedData(combinedData);
                }
                        
                        
                      
                    }
                });
            }
            setShowLoader(0);
        });
    }

    const setSearchStatus = (event) => {
        var searchStatus = event.target.value;
        setCurrentPage(1);
        setTaskStatus(searchStatus);
        if (searchStatus === 'New' || searchStatus === 'In Progress' || searchStatus === 'Re-opened' || searchStatus == '1') {
            setActiveInactiveIsChecked(0);
        } else {

            setActiveInactiveIsChecked(1);
        }
    }

    const editTaskWithId = (taskid) => {
        if (taskid == '') {
            setFormMode('add');
            setprojectidfortask(null);
            setSelectedTaskIdForEdit(taskid);
            setSelectedDynamicValue(Math.floor(Math.random() * (105 - 2 + 1)) + 10)
        } else {
            setFormMode('edit');
            setprojectidfortask(null);
            setSelectedTaskIdForEdit(taskid);
            setSelectedDynamicValue(Math.floor(Math.random() * (105 - 2 + 1)) + 10)
        }
    }

    const changeTaskStatus = (taskid, newStatus) => {
        setTaskIdforUpdate(taskid);
        setTaskStatustoUpdate(newStatus);
        setShowPopup(true);
    }


    const handleUpdateTask = () => {
        setShowLoader(1);
        var requestObject = {};
        for (var p = 0; p < taskListInfo.length; p++) {
            if (taskListInfo[p].id === taskIdforUpdate) {
                var requestObject = {
                    "name": taskListInfo[p].name,
                    "project_id": taskListInfo[p].project_id,
                    "sprint": taskListInfo[p].sprint,
                    "description": taskListInfo[p].description,
                    "duration": taskListInfo[p].duration,
                    "estimated_hours": taskListInfo[p].estimated_hours,
                    "is_billable": taskListInfo[p].is_billable,
                    "task_status": taskStatustoUpdate,
                    "task_started_time": taskListInfo[p].task_started_time,
                    "task_ended_time": taskListInfo[p].task_ended_time
                }
            }
        }
        setShowPopup(false);
        var accessTokenIn = sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'PUT',
        //     headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer "+accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };

        $(".load-wrap").show(); //console.log(requestObject); return false;
        services.updateTaskInfoById(requestObject, taskIdforUpdate).then((res) => {
            //setIsOpen(false);
            $(".load-wrap").show();
            $("#updatesuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
                $("#updatesuccessMessage").slideUp(500);
            });
            setTimeout(function () {
                setCurrentPage(1);
                if (taskStatustoUpdate === 1) {
                    setActiveInactiveIsChecked(0);
                } else {
                    setActiveInactiveIsChecked(1);
                }
                //loadTasks(taskStatustoUpdate);
                setTaskStatus('');
                setSearchProj('');
                setSearchTxt('');
                loadTasks('');
            }, 1000);
            //setShowLoader(0);
        });
    }

    const getProjectTaskDetails = (taskid, projectid) => {
        window.location.href = '/taskDetail?tab=task&tid=' + taskid + '&pid=' + projectid;
    }

    const handleSearchInputChange = (event) => {
        setSearchTxt(event.target.value);
        setCurrentPage(1);
    }

    const setProjectFilter = (event) => {
        setCurrentPage(1);
        settotalPages(0);
        setTotalItems(0);
        setSearchProj(event.target.value);
     
    }

    const capitalizeFirstLetter = (status) => {
        if (!status) return '';
        return status.charAt(0).toUpperCase() + status.slice(1);
    };


    const [expandedRows, setExpandedRows] = useState({});

    const handleToggle = (rowId) => {
        setExpandedRows((prev) => ({
            ...prev,
            [rowId]: !prev[rowId],
        }));
    };

    const toggleRow = (rowIndex, usersLength) => {
        if (usersLength > 1) {
            setExpandedRows((prevState) => ({
                ...prevState,
                [rowIndex]: !prevState[rowIndex],
            }));
        }
    };

   
    const handleTaskSort2 = (columnKey) => {
        let directionStr = 'ASC';
        if (sortTaskConfig.key === columnKey && sortTaskConfig.direction === 'ASC') {
            directionStr = 'DESC';
        }
        setCurrentPage(1);
        setSortTaskConfig({ key: columnKey, direction:directionStr });
    }

    const handleTaskSort = (sortKey) => {
        setSortTaskConfig((prevSortConfig) => {
            let direction = 'ASC';
            if (prevSortConfig.key === sortKey && prevSortConfig.direction === 'ASC') {
                direction = 'DESC';
            }
            return { key: sortKey, direction };
        });
    };



    const taskColumns2 = React.useMemo(
        () => [
            {
                //Header: 'Task',
                Header: (
                    <span onClick={() => handleTaskSort('task.name')}>
                        Task {sortTaskConfig.key === 'task.name' ? (sortTaskConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}
                    </span>
                ),
                accessor: 'name',

                class: 'tsone',

                Cell: ({ value, row }) => (
                    <div className="projecttask">
                        <span>
                            <a href="#" onClick={() => getProjectTaskDetails(row.original.id, row.original.project_id)}>
                                {value}
                            </a>
                        </span>
                        {/* <span className="badge rounded-pill project-badge text-dark my-2">
                              {row.original.project.name}
                          </span> */}
                    </div>
                ),
                //sortType: 'basic', // Enable sorting for Task Name
                disableSortBy: true,
            },
            {
               // Header: 'Project',
                Header: (
                    <span onClick={() => handleTaskSort('project.name')}>
                        Project {sortTaskConfig.key === 'project.name' ? (sortTaskConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}
                    </span>
                ),

                accessor: 'project.name',
                width: '238px',
                Cell: ({ value }) => (
                    <div className="align-items-center">
                        {value}
                    </div>
                ),
                sortType: 'basic', // Enable sorting for Task Name
                disableSortBy: true,
            },
            {
                Header: 'Assigned to',
                accessor: 'users',
                className: "tstwo",
                Cell: ({ row }) => (
                    <>
                        <div className="dualtask d-flex align-items-center" onClick={() => toggleRow(row.index, row.original.users.length)}
                            style={{ cursor: 'pointer' }}>
                            <span className="ps-1">
                                {parseInt(row.original.users.length) > 0
                                    ? `${row.original.users[0].userFirstName} ${row.original.users[0].userLastName}`
                                    : ''}
                                     <span className="count ps-2">
                                {parseInt(row.original.users.length - 1) > 0 ? '+' + parseInt(row.original.users.length - 1) : ''}
                            </span>
                            </span>

                        </div>


                        {/* Toggle button for accordion */}
                        {/*  {row.original.users.length > 1 && (
                              <button
                                  className="btn btn-link"
                                  onClick={() => handleToggle(row.id)}
                                  aria-expanded={expandedRows[row.id] || false}
                              >
                                  Toggle Users
                              </button>
                          )} */}
                    </>
                ),
                sortType: 'basic', // Enable sorting for Task Name
                disableSortBy: true,
            },
            //   {
            //       Header: 'Sprint',
            //       accessor: 'sprint',
            //       className:"tsthree",
            //       Cell: ({ value }) => <div className="text-left">{value}</div>,
            //       sortType: 'basic', // Enable sorting for Task Name
            //   },
            {
                //Header: 'Estimated Hrs',
               
                Header: (
                    <span onClick={() => handleTaskSort('task.estimated_hours')}>
                        Estimated Hrs {sortTaskConfig.key === 'task.estimated_hours' ? (sortTaskConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}
                    </span>
                ),

                accessor: 'estimated_hours',
                className: "tsfour",
                Cell: ({ row }) => <div className="text-left">
                    {parseInt(row.original.estimated_hours) > 0 ? (row.original.estimated_hours) : ''}
                </div>,
                sortType: 'basic', // Enable sorting for Task Name
                disableSortBy: true,
            },
            {
                Header: 'Spent Hrs',
                accessor: 'burnedHours',
                className: "tsfive",
                Cell: ({ row }) => (
                    <div className="text-left position-relative" style={{ paddingLeft: '5px' }}>
{/*
                        {(parseInt(row.original.estimatedHours) > 0 && parseInt(row.original.burnedHours) > parseInt(row.original.estimatedHours)) ? (
                            <>
                                {parseInt(row.original.estimatedHours)}
                                <span className="extrahr">
                                    +{parseInt(row.original.burnedHours) - parseInt(row.original.estimatedHours)}
                                </span>
                            </>
                        ) : (
                            parseInt(row.original.burnedHours) > 0 ? (row.original.burnedHours).slice(0, -3) : ''
                        )} */}
                        {(parseInt(row.original.burnedHours) > 0 ? (row.original.burnedHours).slice(0, -3) : '')}
                    </div>
                ),
                sortType: 'basic', // Enable sorting for Task Name
                disableSortBy: true,
            },
            {
                Header: 'Balance Hrs',
                accessor: 'remainingHours',
                className: "tssix",
                Cell: ({ value }) => <div className="text-left">{parseInt(value) > 0 ? (value).slice(0, -3) : ''}</div>,
                sortType: 'basic', // Enable sorting for Task Name
                disableSortBy: true,
            },
            {
                Header: 'Status',
                accessor: 'task_status',
                className: "tsseven",
                Cell: ({ value }) => (
                    <div className="d-grid text-center">
                        <img
                            src={`images/${value.toLowerCase().replace(/\s+/g, '-')}.svg`}
                            className="m-auto"
                            alt=""  height="20"
                        />
                        <small className="text-nowrap">{value !== 'New' ? value : ''}</small>
                    </div>
                ),
                sortType: 'basic', // Enable sorting for Task Name
                disableSortBy: true,
            },
            {
                Header: 'Action',
                accessor: 'actions',
                className: "tseight",
                Cell: ({ row }) => (
                    (row.original.project.project_status === 1 ? (
                    (row.original.task_status === 'New' || row.original.task_status === 'In progress' || row.original.task_status === 'Re-opened') ? (
                        <div className="dropdown table-action-dd text-left">
                            <button className="dropdown-toggle table-action-btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="images/dots.svg" alt="" />
                            </button>
                            <ul className="dropdown-menu">
                                <li>
                                    <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalct" onClick={() => editTaskWithId(row.original.id)}>
                                        <img src="images/edit.png" alt="" /> Edit
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#" onClick={() => changeTaskStatus(row.original.id, 'Completed')}>
                                        <img src="images/completed.svg" width={19} alt="" /> Complete
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#" onClick={() => changeTaskStatus(row.original.id, 'Disabled')}>
                                        <img src="images/disabled.svg" width={19} alt="" /> Disable
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#" onClick={() => changeTaskStatus(row.original.id, 'Deleted')}>
                                        <img src="images/delete-red.svg" width={18} alt="" /> Delete
                                    </a>
                                </li>
                            </ul>
                        </div>
                    ) : ((row.original.task_status === 'Disabled') ? (
                        <div className="dropdown table-action-dd text-left">
                            <button className="dropdown-toggle table-action-btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="images/dots.svg" alt="" />
                            </button>
                            <ul className="dropdown-menu">
                                <li>
                                    <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalct" onClick={() => editTaskWithId(row.original.id)}>
                                        <img src="images/edit.png" alt="" /> Edit
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#" onClick={() => changeTaskStatus(row.original.id, 'Completed')}>
                                        <img src="images/completed.svg" alt="" /> Complete
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#" onClick={() => changeTaskStatus(row.original.id, 'New')}>
                                        <img src="images/greencheck.svg" width={19} alt="" /> Enable
                                    </a>
                                </li>
                            </ul>
                        </div>
                    ) : ((row.original.task_status === 'Completed') ? (
                        <div className="dropdown table-action-dd text-left">
                            <button className="dropdown-toggle table-action-btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="images/dots.svg" alt="" />
                            </button>
                            <ul className="dropdown-menu">
                                <li>
                                    <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModalct" onClick={() => editTaskWithId(row.original.id)}>
                                        <img src="images/edit.png" alt="" /> Edit
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item" href="#" onClick={() => changeTaskStatus(row.original.id, 'Re-opened')}>
                                        <img src="images/reopen.svg" width={19} alt="" /> Re-Open
                                    </a>
                                </li>
                            </ul>
                        </div>)
                        : ('')
                    )


                    )
                    )
                    : ('')

                )

                ),
                sortType: 'basic', // Enable sorting for Task Name
                disableSortBy: true,
            },
        ],
        [sortTaskConfig]
    );

    const {
        getTableProps: getTaskTableProps2,
        getTableBodyProps: getTaskTableBodyProps2,
        headerGroups: taskHeaderGroups2,
        rows: taskRows2,
        prepareRow: prepareTaskRow2,
    } = useTable(
        { columns: taskColumns2, data: mergedData },
        useSortBy
    );

    return (
        <>
            <main>


                <section>
                    <div class="container">
                        <h2 class="mainHeadline">
                            Project Management
                        </h2>

                        <div class="tab-primary">
                        <ProjectManagementHeader />

                            <div class="tab-content ">
                                <div id="three" class="container tab-pane px-0 fade active show" role="tabpanel">

                                    <div class="d-flex justify-content-between pt-2 pb-2">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-lg-3 pt-1 px-0">
                                                    <button onClick={() => editTaskWithId('')} class="btn btn-primary m-0" data-bs-toggle="modal" data-bs-target="#exampleModalct"><span class="mx-2">+</span> Create
                                                        Task</button>
                                                </div>
                                                <div class="col-lg-3">
                                                    <input type="text" class="form-control searchKeyInput magnifier " id="searchTaskwithTxt" value={searchTxt} onChange={handleSearchInputChange} placeholder="Search by task" aria-describedby="emailHelp" style={{ "background-color": "#ebebeb" }} />
                                                </div>
                                                <div class="col-lg-3">
                                                    <div class="d-flex justify-content-end align-items-center ms-auto">
                                                        <span style={{ "min-width": "69px" }}>Projects:</span>
                                                        <select class="form-select" id="projectdropdown" style={{ "max-width": "200px" }} value={searchProj} onChange={setProjectFilter}>
                                                            <option value="">All</option>
                                                            {projectListInfo.map((projectdropdowndata, projectdropdownindex) => (
                                                                <option value={projectdropdowndata.projectId}>{projectdropdowndata.projectName}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-lg-3">
                                                    <div class="d-flex justify-content-end align-items-center ms-auto">
                                                        <span style={{ "min-width": "69px" }}>Status:</span>
                                                        <select class="form-select" id="inputGroupSelect01" style={{ "max-width": "300px" }} onChange={setSearchStatus} value={taskStatus}>
                                                            <option value="">All</option>
                                                            <option value="New">New</option>
                                                            <option value="In Progress" >In Progress</option>
                                                            <option value="Completed" >Completed</option>
                                                            <option value="Re-opened" >Re-Opened</option>
                                                            <option value="Disabled" >Disabled</option>
                                                            <option value="Deleted" >Deleted</option>
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                    <div class="panel panel-default">

                                        <div class="tbl-container bdr mt-0">


                                            <table className="table table-condensed" {...getTaskTableProps2()}>
                                                <thead className="bg-lightblue tsthwrap">
                                                    {taskHeaderGroups2.map((headerGroup) => (
                                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                                            {headerGroup.headers.map((column) => (
                                                                <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{
                                                                    width: column.width,
                                                                    position: 'relative',

                                                                    cursor: 'pointer',
                                                                }}>
                                                                    {column.render('Header')}
                                                                    <span
                                                                        style={{
                                                                            //position: 'absolute',
                                                                            paddingLeft: '10px',
                                                                            right: '10px',
                                                                            top: '50%',
                                                                            //transform: 'translateY(-50%)',
                                                                            display: 'inline-block',
                                                                            fontSize: '0.8em',
                                                                            color: '#888',
                                                                        }}
                                                                    >
                                                                        {
                                                                            !column.disableSortBy ? (
                                                                                column.isSorted ? (
                                                                                    column.isSortedDesc ? ' 🔽' : ' 🔼'
                                                                                ) : '↕️'
                                                                            ) : null
                                                                        }
                                                                    </span>
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </thead>
                                                <tbody {...getTaskTableBodyProps2()}>
                                                    {taskRows2.length > 0 ? (
                                                        taskRows2.map((row) => {
                                                            prepareTaskRow2(row);
                                                            return (
                                                                <React.Fragment key={row.id}>
                                                                    <tr {...row.getRowProps()} className="accordion-toggle tswrap">
                                                                        {row.cells.map((cell) => (
                                                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                                        ))}
                                                                    </tr>

                                                                    {/* Sub-row for additional users */}
                                                                    {expandedRows[row.id] && (
                                                                        <tr>
                                                                            <td colSpan={9} className="p-0">
                                                                                <div className="collapse show">
                                                                                    <table className="table coltableinn" style={{ backgroundColor: '#e9e9e9' }}>
                                                                                        <tbody>
                                                                                            {row.original.users.map((user, userIndex) => (
                                                                                                <tr key={userIndex}>
                                                                                                    <td className="tszero"></td>
                                                                                                    <td className="tsone"></td>
                                                                                                    <td className="tstwo text-start">
                                                                                                        {user.userFirstName + ' ' + user.userLastName + ' '}{(user.userRoleId === 2) && (<img src="images/manager-icon.svg" alt="" width={22} style={{ "marginRight": "4px" }} />)}
                                                                                                    </td>
                                                                                                    {/* <td className="tsthree text-start" ></td> */}
                                                                                                    <td className="tsfour text-start"></td>
                                                                                                    <td className="tsfive text-start">
                                                                                                        {parseInt(user.userBurnedHours) > 0 ? (user.userBurnedHours).slice(0, -3) : ''}
                                                                                                    </td>
                                                                                                     <td className="tssix text-start"></td>
                                                                                                    <td className="tsseven"> <small>{capitalizeFirstLetter(user.taskUserStatus)}</small> </td>
                                                                                                    <td className="tseight "></td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </React.Fragment>
                                                            );
                                                        })
                                                    ) : (
                                                        <tr>
                                                            <td colSpan="8">
                                                                <div className="d-grid text-center py-3">
                                                                    <img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
                                                                    <span> No tasks available</span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>

                                        </div>
                                        {showPopup && (
                                            <ConfirmationPopup
                                                message="Are you sure you want to Update this Task?"
                                                onConfirm={handleUpdateTask}
                                                onCancel={handleCancel}
                                            />
                                        )}


                                        <div class="paginationContent mt-3">
                                            <Pagination
                                                total={totalItems}
                                                itemsPerPage={ITEMS_PER_PAGE}
                                                currentPage={currentPage}
                                                onPageChange={handleTaskPageChange2} />

                                        </div>
                                    </div>

                                </div>
                            </div>
                            {showLoader === 1 ?
                                <div class="loadwrapper">
                                    <div class="clock"><div class="circle"></div></div>
                                </div>
                                : ''}
                        </div>

                    </div>

                    <AddProject editProject={selectedProjectIdForEdit} />

                    <AddTask formMode={formMode} taskProject={projectidfortask} editTask={selectedTaskIdForEdit} dynamic={selectedDynamicValue} />

                    <AddClient editClient={selectedClientIdForEdit} isOpenModal='1' />

                </section>
            </main>
        </>
    )
};

ManageTasks.propTypes = {};

ManageTasks.defaultProps = {};

export default ManageTasks;

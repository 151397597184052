import React,{history,useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import services from "../../services/services";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DateObject from "react-date-object";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $ from 'jquery';
import CreateTask from "./create-task";
import { DeleteConfirmationPopup } from "../../confirmation-dialogue/delete-dialogue";

const AddTime = ({edittimeentry, addrowday, addrowweekday1, addrowweekday2, mytimeentryid, entryStatus, onTrigger}) => {
    const navigate = useNavigate();
    const buttonRef = useRef(null);
    const projeUnassignRef = useRef(null);
    const projeInactiveSeparateRef = useRef(null);
    const projeInactiveRef = useRef(null);
    const unassignInfoRef = useRef(null);
    const unassignMinInfoRef = useRef(null);
    const unassignProjectNameRef = useRef(null);
    const unassignProjectDropdownRef = useRef(null);
    const [date, setDate] = useState(new Date());
    const [showCalendar, setShowCalendar] = useState(false);
    const [showClientList, setShowClientList] = useState([]);
    const [showProjectList, setShowProjectList] = useState([]);
    const [showAddProjectList, setShowAddProjectList] = useState([]);
    const [showTaskList, setShowTaskList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [showTaskDetails, setShowTaskDetails] = useState('');
    const [showEditTaskDetails, setShowEditTaskDetails] = useState('');
    const [isBillable, setIsBillable] = useState(1);
    const [inputDurationValue, setInputDurationValue] = useState('');
    const [formattedTime, setFormattedTime] = useState('');
    const [formattedEditTime, setFormattedEditTime] = useState('');
    const [clientvalidation, setClientvalidation] = useState(0);
    const [editclientvalidation, setEditClientvalidation] = useState(0);
    const [projvalidation, setProjvalidation] = useState(0);
    const [editprojvalidation, setEditProjvalidation] = useState(0);
    const [taskvalidation, setTaskvalidation] = useState(0);
    const [edittaskvalidation, setEditTaskvalidation] = useState(0);
    const [taskdescriptionvalidation, setTaskdescriptionvalidation] = useState(0);
    const [taskdurationvalidation, setTaskdurationvalidation] = useState(0);
    const [edittaskdescriptionvalidation, setEditTaskdescriptionvalidation] = useState(0);
    const [edittaskdurationvalidation, setEditTaskdurationvalidation] = useState(0);
    const [editProjectUserStatus, setEditProjectUserStatus] = useState('assigned');
    const [editProjectCurrentStatus, setEditProjectCurrentStatus] = useState('1');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [initialClientlistval, setInitialClientlistval] = useState('');
    const [initialProjectlistval, setInitialProjectlistval] = useState('');
    const [initialProjectlistName, setInitialProjectlistName] = useState('');
    const [initialTasklistval, setInitialTasklistval] = useState('');
    const [initialBillinglistval, setInitialBillinglistval] = useState('');
    const [initialWeekTimeEntryId, setInitialWeekTimeEntryId] = useState('');
    const [isbillablecheck, setisbillablecheck]  = useState(1);

    const [showTaskInfo, setShowTaskInfo] = useState('');
    const [entryCreatedFrom, setTaskEntryCreatedFrom] = useState('');
    //const useridval = 4;
    const [useridval, setuseridval] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [myCompany, setMyCompany] = useState(sessionStorage.getItem('userCompanyId'));
    const [showLoader, setShowLoader] = useState(0);

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all",mode: "onBlur"
    });

    const handleDurationInputChange = (event) => {
        const value = event.target.value;
        var newcleanedValue = value.replace(/\D/g, '');
        // Remove non-numeric characters and restrict length to 4
        var cleanedValue = value.replace(/\D/g, '').slice(0, 4);
        if (newcleanedValue.length > 4) {
            cleanedValue = newcleanedValue.slice(4);
        }
        setInputDurationValue(cleanedValue);
        setFormattedTime(formatToHHMM(cleanedValue));
    };

    const handleEditDurationInputChange = (event) => {
        const value = event.target.value;
        var newcleanedValue = value.replace(/\D/g, '');
        // Remove non-numeric characters and restrict length to 4
        var cleanedValue = value.replace(/\D/g, '').slice(0, 4);
        if (newcleanedValue.length > 4) {
            cleanedValue = newcleanedValue.slice(4);
        }
        setInputDurationValue(cleanedValue);
        setFormattedEditTime(formatToHHMM(cleanedValue));
    };



    const formatToHHMM = (value) => {
        if (value === '') return '';

        if (value.length <= 2) {
          return value; // Return as is if only two digits or less
        }

        const firstPart = value.slice(0, 2);
        const secondPart = value.slice(2);

        return `${firstPart}:${secondPart}`;
    };

    const handleDateChange = (newDate) => {
        setDate(newDate);
        setShowCalendar(false); // Hide calendar after date selection
    };

    const toggleCalendar = () => {
        setShowCalendar(!showCalendar);
    };

    function getFormatCalendarDate(calendarDate) {
        if (calendarDate == "") {
            return "";
        }
        var daten = new DateObject(calendarDate);
        var daten1 = daten.format("dddd, DD MMM");
        return daten1;
    }

    function getDateFormatCalendarDate(calendarDate){
        if (calendarDate == "") {
            return "";
        }
        var daten1 = new DateObject(calendarDate);
        var daten11 = daten1.format("YYYY-MM-DD");
        return daten11;
    }

    function getClients(){
        var companyid = '';
        var clientstatus = '';
        services.getClientsList(companyid,clientstatus).then((res) => {
            if(res){
                if(res.data.data != null){
                    setShowClientList(res.data.data);
                }
            }
        });
    }

    function getProjects(clientid, projstatus){
        services.getAllProjects(clientid,projstatus,'',100).then((res) => {

            if(res){
                if(res.data.data != null){

                    setShowProjectList(res.data.data);
                }
            }
        });
    }

const [isbillabletask, setIsbillabletask] = useState(0);
    function getTaskbyId(taskid){
        services.getTaskDetails(taskid).then((res) => {
            
            if(res){
                if(res.data.data != null){
                    var myresponsetask = [res.data.data];
                    for(var i=0; i<myresponsetask.length; i++){
                        
                        setShowTaskDetails(myresponsetask[i].description);

                        //setIsbillabletask(myresponsetask[i].is_billable);
                        setIsBillable(myresponsetask[i].is_billable);
                    }
                }
            }
        });
    }

    function getEditTaskbyId(taskid){
        services.getTaskDetails(taskid).then((res) => {
            if(res){
                if(res.data.data != null){
                    var myresponsetask = [res.data.data];
                    for(var i=0; i<myresponsetask.length; i++){
                        setShowEditTaskDetails(myresponsetask[i].description);
                        setisbillablecheck(myresponsetask[i].is_billable);
                    }
                }
            }
        });
    }

    useEffect(() => {
        var loggedUserId = sessionStorage.getItem('userIdval');
        setuseridval(parseInt(loggedUserId));

        var loggedinUserRole = sessionStorage.getItem('userRole');
        if(loggedinUserRole.toLowerCase() === 'manager'){
            getProjectsByManagerId('', loggedUserId, '1');
        }
        else{
            getProjectUsersByUserId('', loggedUserId, '1');
        }

        

        if(!isEmpty(mytimeentryid)){
            //edit
            $('#addbody').hide();
            $('#editbody').show();
            $('#deleteEntryonEdit').show();
            $('#editclientspace').hide();

            getTimeSheetEntryInfo(mytimeentryid);
           /*
            const timer = setTimeout(() => {
                getTimeSheetEntryInfo(mytimeentryid);
              }, 1000); // 1000 ms = 1 second
           */
        }
        else {
            buttonRef.current.disabled = false;
            $('#addbody').show();
            $('#editbody').hide();
            $('#deleteEntryonEdit').hide();
            $('#clientspace').hide();
        }
        $('#successMessage').hide();
        $('#updatesuccessMessage').hide();
        $('#removesuccessMessage').hide();
        $('#failuresuccessMessage').hide();
        $('#validationMessage').hide();
        $('#editvalidationMessage').hide();


    },[mytimeentryid]);

    useEffect(() => {
        // Attach the event listener to the modal
        const modalElement = document.getElementById('exampleModal');
        const handleModalHidden = () => resetForm();

        modalElement.addEventListener('hidden.bs.modal', handleModalHidden);

        return () => {
            // Cleanup the event listener when the component unmounts
            modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, []);

    useEffect(() => {
        // Check the conditions and update the button disabled state
        if (editProjectUserStatus !== 'assigned' || editProjectCurrentStatus !== '1') {
          setIsButtonDisabled(true);
        } else {
          setIsButtonDisabled(false);
        }
      }, [editProjectUserStatus, editProjectCurrentStatus]); // Dependencies to trigger re-render

    useEffect(() => {
        if (!isOpen) {
            resetForm(); // Reset the form when the modal is closed
        }
    }, [isOpen]);

    const resetForm = () => {
        
        onTrigger('');
        $("#entryform1").trigger("reset");
        setProjvalidation(0);
        setEditProjvalidation(0)
        setTaskvalidation(0);
        setEditTaskvalidation(0);
        setTaskdescriptionvalidation(0);
        setTaskdurationvalidation(0);
        setEditTaskdescriptionvalidation(0);
        setEditTaskdurationvalidation(0);
        setProjectClientId('');
        setProjectClientName('');
        setShowTaskDetails('');
        setTextValue('');
        $('#successMessage').hide();
        $('#updatesuccessMessage').hide();
        $('#removesuccessMessage').hide();
        $('#failuresuccessMessage').hide();
        $('#validationMessage').hide();
        $('#editvalidationMessage').hide();
     };

     async  function getTimeSheetEntryInfo(timesheetentryid) {
        try {
            services.getTimeEntryInfobyId(timesheetentryid).then((res) => {
                
                if(res){
                    if(res.data.data != null){
                        setShowTaskInfo(res.data.data);
                        setShowEditTaskDetails(res.data.data.task.description);
                        setFormattedEditTime((res.data.data.duration).slice(0, -3));
                        $('#edittaskduration').val((res.data.data.duration).slice(0, -3));
                        setclientlistval(res.data.data.client_id);
                        setInitialClientlistval(res.data.data.client_id);

                        setProjectClientId(res.data.data.client.id);
                        setProjectClientName(res.data.data.client.client_name);
                        getTasks(res.data.data.project_id,useridval);

                        setprojectlistval(res.data.data.project_id);
                        setInitialProjectlistval(res.data.data.project_id);
                        setInitialProjectlistName(res.data.data.project.name);

                        //const projectData = await getProjectById(res.data.data.project_id,showProjectList);
                        /*()
                        const projectData = getProjectById(res.data.data.project_id, showProjectList);
                        const projectUserStatus = projectData.project_user_status;
                        const curproject_status = projectData.project.project_status;
                        */

                        const projectData = getProjectById(res.data.data.project_id, showProjectList);

                        // Ensure projectData is defined before accessing its properties
                        if (projectData && projectData.project) {
                            const projectUserStatus = projectData.project_user_status;
                            const curproject_status = projectData.project.project_status;
                            // Proceed with the rest of your logic

                             //setEditProjectUserStatus(projectUserStatus);
						//setEditProjectCurrentStatus(curproject_status);
                            if (projectData.project_user_status == 'unassigned' || projectData.project.project_status == "0" || projectData.project.project_status == 0) {
                                if (buttonRef.current) {
                                    buttonRef.current.disabled = true;
                                }
                            } else {
                                buttonRef.current.disabled = false;
                            }

                            projeUnassignRef.current.style.display = 'none';
                            projeInactiveRef.current.style.display = 'none';
                            projeInactiveSeparateRef.current.style.display = 'none';
                            unassignMinInfoRef.current.style.display = 'none';
                            unassignProjectNameRef.current.style.display = 'none';
                            unassignProjectDropdownRef.current.style.display = 'flex';

                            if (projectData.project_user_status == 'unassigned') {
                                projeUnassignRef.current.style.display = 'inline';
                                unassignMinInfoRef.current.style.display = 'flex';
                                unassignProjectNameRef.current.style.display = 'flex';
                                unassignProjectDropdownRef.current.style.display = 'none';
                            }

                            if (projectData.project.project_status == '0' || projectData.project.project_status == 0) {
                                projeInactiveRef.current.style.display = 'inline';
                                unassignMinInfoRef.current.style.display = 'flex';
                                unassignProjectNameRef.current.style.display = 'flex';
                                unassignProjectDropdownRef.current.style.display = 'none';
                            }

                            if (projectData.project_user_status == 'unassigned' && (projectData.project.project_status == '0' || projectData.project.project_status == 0)) {
                                projeInactiveSeparateRef.current.style.display = 'inline';
                                unassignMinInfoRef.current.style.display = 'flex';
                                unassignProjectNameRef.current.style.display = 'flex';
                                unassignProjectDropdownRef.current.style.display = 'none';
                            }
                        } else {
                            console.error('Project data not found or incomplete:', projectData);
                            
                            // Handle the case where projectData is undefined
                        }



                        /*
                        if(projectData.project_user_status == 'unassigned' ){
                            projeUnassignRef.current.style.display = 'inline'; // Show the span
                            //unassignInfoRef.current.style.display = 'inline'; // Show the div

                        }else{
                            projeUnassignRef.current.style.display = 'none'; // Show the span
                            unassignInfoRef.current.style.display = 'none'; // Show the div
                            projeInactiveSeparateRef.current.style.display = 'none';
                        }

                        if(projectData.project.project_status == '0'  || projectData.project.project_status == 0 ){
                            projeInactiveRef.current.style.display = 'inline';// Show the span
                            unassignInfoRef.current.style.display = 'inline'; // Show the div
                        }else{
                            projeInactiveRef.current.style.display = 'none'; // Show the span
                            unassignInfoRef.current.style.display = 'none'; // Show the div
                        }
                        */


                        settasklistval(res.data.data.task_id);
                        setInitialTasklistval(res.data.data.task_id);
                        settaskdescriptionval(res.data.data.time_entry_description);
                        setInitialBillinglistval(res.data.data.is_billable);
                        if(res.data.data.is_billable == 1){
                            setisbillablecheck(1);
                        } else {
                            setisbillablecheck(0);
                        }
                        setTaskEntryCreatedFrom(res.data.data.entry_created_from_day_or_week);
                        setInitialWeekTimeEntryId(res.data.data.week_time_entry_id);
                    }
                }
            });
        } catch (error) {
            console.error("Error fetching time entry info:", error);
        }

    }

    function getProjectById(projectId, projectList) {
        
        return projectList.find(projectUser => projectUser.project_id === parseInt(projectId));
    }
   async function getProjectUsersByUserId(projid, userid, projstatus){

        if (!isEmpty(mytimeentryid))
        {
            //edit

           await services.getProjectUsersByProjectId('', userid, 1, '', 'assigned',1, '',1, 'ASC', 'client_name').then((res) => {
                if(res){
                    if(res.data.data != null){

                        setShowProjectList(res.data.data);
                    }
                }
            });


        }else{

            await services.getProjectUsersByProjectId('', userid, 1, '', '', '', '', '', 'ASC', 'client_name').then((res) => {
                if (res) {
                    if (res.data.data != null) {

                        setShowProjectList(res.data.data);
                        var dataofProjects = res.data.data;
                        const filteredData = dataofProjects.filter(item =>
                            item.project_user_status === "assigned" && item.project.project_status === 1
                        );

                        setShowAddProjectList(filteredData);
                    }
                }
            });

        }

    }

    async function getProjectsByManagerId(projectid, managerid, projstat){

        if (!isEmpty(mytimeentryid))
            {
                //edit

               await services.getProjectManagersByProjectId('', managerid, 1, 'assigned',1).then((res) => {
                    if(res){
                        if(res.data.data != null){

                            setShowProjectList(res.data.data);
                        }
                    }
                });


            }else{

            await services.getProjectManagersByProjectId('', managerid, '', '', '').then((res) => {
                if (res) {
                    if (res.data.data != null) {

                        setShowProjectList(res.data.data);

                        var dataofProjects = res.data.data;
                        const filteredData = dataofProjects.filter(item =>
                            item.project_manager_status === "assigned" && item.project.project_status === 1
                        );

                        setShowAddProjectList(filteredData);
                    }
                }
            });

            }
    }

    const submitValue=()=>
    {
        if(!isEmpty(mytimeentryid)){
            //Edit form
            var validation = 0;

            if(isEmpty($('#editprojectName').val())){
                validation = 1;
                setEditProjvalidation(1);
            } else {
                setEditProjvalidation(0);
            }

            if(isEmpty($('#edittaskName').val())){
                validation = 1;
                setEditTaskvalidation(1);
            } else {
                setEditTaskvalidation(0);
            }

            if(isEmpty($('#edittaskdescription').val())){
                validation = 1;
                setEditTaskdescriptionvalidation(1);
            } else {
                setEditTaskdescriptionvalidation(0);
            }

            if(!(isEmpty(addrowday))){
                if(isEmpty($('#edittaskduration').val()) || parseInt($('#edittaskduration').val() <= 0)){
                    validation = 1;
                    setEditTaskdurationvalidation(1);
                } else {
                    setEditTaskdurationvalidation(0);
                }
            }

            if(validation == 1){
                $('#editvalidationMessage').show();
                return false;
            } else {
                setShowLoader(1);
                
                if(entryCreatedFrom == 'day'){
                    //direct update
                    const requestObject = {
                        "client_id": $('#clientid').val(),
                        "project_id": $('#editprojectName').val(),
                        "task_id": $('#edittaskName').val(),
                        "time_entry_description": $('#edittaskdescription').val(),
                        "duration": $('#edittaskduration').val(),
                        //"duration": formattedEditTimeVal,
                        "user_id": useridval,
                        "day": addrowday,
                        "is_billable": isbillablecheck,
                        "week_time_entry_id": initialWeekTimeEntryId,
                        "is_holiday": 0,
                        "is_leave": 0,
                        "is_comp_off": 0,
                        "time_sheet_status": entryStatus,
                        "submited_on": null,
                        "approved_on": null,
                        "rejected_on": null
                    }
                    var accessTokenIn=sessionStorage.getItem("accToken");
                    // const requestOptions = {
                    //     method: 'PUT',
                    //     headers: {
                    //     'Content-Type': 'application/json',
                    //     'Authorization': "Bearer "+accessTokenIn
                    //     },
                    //     body: JSON.stringify(requestObject)
                    // };

                    $(".load-wrap").show(); //console.log(requestObject); return false;
                    services.updateTimeSheetEntryById(requestObject, mytimeentryid).then((res) => {
                        setIsOpen(false);

                        $(".load-wrap").show();
                        setShowLoader(0);
                        $("#updatesuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
                            $("#updatesuccessMessage").slideUp(500);
                        });
                        setTimeout(function() { $('#closemodal').click();
                            $("#entryform1").trigger("reset");
                            onTrigger('');
                            //window.location.href='/timesheet?req='+addrowday+'&view='+entryCreatedFrom;

                            navigate('/timesheet?req='+addrowday+'&view='+entryCreatedFrom);
                            onTrigger('');
                        }, 1000);
                    });
                } else {
                    //week
                    
                    if(initialTasklistval != $('#edittaskName').val() || initialBillinglistval != isbillablecheck){
                        //task or isbillable got changed then delete and add new
                        
                        const requestObject =
                            {
                                "client_id": $('#clientid').val(),
                                "project_id": $('#editprojectName').val(),
                                "task_id": $('#edittaskName').val(),
                                "time_entry_description": $('#edittaskdescription').val(),
                                "duration": [$('#edittaskduration').val()],
                                //"duration": [formattedEditTimeVal],
                                "user_id": useridval,
                                "weekStartDate": addrowweekday1,
                                "weekEndDate": addrowweekday2,
                                "days": [addrowday] ,
                                "is_billable": isbillablecheck,
                                //"week_time_entry_id": 5,
                                "is_holiday": 0,
                                "is_leave": 0,
                                "is_comp_off": 0,
                                "entry_created_from_day_or_week" : entryCreatedFrom,
                                "time_sheet_status": entryStatus,
                                "submited_on": null,
                                "approved_on": null,
                                "rejected_on": null
                            }
                            //console.log(requestObject);
                            var accessTokenIn=sessionStorage.getItem("accToken");
                            // const requestOptions = {
                            //     method: 'POST',
                            //     headers: {
                            //     'Content-Type': 'application/json',
                            //     'Authorization': "Bearer "+accessTokenIn
                            //     },
                            //     body: JSON.stringify(requestObject)
                            // };

                            $(".load-wrap").show();
                            services.createTimesheetEntry(requestObject).then((res) => {
                                if(res.type=='success'){


                                    var mydeleteObject= {
                                        "ids":[mytimeentryid]
                                    }
                                    var accessTokenIn = sessionStorage.getItem("accToken");
                                    // const myrequestOptions = {
                                    //     method: 'DELETE',
                                    //     headers: {
                                    //         'Content-Type': 'application/json',
                                    //         'Authorization': "Bearer " + accessTokenIn
                                    //     },
                                    //     body: JSON.stringify(mydeleteObject)
                                    // };
                                    services.deleteTimeEntriesById(mydeleteObject).then((res) => {
                                        if (res.type == 'success') {

                                            setIsOpen(false);
                                            $(".load-wrap").show();
                                            setShowLoader(0);
                                            $("#updatesuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
                                                $("#updatesuccessMessage").slideUp(500);
                                            });
                                            setTimeout(function() { $('#closemodal').click();
                                                $("#entryform1").trigger("reset");
                                                //window.location.href='/timesheet?req='+addrowday+'&view=day';
                                                onTrigger('');
                                                navigate('/timesheet?req='+addrowday+'&view=day');
                                                onTrigger('');
                                            }, 1000);

                                        }
                                    });
                                }
                                if(res.type=='error'){

                                    setIsOpen(false);
                                    $("#failuresuccessMessage").html(res.message);
                                    $(".load-wrap").show();
                                    setShowLoader(0);
                                    $("#failuresuccessMessage").fadeTo(5000, 500).slideUp(500, function () {
                                        $("#failuresuccessMessage").slideUp(500);
                                    });
                                    setTimeout(function() { $('#closemodal').click();
                                        $("#entryform1").trigger("reset");
                                    //window.location.href='/timesheet?req='+addrowday+'&view=day';

                                    navigate('/timesheet?req='+addrowday+'&view=day');
                                    onTrigger('');
                                    }, 1000);
                                }
                            });

                    } else {
                        //update
                        const requestObject = {
                            "client_id": initialClientlistval,
                            "project_id": initialProjectlistval,
                            "task_id": initialTasklistval,
                            "time_entry_description": $('#edittaskdescription').val(),
                            "duration": $('#edittaskduration').val(),
                            //"duration": formattedEditTimeVal,
                            "user_id": useridval,
                            "day": addrowday,
                            "is_billable": initialBillinglistval,
                            "week_time_entry_id": initialWeekTimeEntryId,
                            "is_holiday": 0,
                            "is_leave": 0,
                            "is_comp_off": 0,
                            "time_sheet_status": entryStatus,
                            "submited_on": null,
                            "approved_on": null,
                            "rejected_on": null
                        }
                        var accessTokenIn=sessionStorage.getItem("accToken");
                        // const requestOptions = {
                        //     method: 'PUT',
                        //     headers: {
                        //     'Content-Type': 'application/json',
                        //     'Authorization': "Bearer "+accessTokenIn
                        //     },
                        //     body: JSON.stringify(requestObject)
                        // };

                        $(".load-wrap").show();
                        services.updateTimeSheetEntryById(requestObject, mytimeentryid).then((res) => {
                            if(res.type=='success'){
                                $("#entryform1").trigger("reset");
                                setIsOpen(false);
                                $(".load-wrap").show();
                                setShowLoader(0);
                                //$("#updatesuccessMessage").html(res.message);
                                $("#updatesuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
                                    $("#updatesuccessMessage").slideUp(500);
                                });
                                setTimeout(function() { $('#closemodal').click();
                                    //window.location.href='/timesheet?req='+addrowday+'&view=day';

                                    navigate('/timesheet?req='+addrowday+'&view=day');
                                    onTrigger('');
                                }, 1000);
                            }
                        });
                    }
                }
            }
        }
        else {
            //add form
            var validation = 0;

            if(isEmpty($('#projectName').val())){
                validation = 1;
                setProjvalidation(1);
            } else {
                setProjvalidation(0);
            }

            if(isEmpty($('#taskName').val())){
                validation = 1;
                setTaskvalidation(1);
            } else {
                setTaskvalidation(0);
            }

            if(isEmpty($('#floatingTextarea2').val())){
                validation = 1;
                setTaskdescriptionvalidation(1);
            } else {
                setTaskdescriptionvalidation(0);
            }

            if(!(isEmpty(addrowday))){
                if(isEmpty($('#taskduration').val()) || parseInt($('#taskduration').val()) <= 0){
                    validation = 1;
                    setTaskdurationvalidation(1);
                } else {
                    setTaskdurationvalidation(0);
                }
            }

            if(validation == 1){
                $('#validationMessage').show();
                return false;
            } else {
                setShowLoader(1);
                //var mydateval = $('#timeentrydate').val();
                var mydateval = [];
                var mydurationval = [];
                var entry_created_from_day_or_week='';


                var defaultduration = "00:00";
                var reqdate = '';
                var view = '';
                if(isEmpty(addrowday)){
                    //week

                    const result = [];
                    const givenDate = new Date(addrowweekday1);
                    const dayOfWeek = givenDate.getUTCDay();

                    // Calculate the start of the week (Monday)
                    const startOfWeek = new Date(givenDate);
                    startOfWeek.setUTCDate(givenDate.getUTCDate() - dayOfWeek + 1);
                    startOfWeek.setUTCHours(0, 0, 0, 0);

                    // Get the dates for the entire week
                    for (let i = 0; i < 7; i++) {
                        const currentDay = new Date(startOfWeek);
                        currentDay.setUTCDate(startOfWeek.getUTCDate() + i);
                        mydateval.push(formatDate(currentDay));
                        mydurationval.push(defaultduration);
                    }

                    entry_created_from_day_or_week = 'week';

                } else {
                    //day
                    var myduration = $('#taskduration').val();
                    
                    entry_created_from_day_or_week = 'day';
                    mydurationval.push(myduration);
                    mydateval.push(addrowday);
                }
                const requestObject =
                {
                  "client_id": $('#clientid').val(),
                   "project_id": $('#projectName').val(),
                   "task_id": $('#taskName').val(),
                   "time_entry_description": $('#floatingTextarea2').val(),
                   "duration": mydurationval,
                   "user_id": useridval,
                   "weekStartDate": addrowweekday1,
                    "weekEndDate": addrowweekday2,
                   "days": mydateval,
                   "is_billable": isBillable,
                   //"week_time_entry_id": 5,
                   "is_holiday": 0,
                   "is_leave": 0,
                   "is_comp_off": 0,
                   "entry_created_from_day_or_week" : entry_created_from_day_or_week,
                   "time_sheet_status": entryStatus,
                   "submited_on": null,
                   "approved_on": null,
                   "rejected_on": null
                }

                
                var accessTokenIn=sessionStorage.getItem("accToken");
                // const requestOptions = {
                //   method: 'POST',
                //   headers: {
                //     'Content-Type': 'application/json',
                //     'Authorization': "Bearer "+accessTokenIn
                //   },
                //   body: JSON.stringify(requestObject)
                // };

                $(".load-wrap").show();
                services.createTimesheetEntry(requestObject).then((res) => {
                    
                    if(res.type=='success'){

                        setIsOpen(false);
                        $(".load-wrap").show();
                        setShowLoader(0);
                        $("#successMessage").html(res.message);
                        $("#successMessage").fadeTo(2000, 500).slideUp(500, function () {
                            $("#successMessage").slideUp(500);
                        });
                        setTimeout(function() { $('#closemodal').click();
                            $("#entryform1").trigger("reset");
                            if(entry_created_from_day_or_week == 'day'){
                                //window.location.href='/timesheet?req='+mydateval[0]+'&view='+entry_created_from_day_or_week;

                                navigate('/timesheet?req='+mydateval[0]+'&view='+entry_created_from_day_or_week);
                                onTrigger('');
                            } else {
                                //window.location.href='/timesheet?req='+mydateval[0]+'&endreq='+mydateval[parseInt(mydateval.length - 1)]+'&view='+entry_created_from_day_or_week;

                                navigate('/timesheet?req='+mydateval[0]+'&endreq='+mydateval[parseInt(mydateval.length - 1)]+'&view='+entry_created_from_day_or_week);
                                onTrigger('');
                            }

                        }, 1000);
                    }
                    if(res.type=='error'){

                        setIsOpen(false);
                        $("#failuresuccessMessage").html(res.message);
                        $(".load-wrap").show();
                        setShowLoader(0);
                        $("#failuresuccessMessage").fadeTo(10000, 500).slideUp(500, function () {
                            $("#failuresuccessMessage").slideUp(500);
                        });
                        
                        setTimeout(function() { $('#closemodal').click();
                            $("#entryform1").trigger("reset");
                            if(entry_created_from_day_or_week == 'day'){
                                //window.location.href='/timesheet?req='+mydateval[0]+'&view='+entry_created_from_day_or_week;

                                navigate('/timesheet?req='+mydateval[0]+'&view='+entry_created_from_day_or_week);
                                onTrigger('');
                            } else {
                                //window.location.href='/timesheet?req='+mydateval[0]+'&endreq='+mydateval[parseInt(mydateval.length - 1)]+'&view='+entry_created_from_day_or_week;

                                navigate('/timesheet?req='+mydateval[0]+'&endreq='+mydateval[parseInt(mydateval.length - 1)]+'&view='+entry_created_from_day_or_week);
                                onTrigger('');
                            }
                        }, 1000);
                    }
                });
            }
        }

    }

    function formatDate(date) {
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const [clientlistval, setclientlistval] = useState('');
    const handleClientChange = (event) => {
        setclientlistval(event.target.value);
        setShowProjectList([]);
        setShowTaskList([]);
        var prostatus = '';
        getProjects(event.target.value,prostatus);
    }

    const [projectlistval, setprojectlistval] = useState('');
    const handleProjectChange = (event) => {
        setprojectlistval(event.target.value);
        setShowTaskList([]);
        $('#editclientspace').show();
        var projectidselected = event.target.value;
        for(var i=0; i< showProjectList.length; i++){
            if(showProjectList[i].project.id == projectidselected){
                
                setProjectClientId(showProjectList[i].project.client.id);
                setProjectClientName(showProjectList[i].project.client.client_name)
            }
        }
        getTasks(projectidselected,useridval);
    }

    const [tasklistval, settasklistval] = useState('');
    const handleTaskChange = (event) => {
        settasklistval(event.target.value);
        getTaskbyId(event.target.value);
    }

    const [taskdescriptionval, settaskdescriptionval] = useState('');
    const handleTextareaValue = (event) => {
        const newValue = event.target.value;
        if (newValue.length <= maxLength) {
            settaskdescriptionval(newValue);
        }
        //settaskdescriptionval(event.target.value);
    }

    const [projectClientName, setProjectClientName] = useState('');
    const [projectClientId, setProjectClientId] = useState('');

    $("#projectName").off('change').on('change', function () {
        setShowTaskList([]);
        $('#clientspace').show();
        var projectidselected = $('#projectName').val();
        for(var i=0; i< showProjectList.length; i++){
            if(showProjectList[i].project.id == projectidselected){
                
                setProjectClientId(showProjectList[i].project.client.id);
                setProjectClientName(showProjectList[i].project.client.client_name)
            }
        }
        getTasks(projectidselected,useridval);
    });

    function getTasks(projectid, userid){
        const requestObject = {
            "userId": userid,
            "taskId": "",
            "projectIds": [projectid],
            "taskUserStatus": "",
            "taskStatus": ['New', 'In Progress', 'Re-opened'],
            "clientId":"",
            "sortOrder":"ASC",
            "sortBy":"TaskStatus"
          }
        
        var accessTokenIn=sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer "+accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };
        services.getTasksforUser(requestObject).then((res) => {
            
            if(res.type == 'success'){
                if(res.data != null){
                    var mytasksList = [];
                    for(var i=0; i< res.data.length; i++){
                        if(res.data[i].task_user_status === 'assigned'){
                            mytasksList.push(res.data[i]);
                        }
                    }
                    //setShowTaskList(res.data);
                    setShowTaskList(mytasksList);
                }
            }
        });
        setShowTaskDetails('');
    }

    $("#taskName").off('change').on('change', function () {
        var taskidselected = $('#taskName').val();
        getTaskbyId(taskidselected);
    });

    $("#edittaskName").off('change').on('change', function () {
        var taskidselected = $('#edittaskName').val();
        getEditTaskbyId(taskidselected);
    });


    const handleChange = (event) => {
        if(event.target.checked){
            setIsBillable(1);
        } else{
            setIsBillable(0);
        }
      };

      const handleWeekChange = (event) => {
        if(event.target.checked){
            setisbillablecheck(1);
        } else{
            setisbillablecheck(0);
        }
      };
      const isEmpty = (value) => {
        return value == null || String(value).trim() === '';
      }

      const getTitleHead = () => {
        if(!isEmpty(mytimeentryid)){
            if(isEmpty(addrowday)){
                //week

                var displaynewdate1 = new DateObject(addrowweekday1);
                var displaynewdate2 = new DateObject(addrowweekday2);
                localStorage.setItem('itemforDate',displaynewdate1);
                return 'Edit a Row for the Week: '+displaynewdate1.format("DD MMM")+' - '+displaynewdate2.format("DD MMM YYYY");
            } else {
                //day
                const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                var displaydate = new DateObject(addrowday);
                var displaynewdate = new Date(addrowday);
                const dayIndex = displaynewdate.getDay();
                localStorage.setItem('itemforDate',addrowday);

                var date = new Date(addrowday + 'T00:00:00Z'); // Append 'T00:00:00Z' to ensure it's treated as UTC

                // Array of day names
                var daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

                // Get the day of the week in UTC
                var dayOfWeekIndex = date.getUTCDay(); // Use getUTCDay() for UTC
                var dayOfWeekName = daysOfWeek[dayOfWeekIndex];
                return 'Edit Time Entry for '+dayOfWeekName+', '+displaydate.format("DD MMM");
            }
        }
        else {
            if(isEmpty(addrowday)){
                //week
                var displaynewdate1 = new DateObject(addrowweekday1);
                var displaynewdate2 = new DateObject(addrowweekday2);
                localStorage.setItem('itemforDate',addrowweekday1);
                return 'Add a Row for the Week: '+displaynewdate1.format("DD MMM")+' - '+displaynewdate2.format("DD MMM YYYY");
            } else {
                //day
                const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                var displaydate = new DateObject(addrowday);
                var displaynewdate = new Date(addrowday);
                const dayIndex = displaynewdate.getDay();
                localStorage.setItem('itemforDate',addrowday);

                var date = new Date(addrowday + 'T00:00:00Z'); // Append 'T00:00:00Z' to ensure it's treated as UTC

                // Array of day names
                var daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

                // Get the day of the week in UTC
                var dayOfWeekIndex = date.getUTCDay(); // Use getUTCDay() for UTC
                var dayOfWeekName = daysOfWeek[dayOfWeekIndex];

                return 'New Time Entry for '+dayOfWeekName+', '+displaydate.format("DD MMM");
            }
        }
      }

      const removeEntry = (timesheetentryid) => {
        setShowLoader(1);
        var mydeleteObject= {
            "ids":[timesheetentryid]
        }
        var accessTokenIn = sessionStorage.getItem("accToken");
        // const myrequestOptions = {
        //     method: 'DELETE',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': "Bearer " + accessTokenIn
        //     },
        //     body: JSON.stringify(mydeleteObject)
        // };
        services.deleteTimeEntriesById(mydeleteObject).then((res) => {
            if (res.type == 'success') {
                setShowPopup(false);
                setIsOpen(false);
                $(".load-wrap").show();
                setShowLoader(0);
                $("#removesuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
                    $("#removesuccessMessage").slideUp(500);
                });
                setTimeout(function() { $('#closemodal').click();
                    
                    if(isEmpty(addrowday)){
                        //window.location.href='/timesheet?req='+addrowweekday1;

                        navigate('/timesheet?req='+addrowweekday1);
                        onTrigger('');
                    }
                    else{
                        //window.location.href='/timesheet?req='+addrowday;

                        navigate('/timesheet?req='+addrowday+'&view=day');
                        onTrigger('');
                    }

                }, 1000);
            }
        });
      }

    const [entryIdforDelete, setEntryIdforDelete] = useState(null);
    const [taskRowDateforDelete, setTaskRowDateforDelete] = useState(null);

    const deleteTimeEntryByEntryId = (entryid) => {
        setEntryIdforDelete(entryid);
        setShowPopup(true);
     }

     const handleDeleteEntry = () => {
       
        removeEntry(entryIdforDelete);
     }

     const handleCancel = () => {
        setShowPopup(false);
     }


    const [time, setTime] = useState('');
    const [editTime, setEditTime] = useState('');

    const formatTime = (value) => {
      // Remove any non-digit characters
      const digits = value.replace(/\D/g, '');

      // Ensure the value is not empty
      if (digits.length === 0) return '';

      // Split the digits into hours and minutes
      // If there are more than 4 digits, treat the extra as part of the hours
      const len = digits.length;
      var minutes = parseInt(digits.slice(-2)).toString();
      var hours = parseInt(digits.slice(0, len - 2)).toString();
        
        if(!isNaN(hours)){
            if(hours.length > 0){
                if(hours.length <2){
                    hours = '0'+hours;
                }
                if(hours.length > 2){
                    hours = hours;
                }
            } else{
                hours = '00';
            }
        } else {
            hours = '00';
        }

        
        if(!isNaN(minutes)){
            if(minutes.length > 0){
                if(minutes.length <2){
                    minutes = '0'+minutes;
                }
                if(minutes.length > 2){
                    minutes = minutes;
                }
            } else{
                minutes = '00';
            }
        } else {
            minutes = '00';
        }
      
      return `${hours}:${minutes}`;
    };

    const [time1, setTime1] = useState('');

    // Handler for `HHMM` format
    const formatTimeFromHHMM = (input) => {
      if (input.length === 4 && !isNaN(input)) {
        const hours = input.substring(0, 2);
        const minutes = input.substring(2, 4);

        // Optionally, validate ranges
        if (hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60) {
            setTime1(`${hours}:${minutes}`);
        } else {
            setTime1('Invalid time');
        }
      } else {
        setTime1('Invalid format');
      }
    };

    // Handler for minutes format
    const convertMinutes = (input) => {
      const totalMinutes = parseInt(input, 10);

      if (!isNaN(totalMinutes) && totalMinutes >= 0) {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        // Pad hours and minutes with leading zeros if needed
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');

        setTime1(`${formattedHours}:${formattedMinutes}`);
      } else {
        setTime1('Invalid number of minutes');
      }
    };


    const handleChangeValue = (e) => {
      const value = e.target.value;
      setTime(formatTime(value));
    };

    const handleChangeEditValue = (e) => {
        const value = e.target.value;
        setEditTime(formatTime(value));
      };

  const [inputtime2, setInputtime2] = useState('');
  const [formattedTimeVal2, setFormattedTimeval2] = useState('');

  function formatTimeInput(input) {
    let hours, minutes;

    // Convert input to string and pad with leading zeros
    let timeString = input.toString().padStart(4, '0');

    // Extract hours and minutes
    hours = timeString.slice(0, 2);
    minutes = timeString.slice(2);

    // Format time as HH:mm
    setFormattedTimeval2(`${hours}:${minutes}`);
  }

  const handleChangetime2 = (e) => {
    const value = e.target.value;
    setInputtime2(value);
    formatTimeInput(value);
  };

  const handleChangeEdittime = (e) => {
    const value = e.target.value;
    setInputEditTime(value);
    FormatEditTimeInput(value);
  };

  const [inputedittime, setInputEditTime] = useState('');
  const [formattedEditTimeVal, setFormattedEditTimeval] = useState('');

  function FormatEditTimeInput(input) {
    let hours, minutes;

    // Convert input to string and pad with leading zeros
    let timeString = input.toString().padStart(4, '0');

    // Extract hours and minutes
    hours = timeString.slice(0, 2);
    minutes = timeString.slice(2);

    // Format time as HH:mm
    setFormattedEditTimeval(`${hours}:${minutes}`);
  }

  const checkTimeFormat = () => {
    
    var timeEntered = formattedTimeVal2;
    if(!isNaN(parseInt(timeEntered)) && parseInt(timeEntered) > 0){
        var devidedtime =timeEntered.split(':');
        
        if(devidedtime[0] > 23 || devidedtime[1] > 59){
            setTaskdurationvalidation(1);
        } else {
            setTaskdurationvalidation(0);
        }
    }
    else{
        setTaskdurationvalidation(0);
    }
  }

  const checkEditTimeFormat = () => {
    
    var edittimeEntered = formattedEditTimeVal;
    if(!isNaN(parseInt(edittimeEntered)) && parseInt(edittimeEntered) > 0){
        var devidededittime =edittimeEntered.split(':');
        
        if(devidededittime[0] > 23 || devidededittime[1] > 59){
            setEditTaskdurationvalidation(1);
        } else {
            setEditTaskdurationvalidation(0);
        }
    }
    else{
        setEditTaskdurationvalidation(0);
    }
  }

  const handleTimeInputBlurCustom = (event) => {
    const inputElement = event.target;
    let value = inputElement.value.trim();

    // Remove all non-digit characters except for the first colon
    value = value.replace(/[^0-9:]/g, '');

    // Allow only one colon
    const colonCount = (value.match(/:/g) || []).length;
    if (colonCount > 1) {
      value = value.split(':')[0] + ':' + value.split(':').slice(1).join('');
    }

    // Check if the value is already in a good format
    const isValidTime = (value) => {
      const [hours, minutes] = value.split(':').map(part => parseInt(part, 10));
      return !isNaN(hours) && hours >= 0 && hours <= 23 &&
             !isNaN(minutes) && minutes >= 0 && minutes <= 59 &&
             value.split(':').length === 2;
    };

    if (isValidTime(value)) {
      inputElement.value = value;
      return;
    }

    // Handle cases for incomplete or incorrect values
    if (value.length === 1 && !value.includes(':')) {
      // Case for single digit (e.g., '1'): append ':00'
      value = value + ':00';
    } else if (value.length === 2 && !value.includes(':')) {
      // Case for two digits (e.g., '10'): append ':00'
      value = value + ':00';
    } else if (value.length === 3 && !value.includes(':')) {
      // Case for 'HMM' (e.g., '238'): format as '0H:MM'
      value = '0' + value[0] + ':' + value.slice(1);
    } else if (value.length === 3 && value.includes(':')) {
      // Case for 'H:MM': no change needed
    } else if (value.length === 4 && !value.includes(':')) {
      // Case for 'HHMM': format as 'HH:MM'
      value = value.slice(0, 2) + ':' + value.slice(2);
    } else if (value.length === 5 && !value.includes(':')) {
      // Case for 'HHMM' if the value is already correct
      value = value.slice(0, 2) + ':' + value.slice(2);
    }

    // Validate and format the time
    const [hours, minutes] = value.split(':').map(part => parseInt(part, 10));
    if (isNaN(hours) || hours > 23 || isNaN(minutes) || minutes > 59) {
      value = ''; // Clear the input if the value is invalid
    } else {
      // Ensure hours and minutes are zero-padded
      value = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }

    inputElement.value = value;
  };

  const [textvalue, setTextValue] = useState('');
  const maxLength = 500; // Set your character limit here

  const handleChangeTextarea = (event) => {
      const newValue = event.target.value;
      if (newValue.length <= maxLength) {
          setTextValue(newValue);
      }
  };



  return(
  <>
  <div class="modal fade " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog"  open={isOpen}>
        <form id="entryform1" onSubmit={handleSubmit(submitValue)}>
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{getTitleHead()}</h5>
                <button type="button" id="closemodal" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            {showLoader === 1 ?
    <div class="loadwrapperpopup">
        <div class="clock"><div class="circle"></div></div>
    </div>
    : '' }
            <div class="modal-body" id="addbody">
                
                <div class="form-group mb-2">
                <input type="hidden" id="timeentrydate" name="timeentrydate" value={getDateFormatCalendarDate(date)} />
                    <label for="recipient-name" class="col-form-label">Project*</label>
                    <select class={`form-select ${projvalidation === 1 ? 'errorinp' : ''}`} aria-label="Default select example" id="projectName" name="projectName">
                        <option selected value="">Select Project</option>
                        {showAddProjectList.map((data, index) => (
                            <option value={data.project.id} >{data.project.name}</option>
                        ))}
                    </select>
                </div>

                <div class="form-group mb-2" id="clientspace">
                    <div className="d-flex align-items-center">
                        <label for="recipient-name" class="col-form-label" style={{"min-width":'95px'}}>Client Name </label>
                        <input type="hidden" id="clientid" name="clientid" value={projectClientId}/>
                        <span class="badge rounded-pill project-badge text-dark my-2 ">{projectClientName}</span>
                    </div>
                </div>

                <div class="form-group mb-2">
                   <div className="d-flex justify-content-between align-items-center">
                   <label for="recipient-name" class="col-form-label">Task*</label>

<a href="#" class="review" data-bs-toggle="modal" data-bs-target="#createTaskModal" data-bs-whatever="@mdo">Create Task</a>
                   </div>

                    <select class={`form-select ${taskvalidation === 1 ? 'errorinp' : ''}`} aria-label="Default select example" id="taskName" name="taskName">
                        <option value="" selected>Select Task</option>
                        {showTaskList.map((data, index) => (
                            <option value={data.task.id} >{data.task.name}</option>
                        ))}


                    </select>
                </div>
                {!isEmpty(showTaskDetails) ?
                <div class="form-group mb-2">
                    <label for="recipient-name" class="col-form-label">Task Description</label>
                    <div class="form-floating mb-3">
                        <textarea class="form-control pt-1"  placeholder="Leave a comment here" id="floatingTextarea2Disabled" disabled>{showTaskDetails}</textarea>
                    </div>

                </div>
                : ''}

                <div class="form-group mb-2">
                    <label for="recipient-name1" class="col-form-label">Activities Performed*</label>
                    <div class="form-floating">
                        <textarea class={`form-control pt-1 ${taskdescriptionvalidation === 1 ? 'errorinp' : ''}`} placeholder={`Give details about duties performed in max ${maxLength} characters`} value={textvalue} onChange={handleChangeTextarea} id="floatingTextarea2"></textarea>
                    </div>
                    <small className="textAreaMaxLength">(Max 500 Characters)</small>
                </div>
                <div class="py-2">
                    <div class="d-grid justify-content-end">
                        {!(isEmpty(addrowday)) ?
                        <>
                        <div class="d-flex align-items-center">
                            <span>Duration*</span>

                             <input placeholder="00:00" type="text" format="HH:mm" style={{maxWidth:"100px"}} id="taskduration" name="taskduration" className={`no-icon form-control ms-2 ${taskdurationvalidation === 1 ? 'errorinp' : ''}`}  onBlur={handleTimeInputBlurCustom} maxLength="5" />
                        </div>
                        {/* {taskdurationvalidation === 1 ?
                        <small style={{"color": "red"}}>Task duration value should be less than 2360</small> : ''} */}
                       {/* {formattedTimeVal2}*/}
                        </>


                        : ''
                        }

                        <div class="d-flex align-items-center gap-2 py-2 form-switch justify-content-end">
                            <label class="form-check-label" for="flexSwitchCheckDefault">Non-Billable</label>
                            <input class="form-check-input custtoggle mx-0" type="checkbox" role="switch" id="flexSwitchCheckDefault"  onChange={handleChange} checked={isBillable}/>
                            <label class="form-check-label" for="flexSwitchCheckDefault">Billable</label>
                        </div>
                    </div>
                </div>
                <div className="validationglobal p-2" id="validationMessage">
                        <div className="validateitem">
                            {/* <span className="errimg"><img class="icon" src="images/error.svg" alt=""/></span> */}
                            <p>Please fill out all the required fields!</p>
                        </div>
                </div>
            </div>

            <div class="modal-body" id="editbody">

                <div class="form-group mb-2">
                    <label for="recipient-name" class="col-form-label">Project*</label>
                    <select class={`form-select ${editprojvalidation === 1 ? 'errorinp' : ''}`} aria-label="Default select example" id="editprojectName" name="editprojectName" value={projectlistval}  onChange={handleProjectChange} ref={unassignProjectDropdownRef} style={{ display: 'flex' }}>
                        {showProjectList.map((data, index) => (
                            <option value={data.project.id} >{data.project.name}</option>
                        ))}
                    </select>
<input type="text" ref={unassignProjectNameRef} style={{ display: 'none' }} id="unassignedProjectName" value={initialProjectlistName} className="form-control pt-1 readonly" />

                </div>

                <div class="form-group mb-2" id="editclientspace">
                    <div className="d-flex align-items-center">
                        <label for="recipient-name" class="col-form-label" style={{"min-width":'95px'}}>Client </label>
                        <input type="hidden" id="clientid" name="clientid" value={projectClientId}/>
                        <span class="badge rounded-pill project-badge text-dark my-2 ">{projectClientName}</span>
                    </div>
                </div>

                <div class="form-group mb-2">
                   <div className="d-flex justify-content-between align-items-center">
                   <label for="recipient-name" class="col-form-label">Task*</label>

                   </div>

                    <select class={`form-select ${edittaskvalidation === 1 ? 'errorinp' : ''}`} aria-label="Default select example" id="edittaskName" name="edittaskName" value={tasklistval} onChange={handleTaskChange}>
                        {showTaskList.map((data, index) => (
                            <option value={data.task.id} >{data.task.name}</option>
                        ))}


                    </select>
                </div>
                {!isEmpty(showEditTaskDetails) ?
                <div class="form-group mb-2">
                    <label for="recipient-name" class="col-form-label">Task Description</label>
                    <div class="form-floating mb-3">
                        <textarea class="form-control pt-1"  placeholder="Leave a comment here" id="floatingTextarea2Disabled" disabled value={showEditTaskDetails}></textarea>
                    </div>

                </div>
                : ''}

                <div class="form-group mb-2">
                    <label for="recipient-name1" class="col-form-label">Activities Performed*</label>
                    <div class="form-floating">
                        <textarea class={`form-control pt-1 ${edittaskdescriptionvalidation === 1 ? 'errorinp' : ''}`} placeholder="Give details about the duties performed." value={taskdescriptionval} onChange={handleTextareaValue} id="edittaskdescription"></textarea>
                    </div>
                    <small>(Max 500 Characters)</small>
                </div>
                <div class="py-2">
                    <div class="d-grid justify-content-end">
                        {!(isEmpty(addrowday)) ?
                        <>
                        <div class="d-flex align-items-center">
                            <span>Duration*</span>

                             <input type="text" style={{maxWidth:"100px"}} id="edittaskduration" name="edittaskduration" className={`no-icon form-control ms-2 ${edittaskdurationvalidation === 1 ? 'errorinp' : ''}`} defaultValue={'12:00'} onBlur={handleTimeInputBlurCustom} maxLength="5" />
                        </div>
                        {/* {edittaskdurationvalidation === 1 ?
                            <small style={{"color": "red"}}>Task duration value should be less than 2360</small> : ''}*/}
                            </>
                        : ''
                        }


                        <div class="d-flex align-items-center gap-2 py-2 form-switch justify-content-end">
                            <label class={`form-check-label `} for="editflexSwitchCheckDefault" >Non-Billable</label>
                            <input class="form-check-input custtoggle mx-0" type="checkbox" role="switch" id="editflexSwitchCheckDefault" value={showTaskInfo.is_billable} onChange={handleWeekChange} checked={isbillablecheck}/>
                            <label class={`form-check-label `} for="editflexSwitchCheckDefault">Billable</label>
                        </div>
                    </div>
                </div>
                <div className="validationglobal p-2" id="editvalidationMessage">
                        <div className="validateitem">
                            {/* <span className="errimg"><img class="icon" src="images/error.svg" alt=""/></span> */}
                            <p>Please fill out all the required fields!</p>
                        </div>
                </div>
            </div>

            <div className="modal-footer d-flex justify-content-between align-items-center">
                <div className="d-flex gap-2 justify-content-between w-100">
                  <div className="d-flex gap-2">
                  <button type="submit" class="btn btn-primary"
                    ref={buttonRef}
                //    disabled={isButtonDisabled} // Button is disabled based on the condition
                    >Save</button>
                    <button type="button" class="btn btn-outline-dark mx-0" data-bs-dismiss="modal">Cancel</button>
                  </div>
                    <a id="deleteEntryonEdit" class="review" onClick={() => deleteTimeEntryByEntryId(mytimeentryid)} data-bs-whatever="@mdo">  <img src="images/delete-red.svg" className="me-1" width={15} alt="" /> Delete</a>
                    {/* onClick={() => removeEntry(mytimeentryid)} */}
                </div>


                    <div ref={unassignMinInfoRef} style={{ display: 'none' }}   className="unassign gap-2">
                    <img src="images/redcheck.svg" width={15} alt="" />
                       <div className="d-flex">
                         {/* Hidden by default */}
                         <p ref={projeUnassignRef} style={{ display: 'none' }}>Project unassigned</p>
                        <p style={{ display: 'none' }} className="px-2" ref={projeInactiveSeparateRef}>&</p>
                        <p ref={projeInactiveRef} style={{ display: 'none' }}>&nbsp;Project Inactive</p>
                       </div>
                    </div>



            </div>
            <div class="px-3">
            <div id="successMessage" class="alert alert-success" role="alert">Time Entry Created Successfully!</div>
            <div id="updatesuccessMessage" class="alert alert-success" role="alert">Time Entry Updated Successfully!</div>
            <div id="failuresuccessMessage" class="alert alert-danger" role="alert"></div>
            </div>
        </div>



        </form>
    </div>

    {showPopup && (
            <DeleteConfirmationPopup
            message="Are you sure you want to Delete this entry?"
            onConfirm={handleDeleteEntry}
            onCancel={handleCancel}
            />
        )}
    </div>
    <CreateTask mydate={addrowday} myweekday1={addrowweekday1} myweekday2={addrowweekday2}/>
  </>
)};

AddTime.propTypes = {};

AddTime.defaultProps = {};

export default AddTime;

import React, { useEffect, useState, useRef } from "react";
import { useLocation, useSearchParams } from 'react-router-dom';
import services from "../../../services/services";
import DateObject from "react-date-object";
import TimesheetHeader from "./timesheet-header";
import { useForm } from "react-hook-form";
import $ from 'jquery';
import TaskDescription from "../../modals/task-description";

const AdminTimesheetManagement = () => {
    //const useridval = 4;
    const [searchParams] = useSearchParams();
    const printTimeSheetWeekviewRef = useRef();
    const printTimeSheetWeekviewHeaderRef = useRef();

    // Create refs for each tab link
    const [showValidations, setShowValidations] = useState(0);
    const pendingTabRef = useRef(null);
    const unsubmittedTabRef = useRef(null);
    const approvedTabRef = useRef(null);

    const [companyPrintLogo, setCompanyPrintLogo] = useState("images/timesheet-logo.png");
    const [timesheetByStatusList, setTimesheetByStatusList] = useState([]);
    const [timesheetByUserList, setTimesheetByUserList] = useState([]);
    const [useridval, setuseridval] = useState(sessionStorage.getItem('userIdval'));
    const [loggedUser, setLoggedUser] = useState('');
    const [listStatus, setListStatus] = useState('');
    var mylistStatus = '';

    const [myListViewStatus, setMyListViewStatus] = useState(0);
    const [myListConsolidatedStatus, setMyListConsolidatedStatus] = useState(0);
    const [totalEntryTimeWeek, setTotalEntryTimeWeek] = useState('');
    const [totalBillableTimeWeek, setTotalBillableTimeWeek] = useState('');
    const [totalNonBillableTimeWeek, setTotalNonBillableTimeWeek] = useState('');
    const [totalStatusWeek, setTotalStatusWeek] = useState('');
    const [showWeekTimeEtriesList, setShowWeekTimeEtriesList] = useState([]);
    const [totalWeekTime, setTotalWeekTime] = useState('');
    const [dateWiseTimes, setDateWiseTimes] = useState([]);
    const [weekLimitDates, setWeekLimitDates] = useState('');
    const [weekLimitDatesFull, setWeekLimitDatesFull] = useState('');
    const [detailedViewDate1, setDetailedViewDate1] = useState('');

    const [selectedProject, setSelectedProject] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedTask, setSelectedTask] = useState(null);
    const [selectedBillable, setSelectedBillable] = useState(null);
    const [selectedEntryResult, setSelectedEntryResult] = useState([]);
    const [fullEntryResult, setFullEntryResult] = useState([]);
    const [viewTimesheetDetailsStat, setViewTimesheetDetailsStat] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [timesheetstatusTab, setTimesheetstatusTab] = useState('Submitted');
    const [timesheeetViewUserCompanyName, setTimesheeetViewUserCompanyName] = useState('');


    const [showRejectionLoader, setShowRejectionLoader] = useState(0);
    const [showWithdrawLoader, setShowWithdrawLoader] = useState(0);

    //////////////////////////
    // States for pagination
    const [clientCurrentPage, setClientCurrentPage] = useState(1);
    const [clientTotalPages, setClientTotalPages] = useState(1);
    const pageSize = 10;

    const [companyid, setCompanyId] = useState(sessionStorage.getItem('userCompanyId'));
    const [adminCompanyid, setAdminCompanyId] = useState(sessionStorage.getItem('adminCompany'));

    const [showLoader, setShowLoader] = useState(0);

    //#############################
    // Pagination Handlers for Clients
    const handleClientPrevPage = () => {
        if (clientCurrentPage > 1) {
            setClientCurrentPage(clientCurrentPage - 1);
        }
    };

    const handleClientNextPage = () => {
        if (clientCurrentPage < clientTotalPages) {
            setClientCurrentPage(clientCurrentPage + 1);
        }
    };

    const handlePrintTimeSheetWeekview = () => {
        const contentToPrint = printTimeSheetWeekviewRef.current;
        const headerContent = printTimeSheetWeekviewHeaderRef.current;
    
        // Get the current date and time
        const now = new Date();
        const formattedDate = now.toLocaleDateString(); // Get the date in 'MM/DD/YYYY' format
        const formattedTime = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Get time in 'HH:MM' format
    
        const printWindow = window.open('', '', 'width=800,height=600');
        printWindow.document.write(`
          <html>
            <head>
              <title>&nbsp;</title>
              <link href="/css/bootstrap.min.css" rel="stylesheet">
              <link href="/css/style-guide.css" rel="stylesheet">
              <link href="/assets/custome.css" rel="stylesheet">
              <style>
                /* Custom styles for print view */
                .weekview-list-task strong { display: flex; gap: 5px; }
                .weekcalmain { padding: 5px 0px !important; }
                .item small { font-size: 11px; }
                .mainbluetxt { font-size: 1.1em; }
                .border-bottom { border: none !important; }
                .no-print { display: none !important; }
                .weekcallist span, .weekcallist p, .weekcallist div, .weekcallist b, 
                .weekmainitem b, .weekmainitem small { font-size: 12px; }
                .weekcallist button { padding: 0px 10px; }
                .weekcal, .weekcallist { grid-template-columns: auto 350px 60px; }
                .weekcalmain { grid-template-columns: 50px 50px 50px 50px 50px 50px 50px; }
                .weekcallist a { display: none; }
                .accordion-item { margin-bottom: 3px; padding-bottom: 3px; box-shadow: none !important; 
                  border: 0px; border-bottom: 1px solid #99999950 !important; border-radius: 0px !important; }
                .p-4 { padding: 0px; } 
                .py-2 { padding: 2px 10px !important; } 
                .ms-2 { margin-left: 0px !important; } 
                .ps-2 { padding-left: 0px !important; }
                .print-total { justify-content: end !important; padding-right: 0px; font-weight: 600; }
                button { box-shadow: none !important; }
                .weekcal { border-top: 1px solid #99999950; border-bottom: 1px solid #99999950; margin-bottom: 10px; }
    
                @media print {
                  .no-print { display: none; }
    
                  /* Adjust header and footer positioning */
                  header, footer {
                    position: fixed;
                    left: 0;
                    right: 0;
                    background-color: #fff;
                    z-index: 1000;
                    width: 100%;
                  }
                  header {
                    top: 0;
                    height: auto; /* Allow auto height based on content */
                    text-align: center;
                    font-size: 12px;
                    border-bottom: 1px solid #000;
                    padding: 5px 0;
                  }
                  footer {
                    bottom: 0;
                    height: auto; /* Allow auto height based on content */
                    font-size: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 5px 0;
                  }
    
                  .content {
                    margin-top: 40mm; /* Increase margin to account for header */
                    margin-bottom: 40mm; /* Increase margin to account for footer */
                  }
    
                  /* Handle page breaks to avoid content cutting off */
                  .page-break {
                    page-break-before: always;
                    margin-top: 25mm; /* Ensure space for next page's header */
                  }
                }
    
                .page-break { 
                  page-break-before: always; /* Force page break before element */
                }
              </style>
            </head>
            <body>
              <header>
                ${headerContent.innerHTML}
              </header>
    
              <div class="content">
                ${contentToPrint.innerHTML}
              </div>
    
              <footer>
                <table style="width:100%; border-top:1px solid;">
                  <tr>
                    <td>
                      <div class="d-flex gap-1">
                        <span>Printed on:</span> 
                        <span>${formattedDate}</span> 
                        <span>@</span> 
                        <span>${formattedTime}</span>
                      </div>
                    </td>
                    <td style="text-align:right">
                      <img src="images/timesheet-logo.png" class="print-logo" height="40" alt="Timesheet Logo" />
                    </td>
                  </tr>
                </table>
              </footer>
            </body>
          </html>
        `);
    
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
    
        // Uncomment below to close window after printing
        // setTimeout(() => {
        //     printWindow.close();
        // }, 3000); // Adjust the delay as needed
    };

    const handlePrintTimeSheetWeekviewOld = () => {
        const contentToPrint = printTimeSheetWeekviewRef.current;
        const headerContent = printTimeSheetWeekviewHeaderRef.current;
        const printWindow = window.open('', '', 'width=800,height=600');

        // Get the current date and time
        const now = new Date();
        const formattedDate = now.toLocaleDateString(); // Get the date in 'MM/DD/YYYY' format
        const formattedTime = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Get time in 'HH:MM' format

        printWindow.document.write(`
          <html>
            <head>
              <title>&nbsp;</title>
              <link href="/css/bootstrap.min.css" rel="stylesheet">
                <link href="/css/style-guide.css" rel="stylesheet">
                <link href="/assets/custome.css" rel="stylesheet">
              <style>
                /* Add custom styles for print view */
                 .weekview-list-task strong {display: flex;gap: 5px; align-items-center}
                .item small {font-size:11px}.mainbluetxt {font-size:1.1em}
                .border-bottom{ border: none !important}
                .no-print { display: none !important; }
                .weekcallist span, .weekcallist p, .weekcallist div, .weekcallist b, .weekmainitem b, .weekmainitem small  { font-size:12px; }
                .weekcallist button { padding:0px 10px; }
                .weekcal, .weekcallist { grid-template-columns: auto 350px 60px; }
                .weekcalmain { grid-template-columns: 50px 50px 50px 50px 50px 50px 50px; }
                .weekcallist a { display:none; }
                .accordion-item { margin-bottom:3px; padding-bottom:3px; box-shadow:none !important; border:0px; border-bottom:1px solid #99999950 !important; border-radius:0px !important }
                .p-4 { padding:0px !important; } .py-2 { padding:0px 10px !important} .ms-2{margin-left:0px !important} .ps-2 {padding-left:0px !important}
                .print-total { justify-content: end !important; display:flex; padding-right:25px; font-weight:600}
                button {box-shadow: none !important;}
                .weekcal{ border-top:1px solid #99999950; border-bottom:1px solid #99999950; margin-bottom:5px}

                @media print {
                .no-print { display: none; }
              }
                 .no-print { display: none !important; }

                 header {
                        position: fixed;
                        top: 0;
                        left: 0;
                        right: 0;
                        text-align: center;
                        font-size: 12px;
                        border-bottom: 1px solid #000;
                        padding: 5px 0;
                        background: #fff; /* Ensure background is white */
                    }
              .content {
                       page-break-inside: auto;
                            page-break-before: auto;
                            page-break-after: always;
                            margin-top:21mm;
                            padding-bottom:35mm

                    }

                 footer {
                         position: fixed;
                        bottom: 100px;
                        left: 0;
                        right: 0;
                        width:100%;
                        display:flex;
                        font-size: 10px;

                    }
              </style>
            </head>
            <body>

             <header>
                ${headerContent.innerHTML}
            </header>

            <div class="content">
                ${contentToPrint.innerHTML}
            </div>


               <footer>

                              <table style="width:100%;border-top:1px solid; position:absolute; bottom:-100px"
                                    <tr style="padding-top:15px;">
                                        <td> <div class="d-flex gap-1">
                      <span>Printed on :</span> <span>${formattedDate}</span> <span>@</span> <span>${formattedTime}</span>
                    </div></td>
                                        <td style="text-align:right"> <img style="padding-top:10px;" src="images/timesheet-logo.png" class="print-logo" height="40" alt="" /></td>
                                        </tr>
                                </table>
                          </footer>


            </body>
          </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        //printWindow.close();
        // setTimeout(() => {
        //     printWindow.close();
        //   }, 1800000); // Adjust the delay as needed
    };

    // Example of rendering paginations independently
    const renderClientPagination = () => {
        const pageNumbers = generatePageNumbers(clientCurrentPage, clientTotalPages);

        return (
            <div className="pagination-controls justify-content-end mt-3">
                <button onClick={handleClientPrevPage} disabled={clientCurrentPage === 1}>
                    « Prev
                </button>

                {pageNumbers.map(page => (
                    <button
                        key={page}
                        onClick={() => setClientCurrentPage(page)}
                        className={page === clientCurrentPage ? 'active' : ''}
                    >
                        {page}
                    </button>
                ))}

                <button onClick={handleClientNextPage} disabled={clientCurrentPage === clientTotalPages}>
                    Next »
                </button>
            </div>
        );
    };

    // Helper function to generate page numbers
    const generatePageNumbers = (currentPage, totalPages) => {
        const maxPageButtons = 5;
        let startPage, endPage;

        if (totalPages <= maxPageButtons) {
            startPage = 1;
            endPage = totalPages;
        } else {
            const maxPagesBeforeCurrent = Math.floor(maxPageButtons / 2);
            const maxPagesAfterCurrent = Math.ceil(maxPageButtons / 2) - 1;

            if (currentPage <= maxPagesBeforeCurrent) {
                startPage = 1;
                endPage = maxPageButtons;
            } else if (currentPage + maxPagesAfterCurrent >= totalPages) {
                startPage = totalPages - maxPageButtons + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - maxPagesBeforeCurrent;
                endPage = currentPage + maxPagesAfterCurrent;
            }
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };


    //////////////////////


    /////////////////////////

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all", mode: "onBlur"
    });
    const [myUserIdsFilter, setMyUserIdsFilter] = useState([]);
    useEffect(() => {
        //setWeekCheckedValues([]);
        $('#validationMessage').hide();
        $('#withdrawalvalidationMessage').hide();
        $('#approvalsuccessMessage').hide();
        $('#rejectionsuccessMessage').hide();
        $('#withdrawalsuccessMessage').hide();
        $('#emailRemindersuccessMessageSingle').hide();
        $('.loadwrapper').show();
        $('#userSelectionValidation').hide();
        $('#weekSelectionValidation').hide();
        $('#approvedsuccessMessage').hide();
        $('#emailRemindersuccessMessage').hide();
        setShowValidations(1);
        var loggedinUserRole = sessionStorage.getItem('userRole');
        $('#userList').hide();
        $('#detailed_view').hide();
        // $('#hideTimesheetDetails').hide();
        // $('#timesheetDetails').hide();
        setLoggedUser(loggedinUserRole);
        var myUserIds = [];
        if (loggedinUserRole.toLowerCase() === 'manager') {
            const loggedUserCompanyId = sessionStorage.getItem('userCompanyId');

            setCompanyPrintLogo(services.fetchServerAPIEndPoint()+"uploads/company-logo/company_logo_file_"+loggedUserCompanyId+".png");
            const managerLogId = sessionStorage.getItem('userIdval');
            services.getUsersList('', 1, 1, 1000, managerLogId, loggedUserCompanyId).then((res) => {
                //var myUserIds = [];
                if (res.data.type === 'success') {
                    //setUserListInfo(res.data.data);
                    var userListIds = res.data.data;
                    console.log(userListIds);
                    userListIds.forEach((users) => {
                        console.log(users.user.id);
                        myUserIds.push(parseInt(users.user.id)); // Ensure all ids are strings

                    });
                }
                myUserIds.push(parseInt(useridval)); // Explicitly convert useridval to string
                console.log(myUserIds);
                setMyUserIdsFilter(myUserIds);
            });
        }
        if (loggedinUserRole.toLowerCase() === 'admin') {
            const sessCompanyId = sessionStorage.getItem('adminCompany');
            setCompanyPrintLogo(services.fetchServerAPIEndPoint()+"uploads/company-logo/company_logo_file_"+sessCompanyId+".png")
        }


        if (loggedinUserRole === 'Admin' || loggedinUserRole === 'Manager') {
            //var tab = getParameterByName('tab');

            var activeTab = document.querySelector('.nav-link.active'); // Find the active tab element
            var tab = activeTab ? activeTab.innerText : ''; // Get the tab's name (text content)
            //alert(myUserIds)

            if (!isEmpty(tab)) {
                if (tab === 'Unsubmitted') {
                    //getTimesheetEntriesByStatus('', 'Unsubmitted');
                    getTimesheetEntriesByStatus(myUserIdsFilter, 'Unsubmitted')
                    //document.getElementsByClassName("nav-link")[1].click();
                }

                if (tab === 'Pending Approval') {
                    //getTimesheetEntriesByStatus('', 'Submitted');
                    getTimesheetEntriesByStatus(myUserIdsFilter, 'Submitted')
                    //document.getElementsByClassName("nav-link")[0].click();
                }

                if (tab === 'Approved') {
                    //etTimesheetEntriesByStatus('', 'Approved');
                    getTimesheetEntriesByStatus(myUserIdsFilter, 'Approved')
                    //document.getElementsByClassName("nav-link")[2].click();
                }
            } else {
                //pending
                //getTimesheetEntriesByStatus('', 'Submitted');
                getTimesheetEntriesByStatus(myUserIdsFilter, 'Submitted')
                //document.getElementsByClassName("nav-link")[0].click();
            }
        } else {
            window.location.href = '/login';
        }
    }, [clientCurrentPage, timesheetstatusTab]);



    useEffect(() => {
        // Get the "tab" parameter from the URL
        const tab = searchParams.get('tab');

        // Function to reset the active class for all tabs
        const resetActiveClass = () => {
            if (pendingTabRef.current) pendingTabRef.current.classList.remove('active');
            if (unsubmittedTabRef.current) unsubmittedTabRef.current.classList.remove('active');
            if (approvedTabRef.current) approvedTabRef.current.classList.remove('active');
        };

        if (tab) {
            // setTimesheetstatusTab(tab); // Update the state when the tab parameter changes
        }
        //alert(tab)
        if (tab === 'unsubmitted') {
            resetActiveClass();
            //getTimesheetEntriesByStatus('', 'Unsubmitted');
            if (unsubmittedTabRef.current) unsubmittedTabRef.current.classList.add('active');
            setTimesheetstatusTab('Unsubmitted');
            getTimesheetEntriesByStatus(myUserIdsFilter, 'Unsubmitted')
            //document.getElementsByClassName("nav-link")[1].click();
        }

        if (tab === 'pending') {
            //getTimesheetEntriesByStatus('', 'Submitted');
            if (pendingTabRef.current) pendingTabRef.current.classList.add('active');
            setTimesheetstatusTab('Submitted');
            getTimesheetEntriesByStatus(myUserIdsFilter, 'Submitted')
            //document.getElementsByClassName("nav-link")[0].click();
        }

        if (tab === 'approved') {
            setTimesheetstatusTab('Approved');
            //etTimesheetEntriesByStatus('', 'Approved');
            getTimesheetEntriesByStatus(myUserIdsFilter, 'Approved');
            if (approvedTabRef.current) approvedTabRef.current.classList.add('active');
            //document.getElementsByClassName("nav-link")[2].click();
        }
    }, [searchParams]); // Dependency array ensures the effect runs when searchParams change


    useEffect(() => {

        //alert(timesheetstatusTab)
        setClientCurrentPage(1)
    }, [timesheetstatusTab]);
    /*
    useEffect(() => {
        var activeTab = document.querySelector('.nav-link.active'); // Find the active tab element
        var tabName = activeTab ? activeTab.innerText : ''; // Get the tab's name (text content)

       alert(tabName); // Display the active tab name

        if (tabName === 'Unsubmitted') {
            getTimesheetEntriesByStatus(myUserIdsFilter, 'Unsubmitted');
            //document.getElementsByClassName("nav-link")[1].click();
        }

        if (tabName === 'Pending Approval') {
            getTimesheetEntriesByStatus(myUserIdsFilter, 'Submitted');
            //document.getElementsByClassName("nav-link")[0].click();
        }

        if (tabName === 'Approved') {
            getTimesheetEntriesByStatus(myUserIdsFilter, 'Approved');
            //document.getElementsByClassName("nav-link")[2].click();
        }


    }, [clientCurrentPage]);
    */


    function getParameterByName(name) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        var results = regex.exec(window.location.href);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    const isEmpty = (value) => {
        return value === null || String(value).trim() === '';
    }


    function getTimesheetEntriesByStatus(useridList, timeSheetStatus) {
        // setClientCurrentPage(1)
        setTimesheetstatusTab(timeSheetStatus)
        setShowLoader(1);
        $('#listView').show();

        if (isEmpty(listbyStage)) {
            $('#weekList').show();
            $('#userList').hide();
            $('#listByItem').val('week');
        } else {
            if (listbyStage === 'user') {
                $('#weekList').hide();
                $('#userList').show();
                $('#listByItem').val('user');
            }
            if (listbyStage === 'week') {
                $('#weekList').show();
                $('#userList').hide();
                $('#listByItem').val('week');
            }
        }


        $('#detailed_view').hide();

        if (timeSheetStatus === 'Unsubmitted') {
            var timesheetRegstatus = ['Unsubmitted', 'Rejected', 'Approval Withdrawn'];
        } else {
            var timesheetRegstatus = [timeSheetStatus];
        }

        if (timeSheetStatus === 'Unsubmitted' || timeSheetStatus === 'Submitted') {
            var sortOrder = 'ASC';
        }
        else {
            var sortOrder = 'DESC';
        }



        console.log(useridList);
        var reqCompanyVal = '';
        //console.log(sessionStorage.getItem('userRole'));
        if (sessionStorage.getItem('userRole') == 'Manager') {
            //managerid = sessionStorage.getItem('userIdval');
            reqCompanyVal = companyid;
        }

        if (sessionStorage.getItem('userRole') == 'Admin') {
            reqCompanyVal = adminCompanyid;
        }

        const requestObject =
        {
            "userIds": useridList,
            "timeSheetStatuses": timesheetRegstatus,
            "pageNumber": clientCurrentPage,
            "pageSize": pageSize,
            "companyId": reqCompanyVal,
            "sortOrder": sortOrder
        }
        //console.log(requestObject);
        var accessTokenIn = sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': "Bearer "+accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };
        services.getTimeEntriesByStatus(requestObject).then((res) => {
            //setListStatus(timeSheetStatus);
            //setWeekCheckedValues([]);
            mylistStatus = timeSheetStatus;
            setMyListViewStatus(timeSheetStatus);
            setMyListConsolidatedStatus(timeSheetStatus);
            //console.log(mylistStatus);
            if (res.type === "success") {

                console.log(res.data);
                // Step 1: Create a Map to group entries by userId
                const userMap = new Map();

                res.data.forEach(entry => {
                    entry.users.forEach(user => {
                        if (!userMap.has(user.userId)) {
                            userMap.set(user.userId, {
                                userId: user.userId,
                                userFirstName: user.userFirstName,
                                userLastName: user.userLastName,
                                timeEntries: []
                            });
                        }
                        userMap.get(user.userId).timeEntries.push({
                            weekTimeEntryId: entry.weekTimeEntryId,
                            weekStartDate: entry.weekStartDate,
                            weekEndDate: entry.weekEndDate,
                            timeSheetStatuses: entry.timeSheetStatuses,
                            workedHours: user.workedHours
                        });
                    });
                });

                // Step 2: Convert the Map values to an array
                const result = Array.from(userMap.values());

                const weekresult = groupByDate(res.data);

                //const weekssortedData = sortByWeekStartDate(weekresult);
                // console.log(weekresult);
                // console.log(weekssortedData);
                // console.log(result);

                setTimesheetByUserList(result);

                setTimesheetByStatusList(weekresult);
                setClientTotalPages(res.totalNumberOfPages);
            } else {
                setTimesheetByUserList([]);
                setTimesheetByStatusList([]);
            }
            setShowLoader(0);

        });
    }

    const sortByWeekStartDate = (data) => {
        return data.sort((a, b) => {
            // Convert weekStartDate to Date objects for comparison
            const dateA = new Date(a.weekStartDate);
            const dateB = new Date(b.weekStartDate);

            // Compare the dates
            return dateA - dateB;
        });
    };

    const groupByDate = (entries) => {
        const grouped = entries.reduce((acc, entry) => {
            const key = `${entry.weekStartDate}-${entry.weekEndDate}`;
            if (!acc[key]) {
                acc[key] = {
                    weekTimeEntryId: entry.weekTimeEntryId,
                    weekStartDate: entry.weekStartDate,
                    weekEndDate: entry.weekEndDate,
                    timeSheetStatuses: entry.timeSheetStatuses,
                    users: []
                };
            }
            acc[key].users = [
                ...acc[key].users,
                ...entry.users
            ];
            return acc;
        }, {});

        return Object.values(grouped);
    };

    const getDateRange1 = (weekstart, weekend) => {
        var weekStartobj = new DateObject(weekstart);
        var weekEndobj = new DateObject(weekend);
        return weekStartobj.format("DD MMM YYYY") + ' - ' + weekEndobj.format("DD MMM YYYY");
    }

    const getTotalTimePendingForApproval = () => {
        for (var i = 0; i < timesheetByStatusList.length; i++) {
            var userinfodata = timesheetByStatusList[i].users;
            //console.log(userinfodata);
            for (var j = 0; j < userinfodata.length; j++) {
                if (userinfodata[j].userId === useridval) {
                    return userinfodata[j].workedHours;
                }
            }
        }
    }

    const showSubmittedWeekTimesheet = (mondaydate, sundaydate) => {
        sessionStorage.setItem('viewlistfor', mondaydate + '&' + sundaydate);
    }

    const [listbyStage, setListByStage] = useState('week');
    const handleListBy = (event) => {
        var listval = event.target.value;
        setClientCurrentPage(1);
        getTimesheetEntriesByStatus(myUserIdsFilter, timesheetstatusTab);
        if (listval === 'week') {
            setListByStage('week');
            $('#weekList').show();
            $('#userList').hide();

        }
        if (listval === 'user') {
            setListByStage('user');
            $('#weekList').hide();
            $('#userList').show();
        }
    }

    const [detailedViewUserFname, setDetailedViewUserFname] = useState('');
    const [detailedViewUserLname, setDetailedViewUserLname] = useState('');
    const [detailedViewLimitDay1, setDetailedViewLimitDay1] = useState('');
    const [detailedViewLimitDay2, setDetailedViewLimitDay2] = useState('');
    const [detailedViewUserId, setDetailedViewUserId] = useState('');

    function getUserInfo(userid) {
        setShowLoader(1);
        services.getUserById(userid).then((res) => {
            if (res.data.type === 'success') {

                var userResponse = res.data.data;
                console.log(userResponse);
                setTimesheeetViewUserCompanyName(userResponse.company.company_name);

            }
        });
        setShowLoader(0);
    }
    const showDetailedView = (limitday1, limitday2, timesheetuserid, userFname, UserLname) => {
        setShowLoader(1);
        console.log(limitday1 + ' - ' + limitday2);
        setDetailedViewUserFname(userFname);
        setDetailedViewUserLname(UserLname);
        setDetailedViewLimitDay1(limitday1);
        setDetailedViewLimitDay2(limitday2);
        setDetailedViewUserId(timesheetuserid);
        $('#listView').hide();
        $('#detailed_view').show();
        // $('#hideTimesheetDetails').hide();
        // $('#timesheetDetails').hide();
        getUserInfo(timesheetuserid);
        getTimesheetEntriesForWeek('', '', '', '', '', '', timesheetuserid, '', limitday1, limitday2);
        getFullWeekTimesInfo(timesheetuserid, limitday1, limitday2);
        getUserTimesheetEntriesWithTaskId('', '', '', '', '', '', timesheetuserid, '', limitday1, limitday2);
        getTimesheetComments(limitday1, limitday2, timesheetuserid);
        setShowLoader(0);
    }

    const [formattedDetails, setFormattedDetails] = useState([]);
    function getUserTimesheetEntriesWithTaskId(clientId, projectId, taskId, weekTimeEntryId, isBillable, timeSheetStatus, userId, requestdate, weekStartDateval, weekEndDateval) {
        services.getAllTimeSheetEntries(clientId, projectId, taskId, weekTimeEntryId, isBillable, timeSheetStatus, userId, requestdate, weekStartDateval, weekEndDateval).then((res) => {
            if (res.data.type === 'success') {
                console.log(res.data.data);
                setFullEntryResult(res.data.data);
                const transformedData = transformData(res.data.data);

                setFormattedDetails(transformedData);
            }
        });
    }

    const [commentsData, setCommentsData] = useState([]);
    function getTimesheetComments(weekStartDateval, weekEndDateval, usertimesheetval) {
        services.getTimesheetComments(weekStartDateval, weekEndDateval, usertimesheetval).then((res) => {
            console.log(res.data);
            if (res.data.type === 'success') {
                if (res.data.data !== null) {
                    setCommentsData(res.data.data);
                } else {
                    setCommentsData([]);
                }
            }
        });
    }

    // Transform data
    const transformData = (input) => {
        return input
            .filter(entry => entry.duration !== "00:00:00") // Filter out entries with duration 00:00:00
            .reduce((acc, entry) => {
                const { day } = entry;
                let dayEntry = acc.find(d => d.day === day);

                if (!dayEntry) {
                    dayEntry = { day, timeEntries: [] };
                    acc.push(dayEntry);
                }

                dayEntry.timeEntries.push(entry);
                return acc;
            }, []);
    };

    function getFullWeekTimesInfo(useridvalue, mondayDate, sundayDate) {
        const requestObject =
        {
            "week_start_date": mondayDate,
            "week_end_date": sundayDate,
            "user_id": useridvalue
        }
        setDetailedViewDate1(mondayDate);
        caliculateWeekLimit(mondayDate, sundayDate);
        //console.log(requestObject);
        var accessTokenIn = sessionStorage.getItem("accToken");
        // const requestOptions = {
        //    method: 'POST',
        //    headers: {
        //       'Content-Type': 'application/json',
        //       'Authorization': "Bearer "+accessTokenIn
        //    },
        //    body: JSON.stringify(requestObject)
        // };
        services.getFullWeekTimesInfo(requestObject).then((res) => {
            if (res.data) {
                setTotalWeekTime(res.data.total_duration);
                setDateWiseTimes(res.data.days);
            }
        });
    }

    const caliculateWeekLimit = (date1, date2) => {

        var strtdate = new DateObject(date1);
        var dateweek1 = strtdate.format("MM/DD/YYYY");

        var enddate = new DateObject(date2);
        var dateweek2 = enddate.format("MM/DD/YYYY");
        setWeekLimitDates(dateweek1 + ' - ' + dateweek2);

        setWeekLimitDatesFull(strtdate.format("DD MMM YYYY") + ' - ' + enddate.format("DD MMM YYYY"));
    }

    function getTimesheetEntriesForWeek(clientId, projectId, taskId, weekTimeEntryId, isBillable, timeSheetStatus, userId, requestdate, weekStartDate, weekEndDate) {
        var projectstatus = '';
        setShowLoader(1);
        services.getAllTimeSheetEntriesForWeek(clientId, projectId, projectstatus, weekTimeEntryId, userId, requestdate, weekStartDate, weekEndDate).then((res) => {
            if (res.data.type === 'success') {
                $('#loadwrapper').hide();
                console.log(res.data.data);
                setTotalEntryTimeWeek(res.data.data.totalTotalTime);
                setTotalBillableTimeWeek(res.data.data.totalBillableTime);
                setTotalNonBillableTimeWeek(res.data.data.totalUnbillableTime);
                setTotalStatusWeek(res.data.data.consolidatedStatus);
                if (res.data.data.projects === null) {
                    setShowWeekTimeEtriesList([]);
                }
                else {
                    var converteddata = transformObject(res.data);
                    console.log(converteddata);
                    setShowWeekTimeEtriesList(converteddata.data.projects);
                }
            }
            setShowLoader(0);

        });
    }

    function transformObject(obj) {
        obj.data.projects.map(project => {
            const billableTimeEntries = [];
            const unbillableTimeEntries = [];

            project.tasks.forEach(task => {
                if (task.billableEntries.length > 0) {
                    billableTimeEntries.push(
                        {
                            taskId: task.taskId,
                            taskName: task.taskName,
                            sprint: task.sprint,
                            taskBillableTime: task.taskBillableTime,
                            taskUnbillableTime: task.taskUnbillableTime,
                            taskTotalTime: task.taskTotalTime,
                            timeentries: task.billableEntries.map(entry => ({
                                id: entry.id,
                                day: entry.day,
                                duration: entry.duration,
                                is_billable: 1,
                                clientId: entry.clientId,
                                projectId: entry.projectId,
                                taskId: entry.taskId,
                                time_sheet_status: entry.time_sheet_status,
                                entry_created_from_day_or_week: entry.entry_created_from_day_or_week,
                                time_entry_description: entry.time_entry_description,
                                userId: entry.userId,
                                is_holiday: entry.is_holiday,
                                is_leave: entry.is_leave,
                                is_comp_off: entry.is_comp_off,
                                group_week_entry_sequence: entry.group_week_entry_sequence,
                                week_time_entry_id: entry.week_time_entry_id
                            }))
                        }
                    );
                }
                if (task.unbillableEntries.length > 0) {
                    unbillableTimeEntries.push({
                        taskId: task.taskId,
                        taskName: task.taskName,
                        sprint: task.sprint,
                        taskBillableTime: task.taskBillableTime,
                        taskUnbillableTime: task.taskUnbillableTime,
                        taskTotalTime: task.taskTotalTime,
                        timeentries: task.unbillableEntries.map(entry => ({
                            id: entry.id,
                            day: entry.day,
                            duration: entry.duration,
                            is_billable: 0,
                            clientId: entry.clientId,
                            projectId: entry.projectId,
                            taskId: entry.taskId,
                            time_sheet_status: entry.time_sheet_status,
                            entry_created_from_day_or_week: entry.entry_created_from_day_or_week,
                            time_entry_description: entry.time_entry_description,
                            userId: entry.userId,
                            is_holiday: entry.is_holiday,
                            is_leave: entry.is_leave,
                            is_comp_off: entry.is_comp_off,
                            group_week_entry_sequence: entry.group_week_entry_sequence,
                            week_time_entry_id: entry.week_time_entry_id
                        }))
                    });
                }
            });
            project.billableTimeEntries = billableTimeEntries;
            project.unbillableTimeEntries = unbillableTimeEntries;
        });
        return obj;
    }

    const backToPendingForApprovalList = () => {
        getTimesheetEntriesByStatus(myUserIdsFilter, 'Submitted');
    }

    const backToUnsubmittedList = () => {
        getTimesheetEntriesByStatus(myUserIdsFilter, 'Unsubmitted');
    }

    const backToApprovedList = () => {
        getTimesheetEntriesByStatus(myUserIdsFilter, 'Approved');
    }

    const handleWeekDatesDisplayFormat = (datetoformat) => {
        var displaydate = new DateObject(datetoformat);
        return displaydate.format("DD MMM");
    };

    const handleCommentDatesDisplayFormat = (datetoformat) => {
        var displaydate = new DateObject(datetoformat);
        return displaydate.format("DD MMM, YYYY @ hh:mm A");
    };

    const handleDetailedInfoDatesDisplayFormat = (datetoformat) => {
        var displaydate = new DateObject(datetoformat);
        return displaydate.format("DD MMM YYYY");
    };

    const caliculateNextDay = (mydate, adddays) => {
        // Validate and parse the date string
        const dateParts = mydate.split('-');
        if (dateParts.length === 3) {
            const [year, month, day] = dateParts.map(Number);

            // Create a Date object in UTC
            const date = new Date(Date.UTC(year, month - 1, day));

            // Check if the Date object is valid
            if (!isNaN(date.getTime())) {
                // Add one day to the date
                date.setUTCDate(date.getUTCDate() + adddays);

                // Format the new date to YYYY-MM-DD
                const nextDay = date.toISOString().split('T')[0];
                return nextDay; // Outputs "2024-08-04"
            } else {
                //console.error("Invalid Date object created");
            }
        } else {
            //console.error("Invalid date string format");
        }

    }

    const getProjectTotalTime1 = (weekday, addrow, projectid) => {
        //console.log(weekday);
        // Create a Date object from the string, interpreted as UTC
        var date = new Date(weekday + 'T00:00:00Z'); // Appending 'T00:00:00Z' ensures it's treated as UTC

        // Add one day
        date.setUTCDate(date.getUTCDate() + addrow);

        // Function to format date as YYYY-MM-DD
        function formatDate(date) {
            var year = date.getUTCFullYear();
            var month = ('0' + (date.getUTCMonth() + 1)).slice(-2); // Months are zero-based
            var day = ('0' + date.getUTCDate()).slice(-2);
            return `${year}-${month}-${day}`;
        }

        // Get the new date and format it
        var newDateStr = formatDate(date);
        //console.log(newDateStr);
        return getProjectTotalTime(newDateStr, projectid);
    }

    const getProjectTotalTime = (weekday, projectid) => {
        var mylist = showWeekTimeEtriesList;
        for (var i = 0; i < mylist.length; i++) {
            if (mylist[i].projectId === projectid) {
                var durationval = '...';
                // console.log(mylist[i].dayWiseTotalTime);
                var myentriestime = mylist[i].dayWiseTotalTime;
                for (var j = 0; j < myentriestime.length; j++) {

                    // console.log(myentriestime[j].day+' == '+weekday);
                    if (myentriestime[j].day === weekday) {
                        //console.log(myentriestime[j].total_duration);
                        if (!isEmpty(myentriestime[j].total_duration)) {
                            if (parseInt(myentriestime[j].total_duration) > 0) {
                                durationval = (myentriestime[j].total_duration).slice(0, -3);
                            } else {
                                //return '...';
                            }
                        } else {
                            //return '...';
                        }
                    } else {
                        //return '...';
                    }

                }
                return durationval;
            }
        }
    }

    const handleToDisplayWeekDescription = (id, day1, day2, projectname, clientname, taskname, billable) => {
        setSelectedProject(projectname);
        setSelectedClient(clientname);
        setSelectedTask(taskname);
        setSelectedBillable(billable);
        getMyTimesheetEntriesWithTaskId('', '', id, '', billable, '', detailedViewUserId, '', day1, day2);
        // getMyTimesheetEntriesWithTaskId('', '', id, '', billable, '', useridval, '', day1, day2);
    }

    function getMyTimesheetEntriesWithTaskId(clientId, projectId, taskId, weekTimeEntryId, isBillable, timeSheetStatus, userId, requestdate, weekStartDateval, weekEndDateval) {
        services.getAllTimeSheetEntries(clientId, projectId, taskId, weekTimeEntryId, isBillable, timeSheetStatus, userId, requestdate, weekStartDateval, weekEndDateval).then((res) => {
            if (res.data.type === 'success') {
                setSelectedEntryResult(res.data.data);
            }
        });
    }

    const getTimeEntryDateDuration = (entrybillable, weekday, projectid, taskid) => {
        for (var i = 0; i < showWeekTimeEtriesList.length; i++) {
            if (showWeekTimeEtriesList[i].projectId === projectid) {
                if (entrybillable === '1') {
                    var timeentriesinfo = showWeekTimeEtriesList[i].billableTimeEntries;
                } else {
                    var timeentriesinfo = showWeekTimeEtriesList[i].unbillableTimeEntries;
                }
                if (timeentriesinfo) {
                    var durationentryval = '';
                    for (var l = 0; l < timeentriesinfo.length; l++) {
                        if (timeentriesinfo[l].taskId === taskid) {
                            var mytimeentries = timeentriesinfo[l].timeentries;
                            for (var t = 0; t < mytimeentries.length; t++) {
                                if (new Date(mytimeentries[t].day).setHours(0, 0, 0, 0) === new Date(weekday).setHours(0, 0, 0, 0)) {
                                    //console.log(mytimeentries[t].duration);
                                    if (!isEmpty(mytimeentries[t].duration)) {
                                        if (parseInt(mytimeentries[t].duration) > 0) {
                                            durationentryval = (mytimeentries[t].duration).slice(0, -3);
                                        } else {
                                            //return '...';
                                        }
                                    } else {
                                        //return '...';
                                    }
                                } else {
                                    //return '...';
                                }
                            }
                        }
                    }
                    return durationentryval;
                }
            }
        }
    }

    const hideDetails = () => {
        setViewTimesheetDetailsStat(0);
        // $('#timesheetDetails').hide();
        // $('#hideTimesheetDetails').hide();
        // $('#viewTimesheetDetails').show();
    }

    const showDetails = () => {
        setViewTimesheetDetailsStat(1);
        // $('#viewTimesheetDetails').hide();
        // $('#timesheetDetails').show();
        // $('#hideTimesheetDetails').show();

    }

    const isChecked = (weekStartDate, weekEndDate, userId, weekTimeEntryId) => {
        const key = `${weekStartDate}_${weekEndDate}_${userId}_${weekTimeEntryId}`;
        return selectedWeeks[key] || false;
    };
    const [selectedWeeks, setSelectedWeeks] = useState({});
    const [weekCheckedValues, setWeekCheckedValues] = useState([]);
    const handleWeekListCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setWeekCheckedValues(prevCheckedValues =>
            checked
                ? [...prevCheckedValues, value]
                : prevCheckedValues.filter(val => val !== value)
        );

        setSelectedWeeks((prevSelected) => ({
            ...prevSelected,
            [value]: checked,
        }));
    }

    const [showWeekListValidation, setWeekListValidation] = useState(0);
    const approveWeekList = () => {
        console.log('Checked values:', weekCheckedValues);
        if (weekCheckedValues.length > 0) {
            //$('.loadwrapper').show();
            setWeekListValidation(0);
            $('#weekSelectionValidation').hide();
            formatRequestandAction(weekCheckedValues);
        }
        else {
            setWeekListValidation(1);
            $('#weekSelectionValidation').show();
        }
    }

    const remindeWeekList = () => {
        console.log('Checked values:', weekCheckedValues);
        if (weekCheckedValues.length > 0) {

            setWeekListValidation(0);
            $('#weekSelectionValidation').hide();
            sendEmailReminder(weekCheckedValues);
        }
        else {
            setWeekListValidation(1);
            $('#weekSelectionValidation').show();
        }
    }

    const remindeUserList = () => {
        console.log('Checked values:', userCheckedValues);
        if (userCheckedValues.length > 0) {
            //$('.loadwrapper').show();
            setUserListValidation(0);
            $('#userSelectionValidation').hide();
            sendEmailReminder(userCheckedValues);
        }
        else {
            setUserListValidation(1);
            $('#userSelectionValidation').show();
        }
    }

    const sendEmailReminder = (checkedEntryList) => {
        setShowLoader(1);
        const userIds = new Set();
        const weekTimeEntryIds = new Set();

        // Iterate over each entry
        checkedEntryList.forEach(entry => {
            const [, , userId, weekTimeEntryId] = entry.split('_');

            // Add to the sets to ensure uniqueness
            userIds.add(userId);
            weekTimeEntryIds.add(weekTimeEntryId);
        });

        // Convert sets to arrays
        const emailremiderdata = [
            Array.from(userIds),
            Array.from(weekTimeEntryIds)
        ];



        var myemailReminderRequest = {
            "userIds": emailremiderdata[0],
            "weekTimeEntryIds": emailremiderdata[1]
        }
        //console.log(myemailReminderRequest); return false;
        var accessTokenIn = sessionStorage.getItem("accToken");
        // const myrequestOptions = {
        //     method: 'POST',
        //     headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer " + accessTokenIn
        //     },
        //     body: JSON.stringify(myemailReminderRequest)
        // };
        uncheckAllTimesheetCheckboxes();
        services.sendEmailReminder(myemailReminderRequest).then((res) => {
            //$('.loadwrapper').hide();
            if (res.type === 'success') {
                $('.loadwrapper').hide();
                $("#emailRemindersuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
                    $("#emailRemindersuccessMessage").slideUp(500);
                });
            }
            setShowLoader(0);
        });
    }

    const uncheckAllTimesheetCheckboxes = () => {
        const checkboxes = document.getElementsByName('weekApproval');
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false; // Uncheck each checkbox
        });

        const usercheckboxes = document.getElementsByName('userApproval');
        usercheckboxes.forEach((ucheckbox) => {
            ucheckbox.checked = false; // Uncheck each checkbox
        });
        setUserCheckedValues([]); // Clear the state values as well
        setSelectedUserCheck({}); // Clear the state values as well
        setSelectedWeeks({});
        setWeekCheckedValues([]);
    };

    const sendEmailReminderforTimeSheet = (dayval, dayval2, timesheetuser1) => {
        console.log(showWeekTimeEtriesList);

        const weekTimeEntryIds = new Set();
        setShowLoader(1);
        showWeekTimeEtriesList.forEach(project => {
            project.tasks.forEach(task => {
                task.billableEntries.forEach(entry => {
                    if (entry.week_time_entry_id) {
                        weekTimeEntryIds.add(entry.week_time_entry_id);
                    }
                });

                task.unbillableEntries.forEach(entry => {
                    if (entry.week_time_entry_id) {
                        weekTimeEntryIds.add(entry.week_time_entry_id);
                    }
                });
            });
        });

        var myemailReminderRequest = {
            "userIds": [detailedViewUserId],
            "weekTimeEntryIds": Array.from(weekTimeEntryIds)
        }
        //console.log(myemailReminderRequest); return false;
        var accessTokenIn = sessionStorage.getItem("accToken");
        //  const myrequestOptions = {
        //      method: 'POST',
        //      headers: {
        //      'Content-Type': 'application/json',
        //      'Authorization': "Bearer " + accessTokenIn
        //      },
        //      body: JSON.stringify(myemailReminderRequest)
        //  };
        services.sendEmailReminder(myemailReminderRequest).then((res) => {
            if (res.type === 'success') {
                //$('.loadwrapper').hide();
                $("#emailRemindersuccessMessageSingle").fadeTo(2000, 500).slideUp(500, function () {
                    $("#emailRemindersuccessMessageSingle").slideUp(500);
                });
                setTimeout(function () {
                    $('#closemodal').click();
                    window.location.href = '/timesheet-management?tab=unsubmitted';
                }, 1000);
            }
            setShowLoader(0);
        });

        // console.log(Array.from(weekTimeEntryIds));

    }

    const isCheckedUser = (weekStartDate, weekEndDate, userId, weekTimeEntryId) => {
        const key = `${weekStartDate}_${weekEndDate}_${userId}_${weekTimeEntryId}`;
        return selectedUserCheck[key] || false;
    };

    const [userCheckedValues, setUserCheckedValues] = useState([]);
    const [selectedUserCheck, setSelectedUserCheck] = useState({});
    const handleUserListCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setUserCheckedValues(prevUserCheckedValues =>
            checked
                ? [...prevUserCheckedValues, value]
                : prevUserCheckedValues.filter(val => val !== value)
        );

        setSelectedUserCheck((prevSelected) => ({
            ...prevSelected,
            [value]: checked,
        }));
    }

    const [showUserListValidation, setUserListValidation] = useState(0);
    const approveUserList = () => {
        console.log('Checked values:', userCheckedValues);
        if (userCheckedValues.length > 0) {
            setUserListValidation(0);
            $('#userSelectionValidation').hide();
            formatRequestandAction(userCheckedValues);
        }
        else {
            setUserListValidation(1);
            $('#userSelectionValidation').show();
        }

    }



    const approveTimeSheet = (dayval1, dayval2, timesheetuser) => {
        setShowLoader(1);
        var myupdateentry = {
            "userIds": [timesheetuser],
            "weekStartDate": dayval1,
            "weekEndDate": dayval2,
            "timeSheetStatus": "Approved",
            "reasonForRejection": "",
            "approvedBy": useridval,
            "rejectedBy": ""
        }

        var accessTokenIn = sessionStorage.getItem("accToken");
        // const myrequestOptions = {
        //     method: 'PUT',
        //     headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer " + accessTokenIn
        //     },
        //     body: JSON.stringify(myupdateentry)
        // };
        services.updateTimeSheetSatus(myupdateentry).then((res) => {
            if (res.type === 'success') {
                //$('.loadwrapper').hide();
                $("#approvalsuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
                    $("#approvalsuccessMessage").slideUp(500);
                });
                setTimeout(function () { //$('#closemodal').click();
                    window.location.href = '/timesheet-management';
                }, 1000);
            }
            setShowLoader(0);
        });
    }

    const formatRequestandAction = (inputValues) => {
        setShowLoader(1);
        const result = [];

        inputValues.forEach(item => {
            const [day1, day2, userid, weektimeentryid] = item.split('_');

            // Find or create the group for the current day1 and day2
            let group = result.find(g => g.day1 === day1 && g.day2 === day2);

            if (!group) {
                // If the group doesn't exist, create it
                group = {
                    day1,
                    day2,
                    userid: []
                };
                result.push(group);
            }

            // Add the userid to the group's userid array if not already present
            if (!group.userid.includes(Number(userid))) {
                group.userid.push(Number(userid));
            }
        });

        // Sort userid arrays within each group
        result.forEach(group => {
            group.userid.sort((a, b) => a - b);
        });

        for (var i = 0; i < result.length; i++) {
            var myupdateentry = {
                "userIds": result[i].userid,
                "weekStartDate": result[i].day1,
                "weekEndDate": result[i].day2,
                "timeSheetStatus": "Approved",
                "reasonForRejection": "",
                "approvedBy": useridval,
                "rejectedBy": ""
            }

            var accessTokenIn = sessionStorage.getItem("accToken");
            // const myrequestOptions = {
            //     method: 'PUT',
            //     headers: {
            //     'Content-Type': 'application/json',
            //     'Authorization': "Bearer " + accessTokenIn
            //     },
            //     body: JSON.stringify(myupdateentry)
            // };

            uncheckAllTimesheetCheckboxes();
            services.updateTimeSheetSatus(myupdateentry).then((res) => {
                if (res.type === 'success') {
                    setTimeout(function () {
                        $('#closemodal').click();
                        window.location.href = '/timesheet-management';
                    }, 1000);
                }
            });
        }
        //$('.loadwrapper').hide();
        setShowLoader(0);
        $("#approvedsuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
            $("#approvedsuccessMessage").slideUp(500);
        });

    }

    const [rejectionTimesheetDay1, setRejectionTimesheetDay1] = useState(0);
    const [rejectionTimesheetDay2, setRejectionTimesheetDay2] = useState(0);
    const [rejectionTimesheetUserId, setRejectionTimesheetUserId] = useState(0);
    const [rejectionReasonValidation, setRejectionReasonValidation] = useState(0);
    const rejectTimeSheet = (dayval1, dayval2, timesheetuser) => {
        setRejectionTimesheetDay1(dayval1);
        setRejectionTimesheetDay2(dayval2);
        setRejectionTimesheetUserId(timesheetuser);
    }

    const [withdrawlTimesheetDay1, setWithdrawlTimesheetDay1] = useState(0);
    const [withdrawlTimesheetDay2, setWithdrawlTimesheetDay2] = useState(0);
    const [withdrawlTimesheetUserId, setWithdrawlTimesheetUserId] = useState(0);
    const [withdrawlReasonValidation, setWithdrawlReasonValidation] = useState(0);

    const withdrawTimeSheet = (dayval1, dayval2, timesheetuser) => {
        setWithdrawlTimesheetDay1(dayval1);
        setWithdrawlTimesheetDay2(dayval2);
        setWithdrawlTimesheetUserId(timesheetuser);
    }

    const submitValue = () => {
        var validation = 0;
        if (isEmpty($('#rejectionReason').val().trim())) {
            validation = 1;
            setRejectionReasonValidation(1);
        } else {
            setRejectionReasonValidation(0);
        }

        if (validation === 1) {
            $('#validationMessage').show();
            return false;
        } else {
            setShowRejectionLoader(1);
            //setShowLoader(1);
            var myupdateentry = {
                "userIds": [rejectionTimesheetUserId],
                "weekStartDate": rejectionTimesheetDay1,
                "weekEndDate": rejectionTimesheetDay2,
                "timeSheetStatus": "Rejected",
                "reasonForRejection": $('#rejectionReason').val().trim(),
                "approvedBy": "",
                "rejectedBy": useridval
            }

            var accessTokenIn = sessionStorage.getItem("accToken");
            // const myrequestOptions = {
            //     method: 'PUT',
            //     headers: {
            //     'Content-Type': 'application/json',
            //     'Authorization': "Bearer " + accessTokenIn
            //     },
            //     body: JSON.stringify(myupdateentry)
            // };
            services.updateTimeSheetSatus(myupdateentry).then((res) => {
                if (res.type === 'success') {
                    var myupdateentry = {
                        "weekStartDate": rejectionTimesheetDay1,
                        "weekEndDate": rejectionTimesheetDay2,
                        "commentedForTimeSheetUser": rejectionTimesheetUserId,
                        "commentedByUserId": useridval,
                        "comments": $('#rejectionReason').val().trim()
                    }

                    var accessTokenIn = sessionStorage.getItem("accToken");
                    // const myrequestOptions = {
                    //     method: 'POST',
                    //     headers: {
                    //     'Content-Type': 'application/json',
                    //     'Authorization': "Bearer " + accessTokenIn
                    //     },
                    //     body: JSON.stringify(myupdateentry)
                    // };
                    services.createTimeSheetComment(myupdateentry).then((res) => {
                        if (res.type === 'success') {
                            $('.loadwrapper').hide();
                            setShowRejectionLoader(0);
                            $("#rejectionsuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
                                $("#rejectionsuccessMessage").slideUp(500);
                            });
                            setTimeout(function () {
                                $('#closemodal').click();
                                window.location.href = '/timesheet-management';
                            }, 1000);
                        }

                    });



                }
                //setShowLoader(0);
            });
        }
    }

    const submitWithdrawValue = () => {
        var validation = 0;
        if (isEmpty($('#withdrawalComment').val().trim())) {
            validation = 1;
            setWithdrawlReasonValidation(1);
        } else {
            setWithdrawlReasonValidation(0);
        }

        if (validation === 1) {
            $('#withdrawalvalidationMessage').show();
            return false;
        } else {
            //$('.loadwrapper').show();
            setShowWithdrawLoader(1);
            var myupdateentry = {
                "userIds": [withdrawlTimesheetUserId],
                "weekStartDate": withdrawlTimesheetDay1,
                "weekEndDate": withdrawlTimesheetDay2,
                "timeSheetStatus": "Approval Withdrawn",
                "reasonForRejection": $('#withdrawalComment').val().trim(),
                "approvedBy": "",
                "rejectedBy": useridval
            }

            var accessTokenIn = sessionStorage.getItem("accToken");
            // const myrequestOptions = {
            //     method: 'PUT',
            //     headers: {
            //     'Content-Type': 'application/json',
            //     'Authorization': "Bearer " + accessTokenIn
            //     },
            //     body: JSON.stringify(myupdateentry)
            // };
            services.updateTimeSheetSatus(myupdateentry).then((res) => {
                if (res.type === 'success') {
                    var myupdateentry = {
                        "weekStartDate": withdrawlTimesheetDay1,
                        "weekEndDate": withdrawlTimesheetDay2,
                        "commentedForTimeSheetUser": withdrawlTimesheetUserId,
                        "commentedByUserId": useridval,
                        "comments": $('#withdrawalComment').val().trim()
                    }

                    var accessTokenIn = sessionStorage.getItem("accToken");
                    // const myrequestOptions = {
                    //     method: 'POST',
                    //     headers: {
                    //     'Content-Type': 'application/json',
                    //     'Authorization': "Bearer " + accessTokenIn
                    //     },
                    //     body: JSON.stringify(myupdateentry)
                    // };
                    services.createTimeSheetComment(myupdateentry).then((res) => {
                        if (res.type === 'success') {
                            $('.loadwrapper').hide();
                            setShowWithdrawLoader(1);
                            $("#withdrawalsuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
                                $("#withdrawalsuccessMessage").slideUp(500);
                            });
                            setTimeout(function () {
                                $('#closemodal').click();
                                window.location.href = '/timesheet-management?tab=approved';
                            }, 1000);
                        }
                    });
                }
                //setShowLoader(0);
            });
        }
    }

    const getWorkedHours = (workedHours) => {
        return workedHours;
        //     const [hours, minutes] = workedHours.split('.').map(Number);

        //     // Pad single-digit hours and minutes with leading zero
        //     const paddedHours = hours.toString().padStart(2, '0');
        //    // const paddedMinutes = minutes.toString().padStart(2, '0');
        //     const paddedMinutes = (minutes !== undefined && minutes !== null) ? minutes.toString().padStart(2, '0') : '00';
        //     return `${paddedHours}:${paddedMinutes}`;

    }

    const [textvalue, setTextValue] = useState('');
    const maxLength = 500; // Set your character limit here

    const handleChangeTextarea = (event) => {
        const newValue = event.target.value;
        if (newValue.length <= maxLength) {
            setTextValue(newValue);
        }
    };

    const [rejectionvalue, setRejectionvalue] = useState('');

    const handleChangeRejectionTextarea = (event) => {
        const newValue = event.target.value;
        if (newValue.length <= maxLength) {
            setRejectionvalue(newValue);
        }
    };

    useEffect(() => {
        // Attach the event listener to the modal
        const modalElement = document.getElementById('withdraw');
        const modalElement1 = document.getElementById('reject');
        const handleModalHidden = () => resetForm();

        modalElement.addEventListener('hidden.bs.modal', handleModalHidden);
        modalElement1.addEventListener('hidden.bs.modal', handleModalHidden);

        return () => {
            // Cleanup the event listener when the component unmounts
            modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
            modalElement1.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, []);

    useEffect(() => {
        if (!isOpen) {
            resetForm(); // Reset the form when the modal is closed
        }
    }, [isOpen]);

    const resetForm = () => {
        setWithdrawlReasonValidation(0);
        setRejectionReasonValidation(0);

        $('#withdrawalvalidationMessage').hide();
        $('#validationMessage').hide();

    };


    return (
        <>
            <main>
                <section>
                    <div class="container">
                        <h2 class="mainHeadline">Team Time Sheets</h2>
                        <div class="tab-primary">
                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item">
                                    <a ref={pendingTabRef} class={`nav-link ${timesheetstatusTab === 'Submitted' ? 'active' : ''} bg-none submittedtab`} data-bs-toggle="tab" href="#listView" onClick={() => getTimesheetEntriesByStatus(myUserIdsFilter, 'Submitted')}>Pending Approval</a>
                                </li>
                                <li class="nav-item">
                                    <a ref={unsubmittedTabRef} class={`nav-link bg-none ${timesheetstatusTab === 'Unsubmitted' ? 'active' : ''} unsubmitted`} data-bs-toggle="tab" href="#listView" onClick={() => getTimesheetEntriesByStatus(myUserIdsFilter, 'Unsubmitted')}>Unsubmitted</a>
                                </li>
                                <li class="nav-item">
                                    <a ref={approvedTabRef} class={`nav-link bg-none ${timesheetstatusTab === 'Approved' ? 'active' : ''} approvedtab`} data-bs-toggle="tab" href="#listView" onClick={() => getTimesheetEntriesByStatus(myUserIdsFilter, 'Approved')}>Approved </a>
                                </li>
                            </ul>

                            {/* Tab panes */}
                            <div class="tab-content ">
                                <div id="listView" class="container tab-pane fade px-0 ${timesheetstatusTab === 'Submitted' ? 'active' : ''} show" role="tabpanel">
                                    <div class="d-flex justify-content-between align-items-center p-2">
                                        <div class="d-flex justify-content-end align-items-center ms-auto" style={{ "width": "320px" }}>
                                            <span style={{ "min-width": "69px" }}>List by:</span>
                                            <select class="form-select" id="listByItem" onChange={handleListBy} style={{ "max-width": "200px" }} value={listbyStage}>
                                                <option value="week">Week</option>
                                                <option value="user">User</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div id="weekList">
                                        {timesheetByStatusList.length > 0 ?
                                            <>
                                                {timesheetByStatusList.map((data, index) => (
                                                    <div class="pendingweeklistwrap" >
                                                        <div class="weekheader">
                                                            <span>{getDateRange1(data.weekStartDate, data.weekEndDate)}</span>
                                                        </div>
                                                        {data.users.map((userdata, userindex) => (
                                                            <div class="weekitemlistitem d-flex justify-content-between align-items-center">
                                                                <div class="d-flex justify-content-start gap-2 align-items-center">
                                                                    {data.timeSheetStatuses === 'Approved' ? '' :
                                                                        <input type="checkbox"
                                                                        checked={isChecked(data.weekStartDate, data.weekEndDate, userdata.userId, data.weekTimeEntryId)}
                                                                      
                                                                        class="form-check-input mt-0" name="weekApproval" value={data.weekStartDate + '_' + data.weekEndDate + '_' + userdata.userId + '_' + data.weekTimeEntryId} onChange={handleWeekListCheckboxChange} />
                                                                    }

                                                                    <div class="d-flex candinfo align-items-center">
                                                                        <span class="shortname">{(userdata.userFirstName).slice(0, 1) + '' + (userdata.userLastName).slice(0, 1)}</span>
                                                                        <a href="#" onClick={() => showDetailedView(data.weekStartDate, data.weekEndDate, userdata.userId, userdata.userFirstName, userdata.userLastName)}>{userdata.userFirstName + ' ' + userdata.userLastName}</a>

                                                                        {data.timeSheetStatuses === 'Unsubmitted' ? ''
                                                                            : data.timeSheetStatuses === 'Rejected' ?
                                                                                <a href="#" class="btn btn-danger scale85"> <img src="images/reject.svg" alt="" /> {data.timeSheetStatuses}</a>
                                                                                : data.timeSheetStatuses === 'Approval Withdrawn' ?
                                                                                    <a href="#" class="btn btn-danger scale85"> <img src="images/reject.svg" alt="" /> {data.timeSheetStatuses == 'Approval Withdrawn' ? 'Withdrawn' : ''}</a>
                                                                                    : ''
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div class="d-flex justify-content-end align-items-center">
                                                                    <div class="hours scale65">
                                                                        <span class="hrtime">{getWorkedHours(userdata.workedHours)} <div class="hrlabel">Hrs</div></span>
                                                                    </div>
                                                                    <button onClick={() => showDetailedView(data.weekStartDate, data.weekEndDate, userdata.userId, userdata.userFirstName, userdata.userLastName)} class="btn btn-outline-primary scale85 ">View Details</button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}

                                                {renderClientPagination()}

                                                {showWeekListValidation === 1 ? <div id="weekSelectionValidation" class="alert alert-danger" role="alert"> Please select Time Sheets</div> : ''}

                                                {myListViewStatus === 'Submitted' ?
                                                    <div class="btn-group my-4 ">
                                                        <button class="btn btn-primary" onClick={approveWeekList}>Approve Time Sheet</button>
                                                        {/*  me-2" data-bs-toggle="modal" data-bs-target="#exampleModal33 */}
                                                    </div>
                                                    : myListViewStatus === 'Unsubmitted' ?
                                                        <div class="btn-group my-4 ">
                                                            <button class="btn btn-primary" onClick={remindeWeekList}>Send Email Reminder</button>
                                                        </div>
                                                        : ''}
                                            </>

                                            :
                                            <div className="d-grid text-center py-3">
                                                <img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
                                                <span> No entries available.</span>
                                            </div>
                                        }
                                    </div>

                                    <div id="userList">
                                        {timesheetByUserList.length > 0 ?
                                            <>
                                                {timesheetByUserList.map((data, index) => (
                                                    <div class="pendingweeklistwrap" >
                                                        <div class="weekheader d-flex candinfo">
                                                            <span class="shortname">{(data.userFirstName).slice(0, 1) + '' + (data.userLastName).slice(0, 1)}</span>
                                                            <span>{data.userFirstName + ' ' + data.userLastName}</span>
                                                        </div>
                                                        {data.timeEntries.map((timeEntriesdata, timeEntriesindex) => (
                                                            <div class="weekitemlistitem d-flex justify-content-between align-items-center">
                                                                <div class="d-flex justify-content-start gap-2 align-items-center">

                                                                    {timeEntriesdata.timeSheetStatuses === 'Approved' ? '' :
                                                                        <input type="checkbox" class="form-check-input mt-0" 
                                                                        name="userApproval" 
                                                                        checked={isCheckedUser(timeEntriesdata.weekStartDate, timeEntriesdata.weekEndDate, data.userId, timeEntriesdata.weekTimeEntryId)}
                                                                        value={timeEntriesdata.weekStartDate + '_' + timeEntriesdata.weekEndDate + '_' + data.userId + '_' + timeEntriesdata.weekTimeEntryId} 
                                                                        onChange={handleUserListCheckboxChange} />
                                                                    }

                                                                    <div class="d-flex candinfo align-items-center">

                                                                        <a href="#" onClick={() => showDetailedView(timeEntriesdata.weekStartDate, timeEntriesdata.weekEndDate, data.userId, data.userFirstName, data.userLastName)}>{getDateRange1(timeEntriesdata.weekStartDate, timeEntriesdata.weekEndDate)}</a>

                                                                        {timeEntriesdata.timeSheetStatuses === 'Unsubmitted' ? ''
                                                                            : timeEntriesdata.timeSheetStatuses === 'Rejected' ?
                                                                                <a href="#" class="btn btn-danger scale85"> <img src="images/reject.svg" alt="" /> {timeEntriesdata.timeSheetStatuses}</a>
                                                                                : timeEntriesdata.timeSheetStatuses === 'Approval Withdrawn' ?
                                                                                    <a href="#" class="btn btn-danger scale85"> <img src="images/reject.svg" alt="" /> {timeEntriesdata.timeSheetStatuses === 'Approval Withdrawn' ? 'Withdrawn' : ''}</a>
                                                                                    : ''
                                                                        }

                                                                    </div>
                                                                </div>
                                                                <div class="d-flex justify-content-end align-items-center">
                                                                    <div class="hours scale65">
                                                                        <span class="hrtime">{timeEntriesdata.workedHours} <div class="hrlabel">Hrs</div></span>
                                                                    </div>
                                                                    <button onClick={() => showDetailedView(timeEntriesdata.weekStartDate, timeEntriesdata.weekEndDate, data.userId, data.userFirstName, data.userLastName)} class="btn btn-outline-primary scale85 ">View Details</button>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}

                                                {renderClientPagination()}


                                                {showUserListValidation === 1 ? <div id="userSelectionValidation" class="alert alert-danger" role="alert"> Please select Time Sheets</div> : ''}

                                                {myListViewStatus === 'Submitted' ?
                                                    <div class="btn-group my-4 ">
                                                        <button class="btn btn-primary" onClick={approveUserList}>Approve Time Sheet</button>
                                                    </div>
                                                    : myListViewStatus === 'Unsubmitted' ?
                                                        <div class="btn-group my-4 ">
                                                            <button class="btn btn-primary" onClick={remindeUserList}>Send Email Reminder</button>
                                                        </div>
                                                        : ''}
                                            </>

                                            :

                                            <div className="d-grid text-center py-3">
                                                <img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
                                                <span> No entries available.</span>
                                            </div>
                                        }
                                    </div>
                                    <div id="approvedsuccessMessage" class="alert alert-success" role="alert">Time Sheet(s) Approved successfully!</div>

                                    <div id="emailRemindersuccessMessage" class="alert alert-success" role="alert">Email reminder sent successfully!</div>

                                </div>



                                <div id="detailed_view" class="container tab-pane fade px-0 active show" role="tabpanel" >
                                    <div class="tab-content-bg-primary" >
                                        {myListViewStatus === 'Submitted' ?
                                            <>
                                                <div class="daytitlewrap mb-3">
                                                    <span class="d-flex align-items-center">
                                                        <div class="d-grid">

                                                            <a href="#" className="no-print" onClick={backToPendingForApprovalList}><img src="images/returnArrow.png" style={{ "margin-right": "4px", "transform": "rotate(180deg)" }} alt="" />
                                                                Back to List
                                                                {/*Back to Pending for Approval List page*/}
                                                            </a>
                                                            <div className="d-flex">

                                                                <h2 className="d-flex"> <div className="userhd">{detailedViewUserFname} {detailedViewUserLname}</div>
                                                                <span className="px-2">|</span>
                                                                 week: {weekLimitDates} </h2>
                                                                {/*<span className="px-2">|</span>
                                                                <div className="d-flex gap-2">
                                                                    <small className="companylabel">{timesheeetViewUserCompanyName}</small> </div> */}

                                                            </div>

                                                        </div>
                                                    </span>
                                                    <small class="badge rounded-pill bg-success-orange mx-0 font-500 px-2 py-1 mt-2">Pending Approval</small>
                                                </div>
                                                <div class="billnonbill d-flex gap-4 align-items-center">
                                                    <div class="item d-grid">
                                                        <small> Total Hours</small>
                                                        <strong class="mainbluetxt ">{totalEntryTimeWeek.slice(0, -3)}</strong>
                                                    </div>
                                                    <span>|</span>
                                                    <div class="item d-grid">
                                                        <small class="d-flex gap-1 align-items-center">
                                                            {/* <img src="images/dollargreen.svg" width="12" alt="" />  */}
                                                            <img src="images/billed.png" width="20" alt="" /> Billable</small>
                                                        <strong class="mainbluetxt ">{totalBillableTimeWeek.slice(0, -3)}</strong>
                                                    </div>
                                                    <span>|</span>
                                                    <div class="item d-grid">
                                                        <small class="d-flex gap-1 align-items-center"> <img src="images/dollarred.svg"
                                                            width="20" alt="" />
                                                            Non-billable</small>
                                                        <strong class="mainbluetxt ">{totalNonBillableTimeWeek.slice(0, -3)}</strong>
                                                    </div>
                                                </div></>
                                            : myListViewStatus === 'Unsubmitted' ?
                                                <>
                                                    <a href="#" onClick={backToUnsubmittedList} class="bluetext no-print"><img src="images/returnArrow.png" style={{ "margin-right": "4px", "transform": "rotate(180deg)" }} alt="" />Back to List
                                                        {/* Back to the Unsubmitted List page */} </a>
                                                    <div class="d-flex align-items-center daytitlewrap mb-0">
                                                        <div class="d-flex gap-2 mt-1 mb-1 align-items-center">
                                                            <span class="nametag">{(detailedViewUserFname).slice(0, 1) + '' + (detailedViewUserLname).slice(0, 1)}</span>
                                                            <div class="titlewrap">
                                                                <h2 className="me-2"> <span className="userhd me-0">{detailedViewUserFname + ' ' + detailedViewUserLname}</span>
                                                                {/* Time Sheet */}
                                                                </h2>
                                                                {/* <p class="mb-0">Changes will save to {detailedViewUserFname}'s timesheet</p> */}
                                                            </div>
                                                        </div>
                                                        <span className="px-2">|</span>
                                                        <div class="d-flex align-items-center">
                                                            <div class="daytitlewrap d-flex align-items-center ">
                                                                <span class="d-flex align-items-center">
                                                                    {/* <img class="icon" src="images/cal-icon.png" alt="" /> */}
                                                                    <div class="d-grid">
                                                                        <h2 className="mb-0">Week: {weekLimitDates}</h2>
                                                                    </div>
                                                                </span>
                                                                {/* <div className="d-flex">
                                                                    <span className="px-2">|</span>
                                                                    <small className="companylabel">{timesheeetViewUserCompanyName}</small></div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <small class="badge rounded-pill bg-success mx-0 font-500 px-3 py-2">Approved</small> */}

                                                </>
                                                : myListViewStatus === 'Approved' ?
                                                    <>
                                                        <a href="#" onClick={backToApprovedList} class="bluetext"><img src="images/returnArrow.png" style={{ "margin-right": "4px", "transform": "rotate(180deg)" }} alt="" />Back to List
                                                            {/* Back to Approved List page */}
                                                        </a>
                                                        <div class="d-flex align-items-center daytitlewrap mb-0">
                                                            <span class="d-flex align-items-center">
                                                                <div class="d-flex gap-2 mt-1 mb-1 align-items-center">
                                                                    <span class="nametag">{(detailedViewUserFname).slice(0, 1) + '' + (detailedViewUserLname).slice(0, 1)}</span>
                                                                    <div class="titlewrap">
                                                                        <h2 className="me-2"><span className="userhd">{detailedViewUserFname + ' ' + detailedViewUserLname}</span>
                                                                        {/* Time Sheet */}
                                                                        </h2>
                                                                    </div>
                                                                </div>
                                                            </span>

                                                            <span className="ps-0 pe-2">|</span>
                                                            <div class="d-flex align-items-center">
                                                                <div class="daytitlewrap d-flex align-items-center ">
                                                                    <span class="d-flex align-items-center">
                                                                        {/* <img class="icon" src="images/cal-icon.png" alt="" /> */}
                                                                        <div class="d-grid">
                                                                            <h2 className="mb-0">Week: {weekLimitDates}</h2>
                                                                        </div>
                                                                    </span>
                                                                    {/*<span className="px-2">|</span>
                                                                    <small class="badge rounded-pill bg-success mx-0 font-500 px-3 py-2">Approved</small>
                                                                     <div className="d-flex">
                                                                        <span className="px-2">|</span>
                                                                        <small className="companylabel">{timesheeetViewUserCompanyName}</small>
                                                                    </div> */}

                                                                </div>
                                                                {/* <div>
                                                                <small class="badge rounded-pill bg-success mx-2 font-500 px-3 py-2">Approved</small>
                                                            </div> */}
                                                            </div>
                                                        </div>
                                                        <small class="badge rounded-pill bg-success mx-0 font-500 px-2 py-1">Approved</small>
                                                        <div class="billnonbill d-flex gap-4 align-items-center">
                                                            <div class="item d-grid">
                                                                <small> Total Hours</small>
                                                                <strong class="mainbluetxt ">{totalEntryTimeWeek.slice(0, -3)}</strong>
                                                            </div>
                                                            <span>|</span>
                                                            <div class="item d-grid">
                                                                <small class="d-flex gap-1 align-items-center">
                                                                    {/* <img src="images/dollargreen.svg" width="12" alt="" />  */}
                                                                    <img src="images/billed.png" width="20" alt="" /> Billable</small>
                                                                <strong class="mainbluetxt ">{totalBillableTimeWeek.slice(0, -3)}</strong>
                                                            </div>
                                                            <span>|</span>
                                                            <div class="item d-grid">
                                                                <small class="d-flex gap-1 align-items-center"> <img src="images/dollarred.svg"
                                                                    width="20" alt="" />
                                                                    Non-billable</small>
                                                                <strong class="mainbluetxt ">{totalNonBillableTimeWeek.slice(0, -3)}</strong>
                                                            </div>
                                                        </div>
                                                    </>
                                                    : ''}
                                    </div>


                                    <div class="mainbox">
                                        <div class="weekcal">
                                            <div></div>
                                            <div class="weekcalmain">
                                                <span class="weekmainitem d-grid">
                                                    <b class="text-uppercase">MON</b>
                                                    <small>{handleWeekDatesDisplayFormat(detailedViewDate1)}</small>
                                                </span>
                                                <span class="weekmainitem d-grid">
                                                    <b class="text-uppercase">tue</b>
                                                    <small>{handleWeekDatesDisplayFormat(caliculateNextDay(detailedViewDate1, 1))}</small>
                                                </span>
                                                <span class="weekmainitem d-grid">
                                                    <b class="text-uppercase">wed</b>
                                                    <small>{handleWeekDatesDisplayFormat(caliculateNextDay(detailedViewDate1, 2))}</small>
                                                </span>
                                                <span class="weekmainitem d-grid">
                                                    <b class="text-uppercase">thu</b>
                                                    <small>{handleWeekDatesDisplayFormat(caliculateNextDay(detailedViewDate1, 3))}</small>
                                                </span>
                                                <span class="weekmainitem d-grid">
                                                    <b class="text-uppercase">fri</b>
                                                    <small>{handleWeekDatesDisplayFormat(caliculateNextDay(detailedViewDate1, 4))}</small>
                                                </span>
                                                <span class="weekmainitem d-grid">
                                                    <b class="text-uppercase">sat</b>
                                                    <small>{handleWeekDatesDisplayFormat(caliculateNextDay(detailedViewDate1, 5))}</small>
                                                </span>
                                                <span class="weekmainitem d-grid">
                                                    <b class="text-uppercase">sun</b>
                                                    <small>{handleWeekDatesDisplayFormat(caliculateNextDay(detailedViewDate1, 6))}</small>
                                                </span>
                                            </div>
                                            <div></div>
                                        </div>

                                        {showWeekTimeEtriesList.length > 0 ?
                                            <div class="accordion" id="accordionPanelsStayOpenExample ">
                                                {showWeekTimeEtriesList.map((data, index) => (
                                                    <div class="accordion-item">
                                                        <h2 class="accordion-header weekafterem">
                                                            <button class="accordion-button px-0 position-relative collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-collapse` + index} aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                                                                <div class="weekcallist w-100">
                                                                    <div class="px-3 d-flex align-items-center gap-2 justify-content-between ">
                                                                        {/* <div class="props"> <img src="images/billed.png" alt=""/></div> */}
                                                                        <div class="list ps-3">
                                                                            <div class="client d-flex gap-2 align-items-center">
                                                                                <b>{data.projectName} </b>
                                                                                <span class="bluetext ">({data.clientName})</span>
                                                                                {/* <div class="lastrongelsprint ">Sprint :
                                                            <span>3</span></div> */}
                                                                            </div>
                                                                        </div>
                                                                        <div> <b class="mainbluetxt ">{(data.projectTotalTime).slice(0, -3)}</b></div>
                                                                    </div>


                                                                    <div>
                                                                        {/* <div> <b class="mainbluetxt ">{(data.projectTotalTime).slice(0, -3)}</b></div> */}
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        </h2>
                                                        <div id={`panelsStayOpen-collapse` + index} class="accordion-collapse collapse show" data-bs-parent="#accordionPanelsStayOpenExample">
                                                            <div class="accordion-body px-0 position-relative py-1">
                                                                {data.billableTimeEntries.length > 0 ?
                                                                    <div>
                                                                        {data.billableTimeEntries.map((billabletasks, billabletaskindex) => (
                                                                            <div>
                                                                                {billabletasks.timeentries.length > 0 ?
                                                                                    <div class="innerlist ">
                                                                                        <div class="weekcallist w-100 position-relative">
                                                                                            <div class="px-3 d-flex align-items-center gap-2">
                                                                                                <div class="list ps-2">
                                                                                                    <div class="weekview-list-task d-flex gap-2 align-items-center my-2 ">
                                                                                                        <div class="props" style={{ "margin-top": "0px" }}><img src="images/billed.png" width={20} alt="" /></div>
                                                                                                        <strong><span>{billabletasks.taskName}</span>
                                                                                                            <div className="d-flex gap-2">
                                                                                                                <a href="#" data-bs-toggle="modal" data-bs-target="#taskdes" onClick={() => handleToDisplayWeekDescription(billabletasks.taskId, detailedViewDate1, caliculateNextDay(detailedViewDate1, 6), data.projectName, data.clientName, billabletasks.taskName, '1')}>More</a>
                                                                                                                {!isEmpty(billabletasks.sprint) ?
                                                                                                                    <div class="lastrongelsprint ">{billabletasks.sprint}</div> : ''}
                                                                                                            </div>
                                                                                                        </strong>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <span class="weekcalmain">
                                                                                                <b class="d-flex justify-content-center">{getTimeEntryDateDuration('1', detailedViewDate1, data.projectId, billabletasks.taskId)}</b>
                                                                                                <b class="d-flex justify-content-center">{getTimeEntryDateDuration('1', caliculateNextDay(detailedViewDate1, 1), data.projectId, billabletasks.taskId)}</b>
                                                                                                <b class="d-flex justify-content-center">{getTimeEntryDateDuration('1', caliculateNextDay(detailedViewDate1, 2), data.projectId, billabletasks.taskId)}</b>
                                                                                                <b class="d-flex justify-content-center">{getTimeEntryDateDuration('1', caliculateNextDay(detailedViewDate1, 3), data.projectId, billabletasks.taskId)}</b>
                                                                                                <b class="d-flex justify-content-center">{getTimeEntryDateDuration('1', caliculateNextDay(detailedViewDate1, 4), data.projectId, billabletasks.taskId)}</b>
                                                                                                <b class="d-flex justify-content-center">{getTimeEntryDateDuration('1', caliculateNextDay(detailedViewDate1, 5), data.projectId, billabletasks.taskId)}</b>
                                                                                                <b class="d-flex justify-content-center">{getTimeEntryDateDuration('1', caliculateNextDay(detailedViewDate1, 6), data.projectId, billabletasks.taskId)}</b>
                                                                                            </span>
                                                                                            <div>
                                                                                                <div> <b class="mainbluetxt ">{(billabletasks.taskBillableTime).slice(0, -3)}</b></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    : ''}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                    : ''}

                                                                {data.unbillableTimeEntries.length > 0 ?
                                                                    <div>
                                                                        {data.unbillableTimeEntries.map((unbillabletasks, unbillabletaskindex) => (
                                                                            <div>
                                                                                {unbillabletasks.timeentries.length > 0 ?
                                                                                    <div class="innerlist ">
                                                                                        <div class="weekcallist w-100 position-relative">
                                                                                            <div class="px-3 d-flex align-items-center gap-2">
                                                                                                <div class="list ps-2">
                                                                                                    <div class="weekview-list-task d-flex gap-2 align-items-center my-2 ">
                                                                                                        <div class="props" style={{ "margin-top": "0px" }}>
                                                                                                            {/* <img src="images/nonbill.png" alt="" /> */}
                                                                                                            <img src="images/dollarred.svg" width={20} alt="" />

                                                                                                        </div>
                                                                                                        <strong> <span>{unbillabletasks.taskName}</span>
                                                                                                            <div className="d-flex gap-2">
                                                                                                                <a href="#" data-bs-toggle="modal" data-bs-target="#taskdes" onClick={() => handleToDisplayWeekDescription(unbillabletasks.taskId, detailedViewDate1, caliculateNextDay(detailedViewDate1, 6), data.projectName, data.clientName, unbillabletasks.taskName, '0')}>More</a>
                                                                                                                {!isEmpty(unbillabletasks.sprint) ?
                                                                                                                    <div class="lastrongelsprint ">{unbillabletasks.sprint}</div> : ''}
                                                                                                            </div>
                                                                                                        </strong>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <span class="weekcalmain">
                                                                                                <b class="d-flex justify-content-center">{getTimeEntryDateDuration('0', detailedViewDate1, data.projectId, unbillabletasks.taskId)}</b>
                                                                                                <b class="d-flex justify-content-center">{getTimeEntryDateDuration('0', caliculateNextDay(detailedViewDate1, 1), data.projectId, unbillabletasks.taskId)}</b>
                                                                                                <b class="d-flex justify-content-center">{getTimeEntryDateDuration('0', caliculateNextDay(detailedViewDate1, 2), data.projectId, unbillabletasks.taskId)}</b>
                                                                                                <b class="d-flex justify-content-center">{getTimeEntryDateDuration('0', caliculateNextDay(detailedViewDate1, 3), data.projectId, unbillabletasks.taskId)}</b>
                                                                                                <b class="d-flex justify-content-center">{getTimeEntryDateDuration('0', caliculateNextDay(detailedViewDate1, 4), data.projectId, unbillabletasks.taskId)}</b>
                                                                                                <b class="d-flex justify-content-center">{getTimeEntryDateDuration('0', caliculateNextDay(detailedViewDate1, 5), data.projectId, unbillabletasks.taskId)}</b>
                                                                                                <b class="d-flex justify-content-center">{getTimeEntryDateDuration('0', caliculateNextDay(detailedViewDate1, 6), data.projectId, unbillabletasks.taskId)}</b>
                                                                                            </span>
                                                                                            <div>
                                                                                                <div> <b class="mainbluetxt ">{(unbillabletasks.taskUnbillableTime).slice(0, -3)}</b></div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    : ''}
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                    : ''}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                            </div>
                                            : ''}


                                    </div>
                                    <div class="dayweektotal  ">
                                        <div class="weekcallist">
                                            <span class="p-4 d-flex">
                                                <b className="print-total">Total Hours</b>
                                            </span>
                                            <span class="weekcalmain">
                                                <b class="d-flex justify-content-center">
                                                    {dateWiseTimes.map((data, index) => (
                                                        data.day === detailedViewDate1 ? (parseInt(data.total_duration) > 0 ? (data.total_duration).slice(0, -3) : '') : ''
                                                    ))}
                                                </b>
                                                <b class="d-flex justify-content-center">
                                                    {dateWiseTimes.map((data, index) => (
                                                        data.day === caliculateNextDay(detailedViewDate1, 1) ? (parseInt(data.total_duration) > 0 ? (data.total_duration).slice(0, -3) : '') : ''
                                                    ))}
                                                </b>
                                                <b class="d-flex justify-content-center">
                                                    {dateWiseTimes.map((data, index) => (
                                                        data.day === caliculateNextDay(detailedViewDate1, 2) ? (parseInt(data.total_duration) > 0 ? (data.total_duration).slice(0, -3) : '') : ''
                                                    ))}
                                                </b>
                                                <b class="d-flex justify-content-center">
                                                    {dateWiseTimes.map((data, index) => (
                                                        data.day === caliculateNextDay(detailedViewDate1, 3) ? (parseInt(data.total_duration) > 0 ? (data.total_duration).slice(0, -3) : '') : ''
                                                    ))}
                                                </b>
                                                <b class="d-flex justify-content-center">
                                                    {dateWiseTimes.map((data, index) => (
                                                        data.day === caliculateNextDay(detailedViewDate1, 4) ? (parseInt(data.total_duration) > 0 ? (data.total_duration).slice(0, -3) : '') : ''
                                                    ))}
                                                </b>
                                                <b class="d-flex justify-content-center">
                                                    {dateWiseTimes.map((data, index) => (
                                                        data.day === caliculateNextDay(detailedViewDate1, 5) ? (parseInt(data.total_duration) > 0 ? (data.total_duration).slice(0, -3) : '') : ''
                                                    ))}
                                                </b>
                                                <b class="d-flex justify-content-center">
                                                    {dateWiseTimes.map((data, index) => (
                                                        data.day === caliculateNextDay(detailedViewDate1, 6) ? (parseInt(data.total_duration) > 0 ? (data.total_duration).slice(0, -3) : '') : ''
                                                    ))}
                                                </b>

                                            </span>
                                            <span><b class="mainbluetxt">{totalWeekTime.slice(0, -3)}</b></span>
                                        </div>
                                    </div>
                                    {commentsData.length > 0 ?
                                        <div class="commentwrap no-print">
                                            <b class="mb-2 d-block">Comments</b>
                                            {commentsData.map((commentdata, commentindex) => (
                                                <div class="commentItem my-2 no-print">
                                                    <div class="d-flex gap-2">
                                                        <strong class="name">{commentdata.user.first_name + ' ' + commentdata.user.last_name}</strong>
                                                        <span class="px-1">|</span>
                                                        <span class="reason">{handleCommentDatesDisplayFormat(commentdata.created_time)}</span>
                                                    </div>
                                                    <span>Remarks : <span>{commentdata.comments}</span></span>
                                                </div>
                                            ))}


                                        </div>
                                        : ''}

                                    {formattedDetails.length > 0 ?
                                        <>


                                            {viewTimesheetDetailsStat === 1 ?
                                                <>
                                                    <div class="border-bottom w-100 my-2 py-4">
                                                        <button class="btn btn-outline-secondary" id="hideTimesheetDetails" onClick={hideDetails}>Hide Details</button>
                                                    </div>


                                                    <div class="use-detail-info border-bottom" id="timesheetDetails">
                                                        <h4 class="my-3">{detailedViewUserFname}'s Time Sheet Details</h4>
                                                        {formattedDetails.map((detailData, detailIndex) => (
                                                            <>
                                                                <div class="weekheader">
                                                                    <span>{handleDetailedInfoDatesDisplayFormat(detailData.day)}</span>
                                                                    {/* <b class="ms-2">{totalEntryTimeWeek.slice(0,-3)} Hrs</b> */}
                                                                </div>
                                                                {detailData.timeEntries.map((entryData, entryIndex) => (
                                                                    <div class="d-grid p-2">
                                                                        <div className="d-flex justify-content-between">
                                                                            <div class="d-flex gap-2">
                                                                                <div class="titlewrap py-2">
                                                                                    <h5>{entryData.task.name}</h5>
                                                                                    <h6>{entryData.project.name} ({entryData.client.client_name})</h6>
                                                                                    <span>{entryData.time_entry_description}</span>
                                                                                </div>
                                                                            </div>

                                                                            <div class="weekitemlistitem border-bottom-0">
                                                                                <div class="hours scale85">
                                                                                    <span class="hrtime">{(entryData.duration).slice(0, -3)} <div class="hrlabel">Hrs</div></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                ))}
                                                            </>
                                                        ))}

                                                    </div>
                                                </>
                                                :
                                                <div class="border-bottom w-100 my-2 py-4">
                                                    <button class="btn btn-primary no-print me-2" id="viewTimesheetDetails" onClick={showDetails}>View Details</button>
                                                    <button className={`btn btn-primary no-print ${myListViewStatus === 'Unsubmitted' ? 'd-none' : ''}`}  onClick={handlePrintTimeSheetWeekview}> <img src="images/print.svg" width={24} alt="" /> Print</button>
                                                </div>
                                            }
                                        </> : <></>}

                                    <div id="approvalsuccessMessage" class="alert alert-success" role="alert">Time Sheet Approved successfully!</div>
                                    <div id="rejectionsuccessMessage" class="alert alert-success" role="alert">Time Sheet Rejected successfully!</div>
                                    <div id="withdrawalsuccessMessage" class="alert alert-success" role="alert">Time Sheet Approval withdrawn successfully!</div>
                                    <div id="emailRemindersuccessMessageSingle" class="alert alert-success" role="alert">Email reminder sent successfully!</div>

                                    <div class=" my-4 ">
                                        {totalStatusWeek === 'Approved' ?
                                            <button class="btn btn-danger me-2 no-print" type="button" data-bs-toggle="modal" data-bs-target="#withdraw" onClick={() => withdrawTimeSheet(detailedViewDate1, caliculateNextDay(detailedViewDate1, 6), detailedViewUserId)}>Withdraw Approval</button>
                                            :
                                            totalStatusWeek === 'Submitted' ?
                                                <>
                                                    <button class="btn btn-primary me-2 no-print" onClick={() => approveTimeSheet(detailedViewDate1, caliculateNextDay(detailedViewDate1, 6), detailedViewUserId)}>Approve Time Sheet</button>
                                                    {/*  data-bs-toggle="modal" data-bs-target="#exampleModal33" */}
                                                    <button class="btn btn-danger mx-0 no-print" type="button" data-bs-toggle="modal" data-bs-target="#reject" onClick={() => rejectTimeSheet(detailedViewDate1, caliculateNextDay(detailedViewDate1, 6), detailedViewUserId)}>Reject Time Sheet</button>
                                                </>
                                                : (totalStatusWeek === 'Unsubmitted' || totalStatusWeek === 'Rejected' || totalStatusWeek === 'Approval Withdrawn') ?
                                                    <button class="btn btn-primary me-2 no-print" onClick={() => sendEmailReminderforTimeSheet(detailedViewDate1, caliculateNextDay(detailedViewDate1, 6), detailedViewUserId)}>Send Email Reminder</button>
                                                    : ''
                                        }

                                    </div>

                                </div>

                                {/* Below code belongs to print portion */}
                                {/* print start */}
                                <div className="d-none">

                                    <div ref={printTimeSheetWeekviewHeaderRef}>
                                        <div className=" print-header d-flex justify-content-between  pb-2 mb-2">
                                            <div className="d-grid">
                                                <div className="print-logo-area d-flex align-items-center gap-2 mb-0">
                                                    <img src={companyPrintLogo} className="print-logo" height={36} alt="" />
                                                </div>
                                                <b>{timesheeetViewUserCompanyName}</b>
                                            </div>
                                            <div className="text-end d-grid">
                                                <h4>Time Report</h4>
                                                <div className="d-flex justify-content-end align-items-center gap-2 "><label>Period:</label> <h5> {weekLimitDates}</h5> </div>
                                                <div className="d-flex justify-content-end align-items-center gap-2 "><label>For:</label> <h5>{detailedViewUserFname} {detailedViewUserLname}</h5></div>
                                            </div>
                                        </div>
                                    </div>


                                    <div id="detailed_view" class="container tab-pane fade px-0 active show" role="tabpanel" ref={printTimeSheetWeekviewRef}>
                                        <div class="tab-content-bg-primary" >
                                            {myListViewStatus === 'Submitted' ?
                                                <>
                                                    {/* <div class="daytitlewrap mb-3">
                                                        <span class="d-flex align-items-center">
                                                            <div class="d-grid">
                                                                        <div className="print-logo-area d-flex align-items-center gap-2 mb-1">
                                                                                <img src={companyPrintLogo} className="print-logo" height={36} alt="" />
                                                                        </div>

                                                                <div className="d-flex">

                                                                    <h2 className="d-flex"> <div className="userhd">{detailedViewUserFname} {detailedViewUserLname}</div>
                                                                    <span className="px-2">|</span>
                                                                    week: {weekLimitDates} </h2>

                                                                </div>

                                                            </div>
                                                        </span>
                                                        Status: <small class="badge rounded-pill bg-success-orange mx-0 font-500 px-2 py-1 mt-2">Pending Approval</small>
                                                    </div> */}
                                                    <div class="billnonbill d-flex gap-4 align-items-center justify-content-end">
                                                        <div class="item d-grid">
                                                            <small> Total Hours</small>
                                                            <strong class="mainbluetxt ">{totalEntryTimeWeek.slice(0, -3)}</strong>
                                                        </div>
                                                        <span>|</span>
                                                        <div class="item d-grid">
                                                            <small class="d-flex gap-1 align-items-center">
                                                                {/* <img src="images/dollargreen.svg" width="12" alt="" />  */}
                                                                <img src="images/billed.png" width="20" alt="" /> Billable</small>
                                                            <strong class="mainbluetxt ">{totalBillableTimeWeek.slice(0, -3)}</strong>
                                                        </div>
                                                        <span>|</span>
                                                        <div class="item d-grid">
                                                            <small class="d-flex gap-1 align-items-center"> <img src="images/dollarred.svg"
                                                                width="20" alt="" />
                                                                Non-billable</small>
                                                            <strong class="mainbluetxt ">{totalNonBillableTimeWeek.slice(0, -3)}</strong>
                                                        </div>
                                                    </div></>
                                                : myListViewStatus === 'Unsubmitted' ?
                                                    <>

                                                    <div className="d-grid">
                                                    <div className="print-logo-area d-flex align-items-center gap-2 mb-1">
                                                                                <img src={companyPrintLogo} className="print-logo" height={36} alt="" />
                                                                        </div>
                                                        <div class="d-flex align-items-center daytitlewrap mb-0">
                                                            <div class="d-flex gap-2 mt-1 mb-1 align-items-center">
                                                                <span class="nametag no-print">{(detailedViewUserFname).slice(0, 1) + '' + (detailedViewUserLname).slice(0, 1)}</span>
                                                                <div class="titlewrap">
                                                                    <h2 className="me-2"> <span className="userhd me-0">{detailedViewUserFname + ' ' + detailedViewUserLname}'s</span> Time Sheet</h2>
                                                                    {/* <p class="mb-0">Changes will save to {detailedViewUserFname}'s timesheet</p> */}
                                                                </div>
                                                            </div>
                                                            <div class="d-flex align-items-center">
                                                                <div class="daytitlewrap d-flex align-items-center ">
                                                                    <span class="d-flex align-items-center">
                                                                        {/* <img class="icon" src="images/cal-icon.png" alt="" /> */}
                                                                        <div class="d-grid">
                                                                            <h2 className="mb-0">Week: {weekLimitDates}</h2>
                                                                        </div>
                                                                    </span>
                                                                    <div className="d-flex">
                                                                        <span className="px-2">|</span>
                                                                        <small className="companylabel">{timesheeetViewUserCompanyName}</small></div>
                                                                </div>
                                                            </div>
                                                        </div></div>

                                                    </>
                                                    : myListViewStatus === 'Approved' ?
                                                        <>


                                                            {/* <div className="d-grid">
                                                                <div className="print-logo-area d-flex align-items-center gap-2 mb-1">
                                                                    <img src={companyPrintLogo} className="print-logo" height={36} alt="" />
                                                                </div>

                                                                <div class="d-flex align-items-center daytitlewrap mb-0">
                                                                    <span class="d-flex align-items-center">
                                                                        <div class="d-flex gap-2 mt-1 mb-1 align-items-center">
                                                                            <span class="nametag no-print">{(detailedViewUserFname).slice(0, 1) + '' + (detailedViewUserLname).slice(0, 1)}</span>
                                                                            <div class="titlewrap">
                                                                                <h2 className="me-2"><span className="userhd">{detailedViewUserFname + ' ' + detailedViewUserLname}</span> </h2>
                                                                            </div>
                                                                        </div>
                                                                    </span>




                                                                </div>
                                                                <div >
                                                                    Status: <small class="badge rounded-pill bg-success mx-0 font-500 px-2 py-1">Approved</small>
                                                                </div>
                                                            </div> */}

                                                            <div class="billnonbill d-flex gap-4 align-items-center justify-content-end">
                                                                <div class="item d-grid">
                                                                    <small> Total Hours</small>
                                                                    <strong class="mainbluetxt ">{totalEntryTimeWeek.slice(0, -3)}</strong>
                                                                </div>
                                                                <span>|</span>
                                                                <div class="item d-grid">
                                                                    <small class="d-flex gap-1 align-items-center">
                                                                        {/* <img src="images/dollargreen.svg" width="12" alt="" />  */}
                                                                        <img src="images/billed.png" width="20" alt="" /> Billable</small>
                                                                    <strong class="mainbluetxt ">{totalBillableTimeWeek.slice(0, -3)}</strong>
                                                                </div>
                                                                <span>|</span>
                                                                <div class="item d-grid">
                                                                    <small class="d-flex gap-1 align-items-center"> <img src="images/dollarred.svg"
                                                                        width="20" alt="" />
                                                                        Non-billable</small>
                                                                    <strong class="mainbluetxt ">{totalNonBillableTimeWeek.slice(0, -3)}</strong>
                                                                </div>
                                                            </div>

                                                        </>
                                                        : ''}
                                        </div>


                                        <div class="mainbox">
                                            <div class="weekcal">
                                                <div></div>
                                                <div class="weekcalmain">
                                                    <span class="weekmainitem d-grid">
                                                        <b class="text-uppercase">MON</b>
                                                        <small>{handleWeekDatesDisplayFormat(detailedViewDate1)}</small>
                                                    </span>
                                                    <span class="weekmainitem d-grid">
                                                        <b class="text-uppercase">tue</b>
                                                        <small>{handleWeekDatesDisplayFormat(caliculateNextDay(detailedViewDate1, 1))}</small>
                                                    </span>
                                                    <span class="weekmainitem d-grid">
                                                        <b class="text-uppercase">wed</b>
                                                        <small>{handleWeekDatesDisplayFormat(caliculateNextDay(detailedViewDate1, 2))}</small>
                                                    </span>
                                                    <span class="weekmainitem d-grid">
                                                        <b class="text-uppercase">thu</b>
                                                        <small>{handleWeekDatesDisplayFormat(caliculateNextDay(detailedViewDate1, 3))}</small>
                                                    </span>
                                                    <span class="weekmainitem d-grid">
                                                        <b class="text-uppercase">fri</b>
                                                        <small>{handleWeekDatesDisplayFormat(caliculateNextDay(detailedViewDate1, 4))}</small>
                                                    </span>
                                                    <span class="weekmainitem d-grid">
                                                        <b class="text-uppercase">sat</b>
                                                        <small>{handleWeekDatesDisplayFormat(caliculateNextDay(detailedViewDate1, 5))}</small>
                                                    </span>
                                                    <span class="weekmainitem d-grid">
                                                        <b class="text-uppercase">sun</b>
                                                        <small>{handleWeekDatesDisplayFormat(caliculateNextDay(detailedViewDate1, 6))}</small>
                                                    </span>
                                                </div>
                                                <div></div>
                                            </div>

                                            {showWeekTimeEtriesList.length > 0 ?
                                                <div class="accordion" id="accordionPanelsStayOpenExample ">
                                                    {showWeekTimeEtriesList.map((data, index) => (
                                                        <div className={`accordion-item ${index >= 1 ? 'page-break' : ''}`}>
                                                            <h2 class="accordion-header weekafterem">
                                                                <button class="accordion-button px-0 position-relative collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-collapse` + index} aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                                                                    <div class="weekcallist w-100">
                                                                        <div class="px-3 d-flex align-items-center gap-2 justify-content-between ">
                                                                            {/* <div class="props"> <img src="images/billed.png" alt=""/></div> */}
                                                                            <div class="list ps-3">
                                                                                <div class="client d-flex gap-2 align-items-center">
                                                                                    <b>{data.projectName} </b>
                                                                                    <span class="bluetext ">({data.clientName})</span>
                                                                                    {/* <div class="lastrongelsprint ">Sprint :
                                                            <span>3</span></div> */}
                                                                                </div>
                                                                            </div>
                                                                            <div> <b class="mainbluetxt ">{(data.projectTotalTime).slice(0, -3)}</b></div>
                                                                        </div>


                                                                        <div>
                                                                            {/* <div> <b class="mainbluetxt ">{(data.projectTotalTime).slice(0, -3)}</b></div> */}
                                                                        </div>
                                                                    </div>
                                                                </button>
                                                            </h2>
                                                            <div id={`panelsStayOpen-collapse` + index} class="accordion-collapse collapse show" data-bs-parent="#accordionPanelsStayOpenExample">
                                                                <div class="accordion-body px-0 position-relative py-1">
                                                                    {data.billableTimeEntries.length > 0 ?
                                                                        <div>
                                                                            {data.billableTimeEntries.map((billabletasks, billabletaskindex) => (
                                                                                <div>
                                                                                    {billabletasks.timeentries.length > 0 ?
                                                                                        <div class="innerlist ">
                                                                                            <div class="weekcallist w-100 position-relative">
                                                                                                <div class="px-3 d-flex align-items-center gap-2">
                                                                                                    <div class="list ps-2">
                                                                                                        <div class="weekview-list-task d-flex gap-2 align-items-center my-2 ">
                                                                                                            <div class="props" style={{ "margin-top": "0px" }}><img src="images/billed.png" width={20} alt="" /></div>
                                                                                                            <strong><span>{billabletasks.taskName}</span>
                                                                                                                <div className="d-flex gap-2">
                                                                                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#taskdes" onClick={() => handleToDisplayWeekDescription(billabletasks.taskId, detailedViewDate1, caliculateNextDay(detailedViewDate1, 6), data.projectName, data.clientName, billabletasks.taskName, '1')}>More</a>
                                                                                                                    {!isEmpty(billabletasks.sprint) ?
                                                                                                                        <div class="lastrongelsprint ">{billabletasks.sprint}</div> : ''}
                                                                                                                </div>
                                                                                                            </strong>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <span class="weekcalmain">
                                                                                                    <b class="d-flex justify-content-center">{getTimeEntryDateDuration('1', detailedViewDate1, data.projectId, billabletasks.taskId)}</b>
                                                                                                    <b class="d-flex justify-content-center">{getTimeEntryDateDuration('1', caliculateNextDay(detailedViewDate1, 1), data.projectId, billabletasks.taskId)}</b>
                                                                                                    <b class="d-flex justify-content-center">{getTimeEntryDateDuration('1', caliculateNextDay(detailedViewDate1, 2), data.projectId, billabletasks.taskId)}</b>
                                                                                                    <b class="d-flex justify-content-center">{getTimeEntryDateDuration('1', caliculateNextDay(detailedViewDate1, 3), data.projectId, billabletasks.taskId)}</b>
                                                                                                    <b class="d-flex justify-content-center">{getTimeEntryDateDuration('1', caliculateNextDay(detailedViewDate1, 4), data.projectId, billabletasks.taskId)}</b>
                                                                                                    <b class="d-flex justify-content-center">{getTimeEntryDateDuration('1', caliculateNextDay(detailedViewDate1, 5), data.projectId, billabletasks.taskId)}</b>
                                                                                                    <b class="d-flex justify-content-center">{getTimeEntryDateDuration('1', caliculateNextDay(detailedViewDate1, 6), data.projectId, billabletasks.taskId)}</b>
                                                                                                </span>
                                                                                                <div>
                                                                                                    <div> <b class="mainbluetxt ">{(billabletasks.taskBillableTime).slice(0, -3)}</b></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        : ''}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        : ''}

                                                                    {data.unbillableTimeEntries.length > 0 ?
                                                                        <div>
                                                                            {data.unbillableTimeEntries.map((unbillabletasks, unbillabletaskindex) => (
                                                                                <div>
                                                                                    {unbillabletasks.timeentries.length > 0 ?
                                                                                        <div class="innerlist ">
                                                                                            <div class="weekcallist w-100 position-relative">
                                                                                                <div class="px-3 d-flex align-items-center gap-2">
                                                                                                    <div class="list ps-2">
                                                                                                        <div class="weekview-list-task d-flex gap-2 align-items-center my-2 ">
                                                                                                            <div class="props" style={{ "margin-top": "0px" }}>
                                                                                                                {/* <img src="images/nonbill.png" alt="" /> */}
                                                                                                                <img src="images/dollarred.svg" width={20} alt="" />

                                                                                                            </div>
                                                                                                            <strong> <span>{unbillabletasks.taskName}</span>
                                                                                                                <div className="d-flex gap-2">
                                                                                                                    <a href="#" data-bs-toggle="modal" data-bs-target="#taskdes" onClick={() => handleToDisplayWeekDescription(unbillabletasks.taskId, detailedViewDate1, caliculateNextDay(detailedViewDate1, 6), data.projectName, data.clientName, unbillabletasks.taskName, '0')}>More</a>
                                                                                                                    {!isEmpty(unbillabletasks.sprint) ?
                                                                                                                        <div class="lastrongelsprint ">{unbillabletasks.sprint}</div> : ''}
                                                                                                                </div>
                                                                                                            </strong>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <span class="weekcalmain">
                                                                                                    <b class="d-flex justify-content-center">{getTimeEntryDateDuration('0', detailedViewDate1, data.projectId, unbillabletasks.taskId)}</b>
                                                                                                    <b class="d-flex justify-content-center">{getTimeEntryDateDuration('0', caliculateNextDay(detailedViewDate1, 1), data.projectId, unbillabletasks.taskId)}</b>
                                                                                                    <b class="d-flex justify-content-center">{getTimeEntryDateDuration('0', caliculateNextDay(detailedViewDate1, 2), data.projectId, unbillabletasks.taskId)}</b>
                                                                                                    <b class="d-flex justify-content-center">{getTimeEntryDateDuration('0', caliculateNextDay(detailedViewDate1, 3), data.projectId, unbillabletasks.taskId)}</b>
                                                                                                    <b class="d-flex justify-content-center">{getTimeEntryDateDuration('0', caliculateNextDay(detailedViewDate1, 4), data.projectId, unbillabletasks.taskId)}</b>
                                                                                                    <b class="d-flex justify-content-center">{getTimeEntryDateDuration('0', caliculateNextDay(detailedViewDate1, 5), data.projectId, unbillabletasks.taskId)}</b>
                                                                                                    <b class="d-flex justify-content-center">{getTimeEntryDateDuration('0', caliculateNextDay(detailedViewDate1, 6), data.projectId, unbillabletasks.taskId)}</b>
                                                                                                </span>
                                                                                                <div>
                                                                                                    <div> <b class="mainbluetxt ">{(unbillabletasks.taskUnbillableTime).slice(0, -3)}</b></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        : ''}
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                        : ''}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}

                                                </div>
                                                : ''}


                                        </div>
                                        <div class="dayweektotal  ">
                                            <div class="weekcallist">
                                                <span class="p-4">
                                                    <b  className="print-total">Total Hours</b>
                                                </span>
                                                <span class="weekcalmain">
                                                    <b class="d-flex justify-content-center">
                                                        {dateWiseTimes.map((data, index) => (
                                                            data.day === detailedViewDate1 ? (parseInt(data.total_duration) > 0 ? (data.total_duration).slice(0, -3) : '') : ''
                                                        ))}
                                                    </b>
                                                    <b class="d-flex justify-content-center">
                                                        {dateWiseTimes.map((data, index) => (
                                                            data.day === caliculateNextDay(detailedViewDate1, 1) ? (parseInt(data.total_duration) > 0 ? (data.total_duration).slice(0, -3) : '') : ''
                                                        ))}
                                                    </b>
                                                    <b class="d-flex justify-content-center">
                                                        {dateWiseTimes.map((data, index) => (
                                                            data.day === caliculateNextDay(detailedViewDate1, 2) ? (parseInt(data.total_duration) > 0 ? (data.total_duration).slice(0, -3) : '') : ''
                                                        ))}
                                                    </b>
                                                    <b class="d-flex justify-content-center">
                                                        {dateWiseTimes.map((data, index) => (
                                                            data.day === caliculateNextDay(detailedViewDate1, 3) ? (parseInt(data.total_duration) > 0 ? (data.total_duration).slice(0, -3) : '') : ''
                                                        ))}
                                                    </b>
                                                    <b class="d-flex justify-content-center">
                                                        {dateWiseTimes.map((data, index) => (
                                                            data.day === caliculateNextDay(detailedViewDate1, 4) ? (parseInt(data.total_duration) > 0 ? (data.total_duration).slice(0, -3) : '') : ''
                                                        ))}
                                                    </b>
                                                    <b class="d-flex justify-content-center">
                                                        {dateWiseTimes.map((data, index) => (
                                                            data.day === caliculateNextDay(detailedViewDate1, 5) ? (parseInt(data.total_duration) > 0 ? (data.total_duration).slice(0, -3) : '') : ''
                                                        ))}
                                                    </b>
                                                    <b class="d-flex justify-content-center">
                                                        {dateWiseTimes.map((data, index) => (
                                                            data.day === caliculateNextDay(detailedViewDate1, 6) ? (parseInt(data.total_duration) > 0 ? (data.total_duration).slice(0, -3) : '') : ''
                                                        ))}
                                                    </b>

                                                </span>
                                                <span><b class="mainbluetxt">{totalWeekTime.slice(0, -3)}</b></span>
                                            </div>
                                        </div>
                                        {commentsData.length > 0 ?
                                            <div class="commentwrap no-print">
                                                <b class="mb-2 d-block">Comments</b>
                                                {commentsData.map((commentdata, commentindex) => (
                                                    <div class="commentItem my-2">
                                                        <div class="d-flex gap-2">
                                                            <strong class="name">{commentdata.user.first_name + ' ' + commentdata.user.last_name}</strong>
                                                            <span class="px-1">|</span>
                                                            <span class="reason">{handleCommentDatesDisplayFormat(commentdata.created_time)}</span>
                                                        </div>
                                                        <span>Remarks : <span>{commentdata.comments}</span></span>
                                                    </div>
                                                ))}


                                            </div>
                                            : ''}

                                        {formattedDetails.length > 0 ?
                                            <>


                                                {viewTimesheetDetailsStat === 1 ?
                                                    <>
                                                        <div class="border-bottom w-100 my-2 py-4">
                                                            <button class="btn btn-outline-secondary" id="hideTimesheetDetails" onClick={hideDetails}>Hide Details</button>
                                                        </div>


                                                        <div class="use-detail-info border-bottom" id="timesheetDetails">
                                                            <h4 class="my-3">{detailedViewUserFname}'s Time Sheet Details</h4>
                                                            {formattedDetails.map((detailData, detailIndex) => (
                                                                <>
                                                                    <div class="weekheader">
                                                                        <span>{handleDetailedInfoDatesDisplayFormat(detailData.day)}</span>
                                                                        {/* <b class="ms-2">{totalEntryTimeWeek.slice(0,-3)} Hrs</b> */}
                                                                    </div>
                                                                    {detailData.timeEntries.map((entryData, entryIndex) => (
                                                                        <div class="d-grid p-2">
                                                                            <div className="d-flex justify-content-between">
                                                                                <div class="d-flex gap-2">
                                                                                    <div class="titlewrap py-2">
                                                                                        <h5>{entryData.task.name}</h5>
                                                                                        <h6>{entryData.project.name} ({entryData.client.client_name})</h6>
                                                                                        <span>{entryData.time_entry_description}</span>
                                                                                    </div>
                                                                                </div>

                                                                                <div class="weekitemlistitem border-bottom-0">
                                                                                    <div class="hours scale85">
                                                                                        <span class="hrtime">{(entryData.duration).slice(0, -3)} <div class="hrlabel">Hrs</div></span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    ))}
                                                                </>
                                                            ))}

                                                        </div>
                                                    </>
                                                    :
                                                    <div class="border-bottom w-100 my-2 py-4">
                                                        <button class="btn btn-primary no-print me-2" id="viewTimesheetDetails" onClick={showDetails}>View Details</button>
                                                        <button class="btn btn-primary no-print" onClick={handlePrintTimeSheetWeekview}> <img src="images/print.svg" width={24} alt="" /> Print</button>
                                                    </div>
                                                }
                                            </> : <></>}

                                        <div class=" my-4 ">
                                            {totalStatusWeek === 'Approved' ?
                                                <button class="btn btn-primary me-2 no-print" type="button" data-bs-toggle="modal" data-bs-target="#withdraw" onClick={() => withdrawTimeSheet(detailedViewDate1, caliculateNextDay(detailedViewDate1, 6), detailedViewUserId)}>Withdraw Approval</button>
                                                :
                                                totalStatusWeek === 'Submitted' ?
                                                    <>
                                                        <button class="btn btn-primary me-2 no-print" onClick={() => approveTimeSheet(detailedViewDate1, caliculateNextDay(detailedViewDate1, 6), detailedViewUserId)}>Approve Time Sheet</button>
                                                        {/*  data-bs-toggle="modal" data-bs-target="#exampleModal33" */}
                                                        <button class="btn btn-outline-dark mx-0 no-print" type="button" data-bs-toggle="modal" data-bs-target="#reject" onClick={() => rejectTimeSheet(detailedViewDate1, caliculateNextDay(detailedViewDate1, 6), detailedViewUserId)}>Reject Time Sheet</button>
                                                    </>
                                                    : (totalStatusWeek === 'Unsubmitted' || totalStatusWeek === 'Rejected' || totalStatusWeek === 'Approval Withdrawn') ?
                                                        <button class="btn btn-primary me-2 no-print" onClick={() => sendEmailReminderforTimeSheet(detailedViewDate1, caliculateNextDay(detailedViewDate1, 6), detailedViewUserId)}>Send Email Reminder</button>
                                                        : ''
                                            }

                                        </div>

                                    </div>





                                </div>

                            </div>
                            {/* above code belongs to print portion */}
                        </div>

                    </div>

                    <div class="modal fade" id="withdraw" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <form id="entryform" onSubmit={handleSubmit(submitWithdrawValue)}>
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">Withdraw Approval</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    {showWithdrawLoader === 1 ?
                                        <div class="loadwrapperpopup">
                                            <div class="clock"><div class="circle"></div></div>
                                        </div>
                                        : ''}
                                    <div class="modal-body">
                                        <div class="form-group mb-2">
                                            <label for="abc">Write Comment *</label>
                                            <div class="form-floating">
                                                <textarea class={`form-control pt-1 ${withdrawlReasonValidation === 1 ? 'errorinp' : ''}`} id="withdrawalComment" placeholder={`Write withdrawal comment in max ${maxLength} characters`} value={textvalue} onChange={handleChangeTextarea}></textarea>
                                                <small className="textAreaMaxLength">(Max 500 Characters)</small>
                                            </div>
                                        </div>
                                        <div className="validationglobal p-2" id="withdrawalvalidationMessage">
                                            <div className="validateitem">
                                                {/* <span className="errimg"><img class="icon" src="images/error.svg" alt=""/></span> */}
                                                <p>Please fill out all the required fields!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="submit" class="btn btn-danger">Widthdraw Approval</button>
                                        <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">Cancel</button>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="modal fade" id="reject" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <form id="entryform" onSubmit={handleSubmit(submitValue)}>
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">Reject Time Sheet</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    {showRejectionLoader === 1 ?
                                        <div class="loadwrapperpopup">
                                            <div class="clock"><div class="circle"></div></div>
                                        </div>
                                        : ''}
                                    <div class="modal-body">
                                        <div class="form-group mb-2">
                                            <label for="abc">Reason for Reject *</label>
                                            <div class="form-floating">
                                                {/* <textarea class="form-control pt-1" placeholder="Enter Reason" id="floatingTextarea2"></textarea>  */}
                                                <textarea class={`form-control pt-1 ${rejectionReasonValidation === 1 ? 'errorinp' : ''}`} id="rejectionReason" placeholder={`Write rejection reason in max ${maxLength} characters`} value={rejectionvalue} onChange={handleChangeRejectionTextarea}></textarea>

                                            </div>
                                            <small className="textAreaMaxLength">(Max 500 Characters)</small>
                                        </div>
                                        <div className="validationglobal p-2" id="validationMessage">
                                            <div className="validateitem">
                                                {/* <span className="errimg"><img class="icon" src="images/error.svg" alt=""/></span> */}
                                                <p>Please fill out all the required fields!</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="submit" class="btn btn-primary">Submit</button>
                                        <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">Cancel</button>

                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="modal fade" id="exampleModal33" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title no-print" id="exampleModalLabel">Approve Time Sheet</h5>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <p class="text-center p-2">
                                        <small>User has requested for comp-off. Please review the details and indicate approval by selecting the checkbox's.</small>
                                    </p>

                                    <div class="d-grid p-2">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                            <label class="form-check-label" for="flexCheckDefault">
                                                12 Feb
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                            <label class="form-check-label" for="flexCheckDefault">
                                                13 Feb
                                            </label>
                                        </div>
                                    </div>

                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary">Submit</button>
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                                </div>
                            </div>
                        </div>
                    </div>

                    {showLoader === 1 ?
                        <div class="loadwrapper">
                            <div class="clock"><div class="circle"></div></div>
                        </div>
                        : ''}

                    <TaskDescription project={selectedProject} client={selectedClient} task={selectedTask} isbillable={selectedBillable} result={selectedEntryResult} />

                </section>

            </main>

        </>
    )
};

AdminTimesheetManagement.propTypes = {};

AdminTimesheetManagement.defaultProps = {};

export default AdminTimesheetManagement;

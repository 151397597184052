import React,{useEffect, useState} from "react";


const ProjectManagementHeader = () => {
   //const useridval = 4;
   const [loggedUser, setLoggedUser] = useState('');
   const [useridval, setuseridval] = useState(0);

   useEffect(() =>
   {
    var loggedinUserRole = sessionStorage.getItem('userRole');
    setLoggedUser(loggedinUserRole);

    var loggedUserId = sessionStorage.getItem('userIdval');
        setuseridval(parseInt(loggedUserId));
   },[1]);

  return(
  <>
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <a class={`nav-link bg-none ${(window.location.pathname === '/project-management') ? 'active' : ''}`} href="/project-management">Clients</a>
                </li>
                <li class="nav-item">
                    <a class={`nav-link bg-none ${(window.location.pathname === '/manageprojects' || window.location.pathname === '/project-details') ? 'active' : ''}`} href="/manageprojects">Projects</a>
                </li>
                <li class="nav-item">
                    <a class={`nav-link bg-none ${(window.location.pathname === '/managetasks' || window.location.pathname === '/task-details') ? 'active' : ''}`} href="/managetasks">Tasks</a>
                </li>
            </ul>
  </>
)};

ProjectManagementHeader.propTypes = {};

ProjectManagementHeader.defaultProps = {};

export default ProjectManagementHeader;

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import services from "../../services/services";
import $ from 'jquery';
import jwtDecode from 'jwt-decode';

const Header = () => {
    const [loggedUser, setLoggedUser] = useState('');
    const [useridval, setuseridval] = useState(sessionStorage.getItem('userIdval'));
    const [companyFilterData, setCompaniesFilterData] = useState([]);
    const [userCompanyFilterData, setUserCompanyFilterData] = useState(sessionStorage.getItem('userCompanyName'));
    const [adminCompanyVal, setAdminCompanyVal] = useState('sessionStorage.getItem("adminCompany")');

    const [companyPrintLogo, setCompanyPrintLogo] = useState("");
    //###########################
    const loggedUserId = sessionStorage.getItem('userIdval');
    const accessToken = sessionStorage.getItem('accessToken');


    const location = useLocation(); // This gives you access to the current URL path
    const readOnlyPages = ['/projectDetail', '/projectDetail2']; // Array of pages where the filter should be read-only
    const isReadOnly = readOnlyPages.includes(location.pathname);

     if (!loggedUserId || !accessToken) {
        return null;
     }

     // Decode the access token
     const decodedToken = jwtDecode(accessToken);

     // Check if the token is expired
     const currentTime = Math.floor(Date.now() / 1000);
     if (decodedToken.exp < currentTime) {
        sessionStorage.clear();
        return null;
     }
      //########################################

    useEffect(() => {

        // Check if the user is logged in
        const loggedUserId = sessionStorage.getItem('userIdval');
        /*
        if (!loggedUserId) {
            // If not logged in, redirect to login page
            window.location.href = '/login';
            return;
        }
        */

        if (!loggedUserId) {
            // If not logged in, redirect to login page
            //window.location.href = '/login';
            return;
        }
        var loggedinUserRole = sessionStorage.getItem('userRole');
        setLoggedUser(loggedinUserRole);

        //var loggedUserId = sessionStorage.getItem('userIdval');

        //setUserCompanyFilterData(sessionStorage.getItem('userCompanyName'));

        const timeoutId = setTimeout(() => {
            setAdminCompanyVal(sessionStorage.getItem('adminCompany'));
            setUserCompanyFilterData(sessionStorage.getItem('userCompanyName'));
            if(sessionStorage.getItem('userRole') === 'Admin'){
                setCompanyPrintLogo(services.fetchServerAPIEndPoint()+"uploads/company-logo/company_logo_file_"+sessionStorage.getItem('adminCompany')+".png");
            } else {
                setCompanyPrintLogo(services.fetchServerAPIEndPoint()+"uploads/company-logo/company_logo_file_"+sessionStorage.getItem('userCompanyId')+".png");
            }

          }, 2000); // 500ms delay to simulate the timeout

        getFullCompanyList();



    }, []);

    const setAdminCompanyFilter = (event) => {
        var myCompanyId = event.target.value;
        sessionStorage.setItem('adminCompany', myCompanyId);
        window.location.reload();
    }

    const getFullCompanyList = () => {
        services.getCompanyList(1).then((res) => {
            if(res.data.type === "success"){
                var fullcompanyList = res.data.data;
                setCompaniesFilterData(res.data.data);
                //alert(JSON.stringify(res.data.data));

                if(sessionStorage.getItem('adminCompany') == ""){
                    sessionStorage.setItem('adminCompany',res.data.data[0].id);
                }
            }
        });

    }



    return (
        <>

                {/*<header>*/}
                    <div class="container">
                        <nav class="navbar navbar-expand-lg my-2 ">
                            <div class="container-fluid">
                                <a class="navbar-brand" href="#"><img class="icon" src="images/timesheet-logo.png" alt=""/></a>
                                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                                    <ul class="navbar-nav ms-auto">
                                        <div class="d-flex align-items-center gap-2">
                                            {/* <span>Company:</span> */}
                                            <div className="print-logo-area d-flex align-items-center gap-2">
                                                <img src={companyPrintLogo} className="print-logo" height={36} alt="" />
                                            </div>
                                            {(loggedUser.toLowerCase() === 'admin') ?
                                            <select class="form-select cmp-lbl" aria-label="Default select example" id="companyFilter" value={adminCompanyVal} onChange={setAdminCompanyFilter} disabled={isReadOnly}>
                                                {/* <option value="">All</option> */}
                                                {companyFilterData.map((companyData, companyindex) => (
                                                    <option value={companyData.id}>{companyData.company_name}</option>
                                                ))}
                                            </select>
                                            :
                                            <input type="text" class="form-control company-lbl" id="companyFilterName" style={{"width": "auto"}} value={userCompanyFilterData} disabled />
                                            }
                                        </div>
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
               {/* </header>*/}

        </>

    );
};

export default Header;
import React,{history,useEffect, useState, useMemo, useRef } from "react";
import services from "../../services/services";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $ from 'jquery';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DateObject from "react-date-object";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

const Option = (props) => {
    return (
        <div>
            {
                //console.log("results=="+JSON.stringify(props))
            }
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />{" "}
                <label>{props.label}</label>
            </components.Option>
        </div>
    );
};


const AddProject = ({editProject}) => {
    const calendarRef = useRef(null);
    const calendarRef2 = useRef(null);

    const calendarRef3 = useRef(null);
    const calendarRef4 = useRef(null);
    
    const [showCompanyList, setShowCompanyList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [clientName, setclientName] = useState('');
    const [initialClientName, setInitialClientName] = useState('');
    const [projectClientid, setProjectClientid] = useState('');
    const [initialCompany, setInitialCompany] = useState('');
    const [showProjectInfo, setShowProjectInfo] = useState('');

    const [projectClientListInfo, setProjectClientListInfo] = useState([]);
    const [projectManagerListInfo, setProjectManagerListInfo] = useState([]);
    const [projectUsersListInfo, setProjectUsersListInfo] = useState([]);
    const [projectCompanyList, setProjectCompanyList] = useState([]);
    const pageNum = 1;
    const pageSize = 100;

    const [projectNamevalidation, setProjectNamevalidation] = useState(0);
    const [clientNamevalidation, setClientNamevalidation] = useState(0);
    const [newclientvalidation, setNewclientvalidation] = useState(0);
    const [newclientcompanyvalidation, setNewclientcompanyvalidation] = useState(0);

    const [editprojectNamevalidation, setEditProjectNamevalidation] = useState(0);
    const [editclientNamevalidation, setEditClientNamevalidation] = useState(0);
    const [editnewclientvalidation, setEditNewclientvalidation] = useState(0);
    const [editnewclientcompanyvalidation, setEditNewclientcompanyvalidation] = useState(0);

    const [multiProjectManagers, setmultiProjectManagers] = useState([]);
    const [multiProjectUsers, setmultiProjectUsers] = useState([]);
    const [multiProjectUsers1, setmultiProjectUsers1] = useState([]);
    const [query, setQuery] = useState("");
    const [myProjectStatus, setProjectStatus] = useState([]);
    const {  reset } = useForm();

    const [showLoader, setShowLoader] = useState(1);
    const [isManager, setIsManager] = useState(false);
    const [projectName, setProjectName] = useState('');

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all",mode: "onBlur"
    });

const [clientCompanyId, setClientCompanyId] = useState('');
const [companyid, setCompanyId] = useState(sessionStorage.getItem('userCompanyId'));
const [adminCompanyid, setAdminCompanyId] = useState(sessionStorage.getItem('adminCompany'));
const clientStatus = 1;
const [newClient, setNewClient] = useState(0);
const [loggedUser, setLoggedUser] = useState('');
    useEffect(() => {
        setShowLoader(0);
        var loggedinUserRole = sessionStorage.getItem('userRole');
        setLoggedUser(loggedinUserRole);
        $(".loadwrapper").hide();
        $('#new_client').hide();
        
        setCompanyId(sessionStorage.getItem('userCompanyId'));
        setAdminCompanyId(sessionStorage.getItem('adminCompany'));

        if(!isEmpty(editProject)){
            //edit
            $('#addProjectBody').hide();
            $('#editProjectBody').show();
             console.log(editProject);
            getProjectInfo(editProject);
        }
        else {
            $('#addProjectBody').show();
            $('#editProjectBody').hide();
        }
        $('#successMessageProject').hide();
        $('#updatesuccessMessageProject').hide();
        $('#failuresuccessMessageProject').hide();
        $('#validationMessage').hide();
        $('#editvalidationMessage').hide();
        getFullClientsList();
    },[editProject]);

    function getProjectInfo(projectid) {
        services.getProjectInfoById(projectid).then((res) => {
            //console.log(res.data);
            if(res){
                 if(res.data.data !== null){
                    setShowProjectInfo(res.data.data);

                    const project_name = res.data.data.name;
                    // Capitalize the first letter of each word
                    const capitalizedProjectNameValue = project_name
                        .split(' ')
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(' ');

                    setProjectName(capitalizedProjectNameValue);

                    if (res.data.data.project_start_date) {
                        var mystartdate = (res.data.data.project_start_date).split('-'); //YYYY-MM-DD 
                        setEditStartdate(mystartdate[1]+'-'+mystartdate[2]+'-'+mystartdate[0]);
                    }

                    if (res.data.data.project_end_date) {
                        var myenddate = (res.data.data.project_end_date).split('-'); //YYYY-MM-DD
                        setEditEnddate(myenddate[1]+'-'+myenddate[2]+'-'+myenddate[0]);
                    }
                   
                    var budgettime = res.data.data.budget_time;
                    var splittime = budgettime.split(':');
                    setFormattedTime(splittime[0]);
                    setValue(splittime[0]);
                    $('#editproject_time').val(splittime[0]);
                    setProjectClientid(res.data.data.client_id);
                    setProjectStatus(res.data.data.projectStatus)

                    getClientInfo(res.data.data.client_id);

                 }
            }
        });
    }

    const isEmpty = (value) => {
        return value === null || String(value).trim() === '';
    }

    const getTitleHead = () => {
        if(!isEmpty(editProject)){
            return 'Edit Project';
        }
        else {
            return 'Create New Project';
        }
    }

    function getFullClientsList() {
        var managerid = '';
        var reqCompanyVal = '';
        
            if(sessionStorage.getItem('userRole') == 'Manager'){
                //managerid = sessionStorage.getItem('userIdval');
                reqCompanyVal = companyid;
            }

            if(sessionStorage.getItem('userRole') == 'Admin'){
                reqCompanyVal = adminCompanyid;
            }
            
        services.getClientsList(reqCompanyVal, clientStatus, pageNum, pageSize, managerid).then((res) => {
            
            if(res.data.type === 'success'){
                setProjectClientListInfo(res.data.data);
            }
        });
    }

    const [myCompanyVal, setMyCompanyVal] = useState('');
    const newClientHideandShow = () => {
        $('#existing_client').hide();
        $('#new_client').show();
        setNewClient(1);
        getFullCompanyList();
        var loggedUserCompanyIdVal = sessionStorage.getItem('userCompanyId');
        var loggedUserRole = sessionStorage.getItem('userRole');
        if(loggedUserRole.toLowerCase() === 'manager'){
            setMyCompanyVal(loggedUserCompanyIdVal);
            setIsManager(true);
            getManagersAndUsers(loggedUserCompanyIdVal);  
        }
    }

    const resetForm = () => {
        $("#entryform").trigger("reset");
        setProjectNamevalidation(0);
        setProjectNamevalidation(0)
        setNewclientcompanyvalidation(0);
        setNewclientcompanyvalidation(0);
        setClientNamevalidation(0);        
        setShowValidation(0);   
        setoptionManagersSelected([]);
		setValue('');
        setoptionUsersSelected([])
    };

    useEffect(() => {
        // Attach the event listener to the modal
        const modalElement = document.getElementById('exampleModalcp');
        const handleModalHidden = () => resetForm();
        
        modalElement.addEventListener('hidden.bs.modal', handleModalHidden);

        return () => {
            // Cleanup the event listener when the component unmounts
            modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
        };
    }, []);

    useEffect(() => {
        console.log('trigger '+editProject);
        if (!isOpen) {
            resetForm(); // Reset the form when the modal is closed
        }
    }, [isOpen]);

    const closeNewClient = () => {
        $('#existing_client').show();
        $('#new_client').hide();
        //resetForm();
        $('#newclient_name').val('');
        $('#newclient_company').val('');
        setNewClient(0);
    }

    function getFullCompanyList(){
        var companystatus = 1;
        services.getCompanyList(companystatus).then((res) => {
            if(res){
                if(res.data.data !== null){
                    setProjectCompanyList(res.data.data);
                }
            }
        });
    }

    const today = new Date();
    const today_year = today.getFullYear();
    const today_month = String(today.getMonth() + 1).padStart(2, '0');
    const today_day = String(today.getDate()).padStart(2, '0');

    const [startdate, setStartDate] = useState('');
    const [enddate, setEndDate] = useState('');

    const [editStartdate, setEditStartdate] = useState('');
    const [editEnddate, setEditEnddate] = useState('');

  const [isStartCalendarOpen, setIsStartCalendarOpen] = useState(0);
  const [isEndCalendarOpen, setIsEndCalendarOpen] = useState(0);

  const [isEditStartCalendarOpen, setIsEditStartCalendarOpen] = useState(0);
  const [isEditEndCalendarOpen, setIsEditEndCalendarOpen] = useState(0);

  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  //const inputRef = useRef(null);

  useEffect(() => {
    // Function to handle click outside of the calendar
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        //setIsEndCalendarOpen(0);
        setIsStartCalendarOpen(0);
      }

      if (calendarRef2.current && !calendarRef2.current.contains(event.target)) {
        setIsEndCalendarOpen(0);
        //setIsStartCalendarOpen(0);
      }

      if (calendarRef3.current && !calendarRef3.current.contains(event.target)) {
        //setIsEndCalendarOpen(0);
        setIsEditStartCalendarOpen(0);
      }


      if (calendarRef4.current && !calendarRef4.current.contains(event.target)) {
        setIsEditEndCalendarOpen(0);
        //setIsStartCalendarOpen(0);
      }

    };

    // Add event listener on mount
    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const onChangeStartDate = date => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    setStartDate(month+'-'+day+'-'+year);
    setIsStartCalendarOpen(0);
  };

  const onChangeEndDate = date => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    setEndDate(month+'-'+day+'-'+year);
    setIsEndCalendarOpen(0);
  };


  const onChangeEditStartDate = date => {
   
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    setEditStartdate(month+'-'+day+'-'+year);
    setIsEditStartCalendarOpen(0);
  };

  const onChangeEditEndDate = date => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    setEditEnddate(month+'-'+day+'-'+year);
    setIsEditEndCalendarOpen(0);
  };

  const toggleStartDateCalendar = () => {    
    if(isStartCalendarOpen == 1){
        setIsStartCalendarOpen(0);
    } else {
        setIsStartCalendarOpen(1);
    }
  };

  const toggleEndDateCalendar = () => {
    if(isEndCalendarOpen == 1){
        setIsEndCalendarOpen(0);
    } else {
        setIsEndCalendarOpen(1);
    }
  };

  const toggleEditStartDateCalendar = () => {    
    if(isEditStartCalendarOpen == 1){
        setIsEditStartCalendarOpen(0);
    } else {
        setIsEditStartCalendarOpen(1);
    }
  };

  const toggleEditEndDateCalendar = () => {
    if(isEditEndCalendarOpen == 1){
        setIsEditEndCalendarOpen(0);
    } else {
        setIsEditEndCalendarOpen(1);
    }
  };

  const handleClientChange = (event) => {
        var clientid = event.target.value;
        getClientInfo(clientid);
  }

  const getUsersWithCompany = (event) => {
        var companyid = event.target.value;
        setMyCompanyVal(companyid);
        setoptionManagersSelected([]);
        setoptionUsersSelected([]);
        getManagersAndUsers(companyid);       
  }

  const getManagersAndUsers = (companyidval) => {
    //load managers
    var usersResponse = '';
    var dummArray_users = [];
    const requestObject =
    {
        "roleId": ["2"],
        "status": '1',
        "companyId": companyidval,
        "pageNumber": pageNum,
        "pageSize": pageSize
      }
    var accessTokenIn=sessionStorage.getItem("accToken");
    // const requestOptions = {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': "Bearer "+accessTokenIn
    //   },
    //   body: JSON.stringify(requestObject)
    // };
    services.getUsersByRole(requestObject).then((res) => {
        setProjectManagerListInfo(res.data);
        var serviceresponse = res.data;
        usersResponse = serviceresponse;
        var dummArray = [];
        var dummArray = [{ "label": "All", "value": "all" }];
        if(!isEmpty(res.data)){
            for (var i = 0; i < res.data.length; i++) {
                dummArray.push({ "label": res.data[i].first_name+' '+res.data[i].last_name, "value": res.data[i].id });

                //dummArray_users.push({ "label": res.data.data[i].first_name+' '+res.data.data[i].last_name, "value": res.data.data[i].id });
            }
        }

        setmultiProjectManagers(dummArray);
    });

    //load users
    const userrequestObject =
    {
        "roleId": ["3"],
        "status": '1',
        "companyId": companyidval,
        "pageNumber": pageNum,
        "pageSize": pageSize
      }
    
    var accessTokenIn=sessionStorage.getItem("accToken");
    // const userrequestOptions = {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': "Bearer "+accessTokenIn
    //   },
    //   body: JSON.stringify(userrequestObject)
    // };
    services.getUsersByRole(userrequestObject).then((res) => {
        //console.log(res.data.data);
        setProjectUsersListInfo(res.data);
        var serviceresponse = res.data;

        var dummArray_users = [];
        if(!isEmpty(res.data)){
            var dummArray_users = [{ "label": "All", "value": "all" }];
            for (var i = 0; i < res.data.length; i++) {
                dummArray_users.push({ "label": res.data[i].first_name+' '+res.data[i].last_name, "value": res.data[i].id })
            }
        }

        //dummArray_users = multiProjectUsers1.filter(item => item.label !== selected.label);

        setmultiProjectUsers(dummArray_users);
        setmultiProjectUsers1(dummArray_users)
    });
  }


const [refcompanyidforclient, setrefcompanyidforclient] = useState(null);
  function getClientInfo(clientid) {
    services.getClientInfoById(clientid).then((res) => {
        if(res){
            if(res.data.data !== null){
                var refcompanyid = res.data.data.company_id;
                setrefcompanyidforclient(refcompanyid);
                //load managers
                var usersResponse = '';
                var dummArray_users = [];
                const requestObject =
                {
                    "roleId": ["2"],
                    "status": '1',
                    "companyId": refcompanyid,
                    "pageNumber": pageNum,
                    "pageSize": pageSize
                  }
                
                var accessTokenIn=sessionStorage.getItem("accToken");
                // const requestOptions = {
                //   method: 'POST',
                //   headers: {
                //     'Content-Type': 'application/json',
                //     'Authorization': "Bearer "+accessTokenIn
                //   },
                //   body: JSON.stringify(requestObject)
                // };
                services.getUsersByRole(requestObject).then((res) => {
                    setProjectManagerListInfo(res.data);
                    var serviceresponse = res.data;
                    usersResponse = serviceresponse;
                    var dummArray = [];
                    var dummArray = [{ "label": "All", "value": "all" }];
                    if(!isEmpty(res.data)){
                        for (var i = 0; i < res.data.length; i++) {
                            dummArray.push({ "label": res.data[i].first_name+' '+res.data[i].last_name, "value": res.data[i].id });

                            //dummArray_users.push({ "label": res.data.data[i].first_name+' '+res.data.data[i].last_name, "value": res.data.data[i].id });
                        }
                    }

                    setmultiProjectManagers(dummArray);
                });

                
                var dummArray_default_manager = [];
                if(!isEmpty(editProject)){
                
                services.getProjectManagersByProjectId(editProject, '','1','', '').then((res) => {
                    
                    if(res){
                        //var dummArray_default_manager = [];
                        var dummArray_ids_manager = [];
                        var managerResult = res.data.data;
                        if(!isEmpty(managerResult)){
                            for (var i = 0; i < managerResult.length; i++) {
                                if(managerResult[i].project_manager_status === 'assigned'){
                                    dummArray_default_manager.push({"label": managerResult[i].manager.first_name+' '+managerResult[i].manager.last_name, "value": managerResult[i].manager.id});
                                    dummArray_ids_manager.push(managerResult[i].manager.id);
                                } 
                            }
                        }
            
                        setManagersRequestArray(dummArray_ids_manager);
                        setoptionManagersSelected(dummArray_default_manager);
                        
                    }
                });
                }

                //load users
                const userrequestObject =
                {
                    //"roleId": ["2","3"],
                    "roleId": ["3"],
                    "status": '1',
                    "companyId": refcompanyid,
                    "pageNumber": pageNum,
                    "pageSize": pageSize
                  }
                
                var accessTokenIn=sessionStorage.getItem("accToken");
                // const userrequestOptions = {
                //   method: 'POST',
                //   headers: {
                //     'Content-Type': 'application/json',
                //     'Authorization': "Bearer "+accessTokenIn
                //   },
                //   body: JSON.stringify(userrequestObject)
                // };
                services.getUsersByRole(userrequestObject).then((res) => {
                    //console.log(res.data.data);
                    setProjectUsersListInfo(res.data);
                    var serviceresponse = res.data;

                    var dummArray_users = [];
                    if(!isEmpty(res.data)){
                        var dummArray_users = [{ "label": "All", "value": "all" }];
                        for (var i = 0; i < res.data.length; i++) {
                            dummArray_users.push({ "label": res.data[i].first_name+' '+res.data[i].last_name, "value": res.data[i].id })
                        }
                    }

                    var updatedArray2 = dummArray_users;
                    if(!isEmpty(dummArray_default_manager)){
                        updatedArray2 = dummArray_users.filter(item => item.label !== dummArray_default_manager[0].label);
                    }
    
                    setmultiProjectUsers(updatedArray2);
                    setmultiProjectUsers1(updatedArray2);
                });

                if(!isEmpty(editProject)){
                    services.getProjectUsersByProjectId(editProject, '', 1, '1500', '', 1,'',1, 'ASC', 'client_name').then((res) => {
                        if(res.data !== null){
                            var dummArray_default = [];
                            var dummArray_ids = [];
                            var userResult = res.data.data;
                            if(!isEmpty(userResult)){
                                for (var i = 0; i < userResult.length; i++) {
                                    if(!isEmpty(userResult[i].user) && (userResult[i].project_user_status === 'assigned')){
                                        dummArray_default.push({"label": userResult[i].user.first_name+' '+userResult[i].user.last_name, "value": userResult[i].user.id});
                                        dummArray_ids.push(userResult[i].user.id.toString());
                                    }
                                }
                            }

                            var updatedArray3 = dummArray_default;
                            if(!isEmpty(dummArray_default_manager)){
                                updatedArray3 = dummArray_default.filter(item => item.label !== dummArray_default_manager[0].label);
                            }
                            
                            setUsersRequestArray(dummArray_ids);
                            setoptionUsersSelected(updatedArray3);
                        }
                    });
                    
                }
            }
        }
        
    });
}

function getProjectManagersByProjectId(projectid, managerid, projstat){
    services.getProjectManagersByProjectId(projectid, managerid, projstat,'','').then((res) => {
        
        if(res){
            var dummArray_default = [];
            var dummArray_ids = [];
            var managerResult = res.data.data;
            if(!isEmpty(managerResult)){
                for (var i = 0; i < managerResult.length; i++) {
                    if(managerResult[i].project_manager_status === 'assigned'){
                        dummArray_default.push({"label": managerResult[i].manager.first_name+' '+managerResult[i].manager.last_name, "value": managerResult[i].manager.id});
                        dummArray_ids.push(managerResult[i].manager.id);
                    }
                }
            }

            setManagersRequestArray(dummArray_ids);
            setoptionManagersSelected(dummArray_default);
            
        }
    });
}

function getProjectUsersByProjectId(projectid, userid){
    services.getProjectUsersByProjectId(projectid, userid, 1, '1500', '', '', '',1, 'ASC', 'client_name').then((res) => {
        if(res.data !== null){
            var dummArray_default = [];
            var dummArray_ids = [];
            var UserResult = res.data.data;
            if(!isEmpty(UserResult)){
                for (var i = 0; i < UserResult.length; i++) {
                    if(!isEmpty(UserResult[i].user)){
                        dummArray_default.push({"label": UserResult[i].user.first_name+' '+UserResult[i].user.last_name, "value": UserResult[i].user.id});
                        dummArray_ids.push(UserResult[i].user.id.toString());
                    }
                }
            }
            var updatedArray2 = dummArray_default;
            updatedArray2 = dummArray_default.filter(item => item.label !== optionManagersSelected.label);
            setUsersRequestArray(dummArray_ids);
            setoptionUsersSelected(updatedArray2);
        }
    });
}

const [managersRequestArray, setManagersRequestArray] = useState([]);
const [optionManagersSelected, setoptionManagersSelected] = useState([]);
const handleChangeForManager_prev = (selected) => {
    var dummArray = [];
    var dummArray_label = [];
    var updatedArray2 = multiProjectUsers1;
    console.log(multiProjectUsers1);
    console.log(selected.length);
    console.log(selected);
    for (var i = 0; i < selected.length; i++) {
        //alert(selected[i].label.toString());
        dummArray.push(selected[i].value.toString());
        dummArray_label.push(selected[i].label.toString());


        updatedArray2 = multiProjectUsers1.filter(item => item.label !== selected[i].label);
        console.log(updatedArray2);
    } 

    setmultiProjectUsers(updatedArray2);
    setManagersRequestArray(dummArray);
    setoptionManagersSelected(selected);
};

const handleChangeForManager = (selected) => {
    var dummArray = [];
    var dummArray_label = [];
    var updatedArray3 = multiProjectManagers;

    if (selected.some(option => option.value === 'all')) {
        // If "All" is selected, set all options as selected
        setoptionManagersSelected(multiProjectManagers);
    } else {
        // If "All" is not selected, update with selected options
        setoptionManagersSelected(selected);
    }

    var allIndex = '';
    for (var i = 0; i < selected.length; i++) {
        if (selected[i].label === 'All') {
            allIndex = i;
        }
    }

    if (allIndex !== '') {
        if (allIndex === 0) {
            selected.splice(allIndex, 1);
            for (var i = 0; i < selected.length; i++) {
                dummArray.push(selected[i].value.toString());
            }
            setManagersRequestArray(dummArray);
        } else {
            var dummArray_vals = projectManagerListInfo.map(company => company.id);
            dummArray = projectManagerListInfo.map(project => ({
                label: project.first_name+' '+project.last_name,
                value: project.id
            }));
            setManagersRequestArray(dummArray_vals);
            // setoptionCompanyFilterSelected(dummArray);
        }
    } else {
        for (var i = 0; i < selected.length; i++) {
            dummArray.push(selected[i].value.toString());
        }
        setManagersRequestArray(dummArray);
        //setoptionCompanyFilterSelected(selected);
    }
/*
    for (var i = 0; i < selected.length; i++) {
        dummArray.push(selected[i].value.toString());
        dummArray_label.push(selected[i].label.toString());

       // updatedArray3 = multiProjectManagers.filter(item => item.label !== selected[i].label);
    }

    // setmultiProjectManagers(updatedArray3);
    setManagersRequestArray(dummArray);  

    setoptionManagersSelected(selected);*/

    // if (selected) {
    //     setManagersRequestArray(prev => (prev && prev.value === selected.value ? null : prev));
    //   }
};

const [usersRequestArray, setUsersRequestArray] = useState([]);
const [optionUsersSelected, setoptionUsersSelected] = useState([]);
const handleChangeForUser = (selected) => {
    var dummArray = [];
    var dummArray_label = [];
    var updatedArray3 = multiProjectManagers;

    if (selected.some(option => option.value === 'all')) {
        // If "All" is selected, set all options as selected
        setoptionUsersSelected(multiProjectUsers);
    } else {
        // If "All" is not selected, update with selected options
        setoptionUsersSelected(selected);
    }

    var allIndex = '';
    for (var i = 0; i < selected.length; i++) {
        if (selected[i].label === 'All') {
            allIndex = i;
        }
    }

    if (allIndex !== '') {
        if (allIndex === 0) {
            selected.splice(allIndex, 1);
            for (var i = 0; i < selected.length; i++) {
                dummArray.push(selected[i].value.toString());
                updatedArray3 = multiProjectManagers.filter(item => item.label !== selected[i].label);
            }

            setmultiProjectManagers(updatedArray3);
            setUsersRequestArray(dummArray);
        } else {
            var dummArray_vals = projectUsersListInfo.map(company => company.id);
            dummArray = projectUsersListInfo.map(users => ({
                label: users.first_name+' '+users.last_name,
                value: users.id
            }));

            for (var i = 0; i < dummArray.length; i++) {
                updatedArray3 = multiProjectManagers.filter(item => item.label !== dummArray[i].label);
            }
            setmultiProjectManagers(updatedArray3);

            setUsersRequestArray(dummArray_vals);
            // setoptionCompanyFilterSelected(dummArray);
        }
    } else {
        for (var i = 0; i < selected.length; i++) {
            dummArray.push(selected[i].value.toString());
            updatedArray3 = multiProjectManagers.filter(item => item.label !== selected[i].label);
        }
        setmultiProjectManagers(updatedArray3);
        setUsersRequestArray(dummArray);
        //setoptionCompanyFilterSelected(selected);
    }
/*
    for (var i = 0; i < selected.length; i++) {
        dummArray.push(selected[i].value.toString());
        dummArray_label.push(selected[i].label.toString());

        updatedArray3 = multiProjectManagers.filter(item => item.label !== selected[i].label);
    }

    setmultiProjectManagers(updatedArray3);
    setUsersRequestArray(dummArray);

    setoptionUsersSelected(selected); */

    if (selected) {
        setManagersRequestArray(prev => (prev && prev.value === selected.value ? null : prev));
      }
};

const [showValidation, setShowValidation] = useState(0);
const submitValue=()=>
    {

        if(!isEmpty(editProject)){
            //Edit form
            var validation = 0;
            if(isEmpty($('#editproject_name').val())){
                validation = 1;
                setEditProjectNamevalidation(1);
            } else {
                setEditProjectNamevalidation(0);
            }

            if(isEmpty($('#editclient_list').val())){
                validation = 1;
                setEditClientNamevalidation(1);
            } else {
                setEditClientNamevalidation(0);
            }

            if(validation === 1){
                setShowValidation(1);
                $('#editvalidationMessage').show();
                return false;
            } else {
                setShowValidation(0);
                $('#loadwrapper').show();
                setShowLoader(1);
                if(!isEmpty($('#editproject_time').val())){
                    var editbudgettime = $('#editproject_time').val()+':00';
                } else {
                    var editbudgettime = '00000:00';
                }

                if(!isEmpty($('#project_startdate').val()) && (!isEmpty($('#project_enddate').val()))){
                    var requestObject =
                    {
                            "name": $('#editproject_name').val(),
                            "code": $('#editproject_code').val(),
                            "project_start_date": $('#editproject_startdate').val(),
                            "project_end_date": $('#editproject_enddate').val(),
                            "budget_time": editbudgettime,
                            "client_id": $('#editclient_list').val(),
                            "project_status": myProjectStatus

                    }
                }
                if(!isEmpty($('#project_startdate').val()) && isEmpty($('#project_enddate').val())){
                        var requestObject =
                        {
                            "name": $('#editproject_name').val(),
                            "code": $('#editproject_code').val(),
                            "project_start_date": $('#editproject_startdate').val(),
                            "budget_time": editbudgettime,
                            "client_id": $('#editclient_list').val(),
                            "project_status": myProjectStatus
                        }
                    }
                if(isEmpty($('#project_startdate').val()) && !isEmpty($('#project_enddate').val())){
                        var requestObject =
                        {
                            "name": $('#editproject_name').val(),
                            "code": $('#editproject_code').val(),
                            "project_end_date": $('#editproject_enddate').val(),
                            "budget_time": editbudgettime,
                            "client_id": $('#editclient_list').val(),
                            "project_status": myProjectStatus
                        }
                    }
                if(isEmpty($('#project_startdate').val()) && isEmpty($('#project_enddate').val())){
                        var requestObject =
                        {
                            "name": $('#editproject_name').val(),
                            "code": $('#editproject_code').val(),
                            "budget_time": editbudgettime,
                            "client_id": $('#editclient_list').val(),
                            "project_status": myProjectStatus
                        }
                    }
                    //direct update

                    var accessTokenIn=sessionStorage.getItem("accToken");
                    // const requestOptions = {
                    //     method: 'PUT',
                    //     headers: {
                    //     'Content-Type': 'application/json',
                    //     'Authorization': "Bearer "+accessTokenIn
                    //     },
                    //     body: JSON.stringify(requestObject)
                    // };

                    services.updateProjectById(requestObject, editProject).then((res) => {
                        if(res.type === 'success'){

                            setIsOpen(false);
                            $("#entryform").trigger("reset");
                            $(".loadwrapper").hide();
                            createManagerUsers(editProject);

                            // setTimeout(function() { $('#closemodal').click();
                            // window.location.href='/project-management?tab=project'; }, 1000);
                        }
                    });
            }
        }
        else {
            //add form
            var validation = 0;
            if(isEmpty($('#project_name').val())){
                validation = 1;
                setProjectNamevalidation(1);
            } else {
                setProjectNamevalidation(0);
            }

            if(newClient === 0){
                if(isEmpty($('#client_list').val())){
                    validation = 1;
                    setClientNamevalidation(1);
                } else {
                    setClientNamevalidation(0);
                }
            } else {
                if(isEmpty($('#newclient_name').val())){
                    validation = 1;
                    setNewclientvalidation(1);
                } else {
                    setNewclientvalidation(0);
                }

                if(isEmpty($('#newclient_company').val())){
                    validation = 1;
                    setNewclientcompanyvalidation(1);
                } else {
                    setNewclientcompanyvalidation(0);
                }
            }


            if(validation === 1){
                setShowValidation(1);
                $('#validationMessage').show();
                return false;
            } else {
                setShowValidation(0);
                //$('#loadwrapper').show();
                setShowLoader(1);
                var myprojectclientidforadd = '';
                if(newClient === 0){
                    myprojectclientidforadd = $('#client_list').val();
                    createProject(myprojectclientidforadd);
                } else {
                    const requestObject =
                    {
                        "client_name": $('#newclient_name').val(),
                        "address1": null,
                        "city": null,
                        "state": null,
                        "zip": null,
                        "company_id": $('#newclient_company').val(),
                        "status": 1
                    }
                    console.log(requestObject); //return false;
                    var accessTokenIn=sessionStorage.getItem("accToken");
                    // const requestOptions = {
                    // method: 'POST',
                    // headers: {
                    //     'Content-Type': 'application/json',
                    //     'Authorization': "Bearer "+accessTokenIn
                    // },
                    // body: JSON.stringify(requestObject)
                    // };

                    $(".load-wrap").show();
                    services.createClient(requestObject).then((res) => {
                        console.log(res); //return false;
                        if(res.type === 'success'){
                            myprojectclientidforadd = res.data.id;
                            createProject(res.data.id);
                        }
                        if(res.type === 'error'){
                            $("#entryform").trigger("reset");
                            setIsOpen(false);
                            $("#failuresuccessMessageProject").html(res.message);
                            $(".loadwrapper").hide();
                            setShowLoader(0);
                            $("#failuresuccessMessageProject").fadeTo(2000, 500).slideUp(500, function () {
                                $("#failuresuccessMessageProject").slideUp(500);
                            });
                            setTimeout(function() { $('#closemodal').click();
                            window.location.href='/manageprojects';
                            }, 1000);
                        }
                    });
                }
            }
        }

    }

    function createProject(projectclientid) {
        if(!isEmpty($('#project_time').val())){
            var projectbudgettime = $('#project_time').val()+':00';
        } else {
            var projectbudgettime = '0000:00';
        }

        if(!isEmpty($('#project_startdate').val()) && (!isEmpty($('#project_enddate').val()))){
            var requestObject =
            {
                "name": $('#project_name').val(),
                "code": $('#project_code').val(),
                "project_start_date": $('#project_startdate').val(),
                "project_end_date": $('#project_enddate').val(),
                "budget_time": projectbudgettime,
                "client_id": projectclientid,
                "project_status":1
            }
        }
        if(!isEmpty($('#project_startdate').val()) && isEmpty($('#project_enddate').val())){
                var requestObject =
                {
                    "name": $('#project_name').val(),
                    "code": $('#project_code').val(),
                    "project_start_date": $('#project_startdate').val(),
                    "budget_time": projectbudgettime,
                    "client_id": projectclientid,
                    "project_status":1
                }
            }
        if(isEmpty($('#project_startdate').val()) && !isEmpty($('#project_enddate').val())){
                var requestObject =
                {
                    "name": $('#project_name').val(),
                    "code": $('#project_code').val(),
                    "project_end_date": $('#project_enddate').val(),
                    "budget_time": projectbudgettime,
                    "client_id": projectclientid,
                    "project_status":1
                }
            }
        if(isEmpty($('#project_startdate').val()) && isEmpty($('#project_enddate').val())){
                var requestObject =
                {
                    "name": $('#project_name').val(),
                    "code": $('#project_code').val(),
                    "budget_time": projectbudgettime,
                    "client_id": projectclientid,
                    "project_status":1
                }
            }


        console.log(requestObject); //return false;
        var accessTokenIn=sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': "Bearer "+accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };

        //$(".load-wrap").show();
        services.createProject(requestObject).then((res) => {
            
            if(res.type === 'success'){
                var newprojectid = res.data.id;
                createManagerUsers(newprojectid);
            }
            if(res.type === 'error'){
                $("#entryform").trigger("reset");
                setIsOpen(false);
                $("#failuresuccessMessageProject").html(res.message);
                $(".loadwrapper").hide();
                setShowLoader(0);
                $("#failuresuccessMessageProject").fadeTo(2000, 500).slideUp(500, function () {
                    $("#failuresuccessMessageProject").slideUp(500);
                });
                setTimeout(function() { $('#closemodal').click();
                window.location.href='/manageprojects';
                }, 1000);
            }
        });
    }

    function createManagerUsers (mynewprojectid) {

        if (Array.isArray(optionManagersSelected)) {
            var myManagers =  optionManagersSelected; // It's already an array
        } else if (optionManagersSelected && typeof optionManagersSelected === 'object') {
            var myManagers =  [optionManagersSelected]; // Wrap the object in an array
        }

        

        //if(!isEmpty(myManagers)){
            var mymanagerids = [];
                for(var i=0; i< myManagers.length; i++){
                    if(myManagers[i].value !== 'all'){
                        mymanagerids.push(myManagers[i].value);
                    }
                }

                const requestObject =
                {
                    "project_id": mynewprojectid,
                    "manager_id": mymanagerids
                }

                //console.log(requestObject); return false;
                var accessTokenIn=sessionStorage.getItem("accToken");
                // const requestOptions = {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type': 'application/json',
                //         'Authorization': "Bearer "+accessTokenIn
                //     },
                //     body: JSON.stringify(requestObject)
                // };

                $(".load-wrap").show();
                services.createProjectManager(requestObject).then((res) => {
                    
                    if(res.type === 'success'){
                        createUsersAssigned(mynewprojectid);
                    }
                    if(res.type === 'error'){
                        $("#entryform").trigger("reset");
                        setIsOpen(false);
                        $("#failuresuccessMessageProject").html(res.message);
                        $(".loadwrapper").hide();
                        setShowLoader(0);
                        $("#failuresuccessMessageProject").fadeTo(2000, 500).slideUp(500, function () {
                            $("#failuresuccessMessageProject").slideUp(500);
                        });
                        setTimeout(function() { $('#closemodal').click();
                        window.location.href='/manageprojects';
                        }, 1000);
                    }
                });

           /* } else {
                createUsersAssigned(mynewprojectid);
            } */

    }

    const createUsersAssigned = (mynewprojectid) => {
        //if(optionUsersSelected.length > 0){
            var userids = [];
            //console.log()
            for(var m=0; m<optionUsersSelected.length; m++){
                if(optionUsersSelected[m].value !== 'all'){
                    userids.push(optionUsersSelected[m].value);
                }
            }
            const requestObject =
            {
                "project_id": mynewprojectid,
                "user_id": userids
            }

            //console.log(requestObject); //return false;
            var accessTokenIn=sessionStorage.getItem("accToken");
            // const requestOptions = {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //         'Authorization': "Bearer "+accessTokenIn
            //     },
            //     body: JSON.stringify(requestObject)
            // };

            $(".load-wrap").show();
            services.createProjectUsers(requestObject).then((res) => {
                if(res.type === 'success'){
                    $("#entryform").trigger("reset");
                    setIsOpen(false);
                    $(".loadwrapper").hide();
                    setShowLoader(0);
                    if(!isEmpty(editProject)){
                        $("#updatesuccessMessageProject").fadeTo(2000, 500).slideUp(500, function () {
                            $("#updatesuccessMessageProject").slideUp(500);
                        });
                    } else {
                        $("#successMessageProject").fadeTo(2000, 500).slideUp(500, function () {
                            $("#successMessageProject").slideUp(500);
                        });
                    }

                    setTimeout(function() { $('#closemodal').click();
                    window.location.href='/manageprojects';
                    }, 1000);
                }
                if(res.type === 'error'){
                    $("#entryform").trigger("reset");
                    setIsOpen(false);
                    $("#failuresuccessMessageProject").html(res.message);
                    $(".loadwrapper").hide(); setShowLoader(0);
                    $("#failuresuccessMessageProject").fadeTo(2000, 500).slideUp(500, function () {
                        $("#failuresuccessMessageProject").slideUp(500);
                    });
                    setTimeout(function() { $('#closemodal').click();
                    window.location.href='/manageprojects';
                    }, 1000);
                }
            });
    }

    const [inputDurationValue, setInputDurationValue] = useState('');
    const [formattedTime, setFormattedTime] = useState('');
    const handleDurationInputChange = (event) => {
        const value = event.target.value;

        // Remove non-numeric characters and restrict length to 4
        const cleanedValue = value.replace(/\D/g, '').slice(0, 4);

        setInputDurationValue(cleanedValue);
        setFormattedTime(formatToHHMM(cleanedValue));
    };

    const formatToHHMM = (value) => {
        if (value === '') return '';

        if (value.length <= 2) {
          return value; // Return as is if only two digits or less
        }

        const time = value.replace(/[^0-9]/g, ''); // Remove non-numeric characters
        const hours = time.substring(0, 2).padStart(2, '0');
        const minutes = time.substring(2, 4).padStart(2, '0');
        //const seconds = time.substring(4, 6).padStart(2, '0');
        return `${hours}:${minutes}`;
    };

    const handleTimeInputBlurCustom = (event) => {
        let value = event.target.value;
        //alert(value);
        // Extract value using a regular expression for partial inputs
        const timeRegex = /^(\d{1,2})(?::(\d{1,2}))?$/;
        const match = timeRegex.exec(value);


        let hours = '00';
        let minutes = '00';

        if (match) {
          hours = match[1] || '00'; // Default to '00' if hours are missing
          minutes = match[2] || '00'; // Default to '00' if minutes are missing
        }

        // Corrected time value
        const correctedTime = `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;

        // Update the input field directly
        event.target.value = correctedTime;

        // Optionally update the state if using controlled input
        // setTime(correctedTime);
      };

      const [value, setValue] = useState('');

  const handleChange = (event) => {
    const inputValue = event.target.value;

    // Allow only numbers and up to 4 digits
    if (/^\d{0,4}$/.test(inputValue)) {
      setValue(inputValue);
    }
  };

  const onClickOutside = () => {
    // This will be called when the user clicks outside the calendar without selecting a date
    setStartDate(null); // or any other state update to close the calendar
  };

  const scrollToDropdown = () => {
    const dropdown = document.getElementById('assign-users-dropdown');
    dropdown.scrollIntoView({ behavior: 'smooth' });
  };
  
  const [inputProjectValue, setInputProjectValue] = useState('');

  const handleProjectNameChange = (event) => {
    const { value } = event.target;
    // Capitalize the first letter of each word
    const capitalizedValue = value
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    
      setInputProjectValue(capitalizedValue);
  };

  const handleProjectNameEditChange = (event) => {
    const { value } = event.target;
    // Capitalize the first letter of each word
    const capitalizedValue = value
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
    
      $('#editproject_name').val(capitalizedValue);
  };


  return(
  <>
      <div class="modal fade" id="exampleModalcp" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
        <form id="entryform" onSubmit={handleSubmit(submitValue)}>
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">{getTitleHead()}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                {showLoader === 1 ?
    <div class="loadwrapperpopup">
        <div class="clock"><div class="circle"></div></div>
    </div>
    : '' }

                <div id="addProjectBody" class="modal-body">

                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Project*</label>
                        <input type="text" class={`form-control ${projectNamevalidation === 1 ? 'errorinp' : ''}`} id="project_name" name="project_name" placeholder="Project Name" value={inputProjectValue} onChange={handleProjectNameChange}/>
                    </div>

                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Project Code </label>
                        <input type="text" class="form-control" id="project_code" name="project_code" placeholder="Project Code"/>
                    </div>

                    <div class="form-group mb-2">
                        <div class="d-flex justify-content-between">
                            <div className="w-100">
                                <label for="recipient-name" class="col-form-label">Start & End dates 
                                </label>
                                <div class="justify-content-start gap-3 date-picker-container-wrap">
                                    <div className="date-picker-container" ref={calendarRef}>
                                        <input type="text" id="project_startdate" name="project_startdate" placeholder="Start Date" value={startdate} onClick={toggleStartDateCalendar} readOnly/>
                                        {isStartCalendarOpen === 1 && (
                                            <Calendar onClickOutside={onClickOutside} onChange={onChangeStartDate} value={startdate} className="calendar" />
                                        )}
                                    </div>

                                    <div className="date-picker-container" ref={calendarRef2}>
                                        <input type="text" value={enddate} id="project_enddate" placeholder="End Date" name="project_enddate" onClick={toggleEndDateCalendar} readOnly/>
                                        {isEndCalendarOpen === 1 && (
                                            <Calendar onClickOutside={onClickOutside} onChange={onChangeEndDate} value={enddate} className="calendar" />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group mb-4">
                        <label for="recipient-name" class="col-form-label position-relative">Budget Time
                            <span class="hourstag">HOURS</span>
                        </label>
                        <input type="text" id="project_time" name="project_time" className={`form-control`} maxLength='5' style={{"max-width":"142px"}} value={value} onChange={handleChange}/>
                    </div>

                    <div style={{"background-color": "#e9e9e9"}} class="p-3 d-grid">
                    {newClient === 0 ?
                    <>
                    <div class="form-group mb-2 d-grid" id="existing_client">
                        <label for="recipient-name" class="col-form-label">Client*</label>
                        <select class={`form-select ${clientNamevalidation === 1 ? 'errorinp' : ''}`} id="client_list" name="client_list" onChange={handleClientChange} aria-label="Default select example">
                            <option value="">Select Client</option>
                            {projectClientListInfo.map((clientData, index) => (
                                <option value={clientData.id}>{clientData.client_name}</option>
                            ))}
                        </select>
                    </div>

                    {/* <span class="text-center mb-2">---- or ----</span> */}
                    </>
                    : ''
                    }

                    {/* <a href="#" class="text-center" onClick={newClientHideandShow}> Create New Client</a> */}

                    <div id="new_client">
                        <div style={{ textAlign: "right" }}><a href="#" onClick={closeNewClient}><img src="images/close.svg" alt="" /></a></div>


                        <div class="form-group mb-2">
                            <label for="recipient-name" class="col-form-label">Client*</label>
                            <input type="name" id="newclient_name" name="newclient_name" class={`form-control ${newclientvalidation === 1 ? 'errorinp' : ''}`} placeholder="Client Name"/>
                        </div>


                        <div class="form-group mb-2">
                            <label for="recipient-name" class="col-form-label text-left">Company*</label>
                            <select class={`form-select ${newclientcompanyvalidation === 1 ? 'errorinp' : ''}`} id="newclient_company" name="newclient_company" aria-label="Default select example" onChange={getUsersWithCompany} disabled={isManager} value={myCompanyVal}>
                                <option value="" selected>Select Company</option>
                                {projectCompanyList.map((companydata, companyindex) => (
                                    <option value={companydata.id} >{companydata.company_name}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                </div>


                    <div class="form-group mb-2 d-grid">
                        <label for="recipient-name" class="col-form-label">Managers</label>

<ReactSelect
                                class="form-select"
                                options={multiProjectManagers}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                    Option
                                }}
                                onChange={handleChangeForManager}
                                allowSelectAll={false}
                                value={optionManagersSelected}
                                defaultValue={['1']}
                            />
                    </div>


                    <div class="form-group mb-2 d-grid">
                        <label for="recipient-name" class="col-form-label">Users</label>

                        <ReactSelect
                                class="form-select"
                                options={multiProjectUsers}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                    Option
                                }}
                                onChange={handleChangeForUser}
                                allowSelectAll={false}
                                value={optionUsersSelected}
                                defaultValue={['1']}
                            />
                    </div>
                    {showValidation === 1 ?
                    <div className="validationglobal p-2" id="validationMessage">
                        <div className="validateitem">
                            {/* <span className="errimg"><img class="icon" src="images/error.svg" alt=""/></span> */}
                            <p>Please fill out all the required fields!</p>
                        </div>
                    </div>
                    : '' }
                </div>

                <div id="editProjectBody" class="modal-body">

                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Project*</label>
                        <input type="text" class={`form-control ${editprojectNamevalidation === 1 ? 'errorinp' : ''}`} id="editproject_name" name="editproject_name" placeholder="Project Name" defaultValue={projectName} onChange={handleProjectNameEditChange}/>
                    </div>

                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label">Project Code </label>
                        <input type="text" class="form-control" id="editproject_code" name="editproject_code" placeholder="Project Code" defaultValue={showProjectInfo.code}/>
                    </div>

                    <div class="form-group mb-2">
                        <div class="d-flex justify-content-between">
                            <div className="w-100">
                                <label for="recipient-name" class="col-form-label">Start & End dates
                                </label>
                                <div class="justify-content-start gap-1 date-picker-container-wrap">
                                    <div className="date-picker-container" ref={calendarRef3}>
                                        <input className="cal-icon" type="text" id="editproject_startdate" name="editproject_startdate" value={editStartdate} onClick={toggleEditStartDateCalendar} readOnly/>
                                        {isEditStartCalendarOpen === 1 && (
                                            <Calendar onChange={onChangeEditStartDate} value={editStartdate} className="calendar" />
                                        )}
                                    </div>

                                    <div className="date-picker-container" ref={calendarRef4}>
                                        <input type="text" value={editEnddate} id="editproject_enddate" name="editproject_enddate" onClick={toggleEditEndDateCalendar} readOnly/>
                                        {isEditEndCalendarOpen === 1 && (
                                            <Calendar onChange={onChangeEditEndDate} value={editEnddate} className="calendar" />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group mb-2">
                        <label for="recipient-name" class="col-form-label position-relative">Budget Time
                            <span class="hourstag">HOURS</span>
                        </label>

                        <input type="text" id="editproject_time" name="editproject_time" className={`form-control`} maxLength='4'  style={{"max-width":"142px"}}  value={value} onChange={handleChange}/>
                    </div>

                    <div style={{"background-color": "#e9e9e9"}} class="p-3 d-grid">
                    <div class="form-group mb-2 d-grid">
                        <label for="recipient-name" class="col-form-label">Client*</label>
                        <select class={`form-select ${editclientNamevalidation === 1 ? 'errorinp' : ''}`} id="editclient_list" name="editclient_list" aria-label="Default select example" value={projectClientid} onChange={handleClientChange} disabled>
                            <option value="">Select Client</option>
                            {projectClientListInfo.map((clientData, index) => (
                                <option value={clientData.id}>{clientData.client_name}</option>
                            ))}
                        </select>
                    </div>

                </div>


                    <div class="form-group mb-2 d-grid">
                        <label for="recipient-name" class="col-form-label">Managers</label>

                        <ReactSelect
                                //class="form-select"
                                options={multiProjectManagers}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                    Option
                                }}
                                onChange={handleChangeForManager}
                                allowSelectAll={false}
                                value={optionManagersSelected}
                                defaultValue={['1']}
                                //isDisabled={loggedUser.toLowerCase() !== 'admin'} // Disable if not an admin
                            />
                    </div>


                    <div class="form-group mb-2 d-grid">
                        <label for="recipient-name" class="col-form-label">Users</label>

                        <ReactSelect
                                //class="form-select"
                                id="assign-users-dropdown"
                                onFocus={scrollToDropdown}
                                options={multiProjectUsers}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                    Option
                                }}
                                onChange={handleChangeForUser}
                                allowSelectAll={false}
                                value={optionUsersSelected}
                                defaultValue={['1']}
                            />

                    </div>
{showValidation === 1 ?
                    <div className="validationglobal p-2" id="editvalidationMessage">
                        <div className="validateitem">
                            {/* <span className="errimg"><img class="icon" src="images/error.svg" alt=""/></span> */}
                            <p>Please fill out all the required fields!</p>
                        </div>
                    </div>
                    : '' }
                </div>

                <div class="modal-footer">
                    <button type="submit" class="btn btn-primary">{(!isEmpty(editProject)) ? 'Update'  : 'Add Project'  }</button>
                    <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">Cancel</button>

                </div>
                <div class="px-3">
                    <div id="successMessageProject" class="alert alert-success" role="alert">Project Created successfully!</div>
                    <div id="updatesuccessMessageProject" class="alert alert-success" role="alert">Project Updated successfully!</div>
                    <div id="failuresuccessMessageProject" class="alert alert-danger" role="alert"></div>
                </div>
            </div>
        </form>

        
    
        </div>
    </div>
    
  </>
)};

AddProject.propTypes = {};

AddProject.defaultProps = {};

export default AddProject;

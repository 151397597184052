import React,{history,useEffect, useState, useMemo } from "react";
import services from "../../services/services";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DateObject from "react-date-object";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $ from 'jquery';

const TaskDescription = ({project, client, task, isbillable, result}) => {
    const useridfortask = 4;

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all",mode: "onBlur"
    });




    useEffect(() => {
        console.log(result);
    },[]);

    const handleDisplayTimeDuration = (datetoformat) => {
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        var displaydate = new DateObject(datetoformat);
        var displaynewdate = new Date(datetoformat);
        const dayIndex = displaynewdate.getDay();

        var date = new Date(datetoformat + 'T00:00:00Z'); // Append 'T00:00:00Z' to ensure it's treated as UTC

        // Array of day names
        var daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        // Get the day of the week in UTC
        var dayOfWeekIndex = date.getUTCDay(); // Use getUTCDay() for UTC
        var dayOfWeekName = daysOfWeek[dayOfWeekIndex];

        return dayOfWeekName+', '+displaydate.format("MMM DD");
     };

  return(
  <>
  <div class="modal fade" id="taskdes" tabindex="-1" aria-labelledby="taskdes" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="taskdes">{task}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="p-2">
                        <div class="d-flex gap-2 mb-3">
                            <span>{isbillable == 1 ? <img src="images/billed.png" width={20} alt=""/> : <img src="images/dollarred.svg" width={20} alt=""/> }</span>
                            {/* <img src="images/nonbill.png" alt=""/> */}
                            <span class="d-grid">
                                <h5 className="mb-0">{project}</h5>
                                <small>{client}</small>
                            </span>
                        </div>
                        {result.map((item, index) => (
                            <div>
                            {parseInt(item.duration) > 0 ?
                            <div class="tdescitem">
                                <div class="d-flex justify-content-between align-items-center border-bottom pb-2">
                                    <div class="date">
                                    <u> <b>{handleDisplayTimeDuration(item.day)}</b></u>


                                    </div>
                                    <div class="hours tdescitemposmall">
                                        <span class="hrtime">{item.duration.slice(0, -3)}</span>
                                        <span class="hrlabel">HOURS</span>
                                    </div>

                                </div>
                                <p className="pt-2 mb-0"> {item.time_entry_description} </p>
                              {/*  <p>{item.time_entry_description}</p> */}
                            </div>
                            :
                            ''
                            }
                            </div>

                        ))}
                        {/*{result.data.map((data, index) => (*/}

                        {/*}))}*/}

                    </div>
                </div>
               {/* <div class="modal-footer">
                    <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">Cancel</button>

                </div> */}
            </div>
        </div>
    </div>
  </>
)};

TaskDescription.propTypes = {};

TaskDescription.defaultProps = {};

export default TaskDescription;

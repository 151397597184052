// dateUtils.js
// export const timezone = 'America/New_York';
//export const timezone = 'Asia/Kolkata';
//export const timezone = 'Australia/Sydney';
export const timezone = 'UTC';
//export const timezone = 'Etc/UTC';

export const convertDateStringToGMT = (dateString) => {
  const options = {
    timeZone: 'UTC',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  const formatter = new Intl.DateTimeFormat('en-US', options);
  const date = new Date(dateString);
  const parts = formatter.formatToParts(date);
  const dateParts = {};
  parts.forEach(({ type, value }) => {
    dateParts[type] = value;
  });
  return new Date(
    `${dateParts.year}-${dateParts.month}-${dateParts.day}T${dateParts.hour}:${dateParts.minute}:${dateParts.second}Z`
  );
};

export const getCurrentDateInEST = () => {
  const options = {
    timeZone: timezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  const formatter = new Intl.DateTimeFormat('en-US', options);
  const parts = formatter.formatToParts(new Date());
  const dateParts = {};
  parts.forEach(({ type, value }) => {
    dateParts[type] = value;
  });
  return new Date(
    `${dateParts.year}-${dateParts.month}-${dateParts.day}T${dateParts.hour}:${dateParts.minute}:${dateParts.second}`
  );
};

export const getMonthStartDateInESTFormat = () => {
  const timezone = 'America/New_York'; // EST timezone

  // Get the current date
  const currentDate = new Date();

  // Create a date object for the first day of the current month
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();

  // Create a date object for the first day of the current month in UTC
  const firstDayOfMonthUTC = new Date(Date.UTC(year, month, 1));

  // Convert to the specified timezone and get the local time
  const options = {
    timeZone: timezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  // Format the UTC date to the target timezone
  const formatter = new Intl.DateTimeFormat('en-US', options);
  const parts = formatter.formatToParts(firstDayOfMonthUTC);
  const dateParts = {};

  parts.forEach(({ type, value }) => {
    dateParts[type] = value;
  });

  // Create a new date object using the parsed date parts
  const startDateInEST = new Date(
    `${dateParts.year}-${dateParts.month}-${dateParts.day}T00:00:00`
  );

  // Format the output to yyyy-mm-dd
  const formattedDate = `${startDateInEST.getFullYear()}-${String(startDateInEST.getMonth() + 1).padStart(2, '0')}-${String(startDateInEST.getDate()).padStart(2, '0')}`;

  console.log("month asked start:" + formattedDate);
  return formattedDate;
};

export const getMonthEndDateInESTFormat2 = () => {
  const timezone = 'America/New_York'; // EST timezone
  
  // Get the current date
  const currentDate = new Date();

  // Calculate the last day of the current month
  const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

  const options = {
    timeZone: timezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  const formatter = new Intl.DateTimeFormat('en-US', options);
  const parts = formatter.formatToParts(lastDayOfMonth);
  const dateParts = {};
  parts.forEach(({ type, value }) => {
    dateParts[type] = value;
  });
  
  // Return the last day of the current month in yyyy-mm-dd format
  return `${dateParts.year}-${dateParts.month}-${dateParts.day}`;
};

export const getMonthEndDateInESTFormat = () => {
  const timezone = 'America/New_York'; // EST timezone

  // Create a date object for the first day of the next month
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();

  // Create a date object for the first day of the next month
  const firstDayNextMonth = new Date(year, month + 1, 1);

  // Adjust this date to the EST timezone
  const lastDayOfMonth = new Date(firstDayNextMonth.getTime() - 1);

  const options = {
    timeZone: timezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  
  const formatter = new Intl.DateTimeFormat('en-US', options);
  const parts = formatter.formatToParts(lastDayOfMonth);
  const dateParts = {};
  
  parts.forEach(({ type, value }) => {
    dateParts[type] = value;
  });

  console.log("month asked:"+`${dateParts.year}-${dateParts.month}-${dateParts.day}`)
  // Return the last day of the current month in yyyy-mm-dd format
  return `${dateParts.year}-${dateParts.month}-${dateParts.day}`;
  
 
};

export const formatDateToEST = (date) => {
  const options = {
    timeZone: timezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  const formatter = new Intl.DateTimeFormat('en-US', options);
  const parts = formatter.formatToParts(date);
  const dateParts = {};
  parts.forEach(({ type, value }) => {
    dateParts[type] = value;
  });
  return new Date(
    `${dateParts.year}-${dateParts.month}-${dateParts.day}T${dateParts.hour}:${dateParts.minute}:${dateParts.second}`
  );
};

export const convertDateStringToEST = (dateString) => {
  const options = {
    timeZone: timezone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  const formatter = new Intl.DateTimeFormat('en-US', options);
  const date = new Date(dateString);
  const parts = formatter.formatToParts(date);
  const dateParts = {};
  parts.forEach(({ type, value }) => {
    dateParts[type] = value;
  });
  return new Date(
    `${dateParts.year}-${dateParts.month}-${dateParts.day}T${dateParts.hour}:${dateParts.minute}:${dateParts.second}`
  );
};

import React, { useEffect, useState } from "react";
import services from "../../services/services";
import DateObject from "react-date-object";
import TimesheetHeader from "./timesheet-header";
import { useTable, useSortBy } from 'react-table';

const ApprovedTimesheet = () => {
    //const useridval = 4;
    const [approvedList, setApprovedList] = useState([]);
    var loggedUserId = sessionStorage.getItem('userIdval');
    const [useridval, setuseridval] = useState(parseInt(loggedUserId));

    const [projectCurrentPage, setProjectCurrentPage] = useState(1);
    const [projectTotalPages, setProjectTotalPages] = useState(1);
    const pageSize = 10;
    const [showLoader, setShowLoader] = useState(0);
    const [sortApprovedListConfig, setSortApprovedListConfig] = useState({ key: 'Week', direction: 'DESC' });

   /*
    useEffect(() => {
        var timeSheetStatus1 = 'Approved';
        getTimesheetEntriesApproved(timeSheetStatus1);

        var loggedUserId = sessionStorage.getItem('userIdval');
        setuseridval(parseInt(loggedUserId));
    }, []);

   */

    useEffect(() => {
        //console.log('1');
        var timeSheetStatus = 'Approved';
        getTimesheetEntriesApproved(timeSheetStatus);
    }, [projectCurrentPage, sortApprovedListConfig]);

    function getTimesheetEntriesApproved(timeSheetStatus1) {
        setShowLoader(1);
        const requestObject =
        {
            "userIds": [useridval],
            "timeSheetStatuses": [timeSheetStatus1],
            "pageNumber": projectCurrentPage,
            "pageSize": pageSize,
            //"sortOrder" : 'DESC'
            "sortOrder": sortApprovedListConfig.direction,
        }
        console.log(requestObject);
        var accessTokenIn = sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Authorization': "Bearer " + accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };
        services.getTimeEntriesByStatus(requestObject).then((res) => {
            console.log(res);
            setApprovedList(res.data);
            setProjectTotalPages(res.totalNumberOfPages);
            setShowLoader(0);
        });
    }

    const getDateRange2 = (weekstart, weekend) => {
        var weekStartobj = new DateObject(weekstart);
        var weekEndobj = new DateObject(weekend);
        return weekStartobj.format("DD MMM YYYY") + ' - ' + weekEndobj.format("DD MMM YYYY");
    }

    const getTotalTimeApproved = (timesheetinfoindex) => {
        for (var i = 0; i < approvedList.length; i++) {
            var userinfodata = approvedList[timesheetinfoindex].users;
            //console.log(userinfodata);
            for (var j = 0; j < userinfodata.length; j++) {
                if (userinfodata[j].userId == useridval) {
                    var workedHours = userinfodata[j].workedHours;
                    return workedHours;
                    // const [hours, minutes] = workedHours.split('.').map(Number);

                    // // Pad single-digit hours and minutes with leading zero
                    // const paddedHours = hours.toString().padStart(2, '0');
                    // const paddedMinutes = minutes.toString().padStart(2, '0');
                    // return `${paddedHours}:${paddedMinutes}`;
                }
            }
        }
    }

    const showSubmittedWeekTimesheet = (mondaydate, sundaydate) => {
        sessionStorage.setItem('viewlistfor', mondaydate + '&' + sundaydate);
    }

    // Pagination Handlers for Projects
    const handleProjectPrevPage = () => {
        if (projectCurrentPage > 1) {
            setProjectCurrentPage(projectCurrentPage - 1);
        }
    };

    const handleProjectNextPage = () => {
        if (projectCurrentPage < projectTotalPages) {
            setProjectCurrentPage(projectCurrentPage + 1);
        }
    };

    const isEmpty = (value) => {
        return value == null || String(value).trim() === '';
    }

    const renderProjectPagination = () => {
        const pageNumbers = generatePageNumbers(projectCurrentPage, projectTotalPages);
        if(!isEmpty(pageNumbers)){
            return (
                <div className="pagination-controls justify-content-end">
                    <button onClick={handleProjectPrevPage} disabled={projectCurrentPage === 1}>
                        « Prev
                    </button>

                    {pageNumbers.map(page => (
                        <button
                            key={page}
                            onClick={() => setProjectCurrentPage(page)}
                            className={page === projectCurrentPage ? 'active' : ''}
                        >
                            {page}
                        </button>
                    ))}

                    <button onClick={handleProjectNextPage} disabled={projectCurrentPage === projectTotalPages}>
                        Next »
                    </button>
                </div>
            );
        }
    };


    // Helper function to generate page numbers
    const generatePageNumbers = (currentPage, totalPages) => {
        const maxPageButtons = 5;
        let startPage, endPage;

        if (totalPages <= maxPageButtons) {
            startPage = 1;
            endPage = totalPages;
        } else {
            const maxPagesBeforeCurrent = Math.floor(maxPageButtons / 2);
            const maxPagesAfterCurrent = Math.ceil(maxPageButtons / 2) - 1;

            if (currentPage <= maxPagesBeforeCurrent) {
                startPage = 1;
                endPage = maxPageButtons;
            } else if (currentPage + maxPagesAfterCurrent >= totalPages) {
                startPage = totalPages - maxPageButtons + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - maxPagesBeforeCurrent;
                endPage = currentPage + maxPagesAfterCurrent;
            }
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };

    const handleApprovedListSort = (columnKey) => {
        let direction = 'ASC';
        if (sortApprovedListConfig.key === columnKey && sortApprovedListConfig.direction === 'ASC') {
            direction = 'DESC';
        }
        setProjectCurrentPage(1);
        setSortApprovedListConfig({ key: columnKey, direction });
    }

    const columns2 = React.useMemo(() => [
        {
            Header: 'Week',
            accessor: 'week',  // Just a placeholder, we'll customize the Cell rendering
            width: 'auto',
            sortType: 'basic',  // Enable sorting for this column
            Cell: ({ row }) => (
                <div className="d-flex gap-2 align-items-center">
                    <a href="/approved-weekview" onClick={() => showSubmittedWeekTimesheet(row.original.weekStartDate, row.original.weekEndDate)}>
                        {getDateRange2(row.original.weekStartDate, row.original.weekEndDate)}
                    </a>
                </div>
            ),
        },
        {
            Header: 'Total Hours',
            accessor: 'totalHours',
            width: '150px',
            sortType: 'basic',  // Enable sorting for this column
            Cell: ({value,  row }) => {
                // Debug output
                //console.log('Row Index:', row.index);
                //console.log('Row Index:', row.original.users[0].workedHours);
                //console.log('Total Hours:', getTotalTimePendingForApproval(row.index));

                // Return the total hours or a fallback value
                //return <div>{getTotalTimePendingForApproval(row.index) || 'N/A'}</div>;
                return <div>{row.original.users[0].workedHours || 'N/A'}</div>;
            },
        },
        {
            Header: '',
            accessor: 'actions',
            width: '160px',
            sortType: 'basic',  // Enable sorting for this column
            Cell: ({ row }) => (
                <a href="/approved-weekview" onClick={() => showSubmittedWeekTimesheet(row.original.weekStartDate, row.original.weekEndDate)} className="btn btn-outline-primary scale85">
                    View Details
                </a>
            ),
        }
    ], []);

    const data = React.useMemo(() => approvedList, [approvedList]);

    // const {
    //     getTableProps,
    //     getTableBodyProps,
    //     headerGroups,
    //     rows,
    //     prepareRow
    // } = useTable(
    //     {
    //         columns,
    //         data
    //     },
    //     useSortBy
    // );

    const columns1 = React.useMemo(() => [
        {
            //Header: 'Week',
            Header: (
                <span onClick={() => handleApprovedListSort('Week')}>
                    Week{sortApprovedListConfig.key === 'Week' ? (sortApprovedListConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}
                </span>
            ),
            id:'week_id',
            accessor: 'weekStartDate', // Use weekStartDate for sorting
            width: 'auto',
            disableSortBy: true,
            sortType: (rowA, rowB) => {
                const dateA = new Date(rowA.original.weekStartDate);
                const dateB = new Date(rowB.original.weekStartDate);
                return dateA - dateB; // Sort based on weekStartDate
            },
            Cell: ({ row }) => (
                <div className="d-flex gap-2 align-items-center">
                    <a href="/approved-weekview" onClick={() => showSubmittedWeekTimesheet(row.original.weekStartDate, row.original.weekEndDate)}>
                        {getDateRange2(row.original.weekStartDate, row.original.weekEndDate)}
                    </a>
                </div>
            ),
        },
        {
            Header: 'Total Hours',
            accessor: (row) => row.users.reduce((total, user) => total + convertTimeStringToMinutes(user.workedHours), 0), // Accessor for total minutes
            width: '150px',
            //sortType: 'basic',  // Enable basic sorting for numeric values
            disableSortBy: true,
            Cell: ({ row }) => {
                // Calculate total hours for all users
                const totalMinutes = row.original.users.reduce((total, user) => total + convertTimeStringToMinutes(user.workedHours), 0);
                const formattedTotal = formatMinutesToTime(totalMinutes); // Convert minutes back to "HH:mm" format
                return <div>{formattedTotal || 'N/A'}</div>;
            },
        },
        {
            Header: '',
            accessor: 'actions',
            width: '160px',
            sortType: 'basic',  // Enable sorting for this column
            Cell: ({ row }) => (
                <a href="/approved-weekview" onClick={() => showSubmittedWeekTimesheet(row.original.weekStartDate, row.original.weekEndDate)} className="btn btn-outline-primary scale85">
                    View Details
                </a>
            ),
            disableSortBy: true,
        }
    ], [sortApprovedListConfig]);

    const convertTimeStringToMinutes = (timeString) => {
        const [hours, minutes] = timeString.split(':').map(Number);
        return hours * 60 + minutes;
    };

    // Convert total minutes back to "HH:mm" format
    const formatMinutesToTime = (totalMinutes) => {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
        {
            columns: columns1, // This should still reference `columns1`
            data
        },
        useSortBy
    );

    return (
        <>
            <main>


                <section>
                    <div class="container">
                        <h2 class="mainHeadline">Time Sheet</h2>
                        <div class="tab-primary">
                            <TimesheetHeader />

                            {/* Tab panes */}
                            <div class="tab-content ">
                                <div>
                                    {/*
         <div class="d-flex justify-content-end gap-2 align-items-center my-2">
                            <span>Sort by:</span>
                            <select class="form-select" id="inputGroupSelect01" style={{"max-width": "200px"}}>
                                <option selected="">Select Week</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                        </div> */}
                                    <div class="tbl-container bdr mb-3">
                                        {/* <== overflow: hidden applied to parent */}
                                      {/*  <table {...getTableProps()} className="table mb-0">
                                    <thead className="bg-lightblue">
                                        {headerGroups.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th
                                                        {...column.getHeaderProps(column.getSortByToggleProps())}
                                                        style={{
                                                            width: column.width,
                                                            position: 'relative',

                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        {column.render('Header')}
                                                        <span
                                                            style={{
                                                                position: 'absolute',
                                                                right: '10px',
                                                                top: '50%',
                                                                transform: 'translateY(-50%)',
                                                                display: 'inline-block',
                                                                fontSize: '0.8em',
                                                                color: '#888',
                                                            }}
                                                        >
                                                            {column.isSorted ? (column.isSortedDesc ? '🔽' : '🔼') : '↕️'}
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {rows.length > 0 ? (
                                            rows.map(row => {
                                                prepareRow(row);
                                                return (
                                                    <tr {...row.getRowProps()}>
                                                        {row.cells.map(cell => (
                                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                        ))}
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan="6">
                                                <div className="d-grid text-center py-3">
                        <img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
                        <span> No Approved Entries</span>
                        </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table> */}

                                        <table {...getTableProps()} className="table mb-0">
                                            <thead className="bg-lightblue">
                                                {headerGroups.map(headerGroup => (
                                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                                        {headerGroup.headers.map(column => (
                                                            <th
                                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                                style={{
                                                                    width: column.width,
                                                                    position: 'relative',
                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                {column.render('Header')}
                                                                <span
                                                                    style={{
                                                                        //position: 'absolute',
                                                                        paddingLeft: '10px',
                                                                        right: '10px',
                                                                        top: '50%',
                                                                        //transform: 'translateY(-50%)',
                                                                        display: 'inline-block',
                                                                        fontSize: '0.8em',
                                                                        color: '#888',
                                                                    }}
                                                                >
                                                                    {
                                                                        !column.disableSortBy ? (
                                                                            column.isSorted ? (
                                                                                column.isSortedDesc ? ' 🔽' : ' 🔼'
                                                                            ) : '↕️'
                                                                        ) : null
                                                                    }
                                                                </span>
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </thead>
                                            <tbody {...getTableBodyProps()}>
                                                {rows.length > 0 ? (
                                                    rows.map(row => {
                                                        prepareRow(row);
                                                        return (
                                                            <tr {...row.getRowProps()}>
                                                                {row.cells.map(cell => (
                                                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                                ))}
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td colSpan="6">
                                                            <div className="d-grid text-center py-3">
                                                                <img src="images/emptybox.svg" className="m-auto" alt="" width={128} />
                                                                <span> No entries available.</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>

                                    {/*    <table class="table mb-0">
                                            <thead class="bg-lightblue">
                                                <tr>

                                                    <th scope="col" style={{ "width": "auto" }}>Week</th>
                                                    <th scope="col" style={{ "width": "150px" }}>Total Hours</th>
                                                    <th scope="col" style={{ "width": "160px" }}></th>
                                                </tr>
                                            </thead>
                                            {approvedList.length > 0 ?
                                                <tbody>
                                                    {approvedList.map((data, index) => (
                                                        <tr>
                                                            <td style={{ "width": "auto" }}>
                                                                <div class="d-flex gap-2 align-items-center">
                                                                    <a href="/approved-weekview" onClick={() => showSubmittedWeekTimesheet(data.weekStartDate, data.weekEndDate)}> {getDateRange2(data.weekStartDate, data.weekEndDate)}</a>
                                                                </div>
                                                            </td>
                                                            <td style={{ "width": "150px" }}>{getTotalTimeApproved(index)}</td>
                                                            <td style={{ "width": "160px" }}><a href="/approved-weekview" onClick={() => showSubmittedWeekTimesheet(data.weekStartDate, data.weekEndDate)} class="btn btn-outline-primary scale65">View Details</a></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                                :
                                                <tbody>
                                                    <tr>
                                                        <td colspan="3">

                                                            <div className="d-grid text-center py-3">
                                                                <img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
                                                                <span>No Approved Entries</span>
                                                            </div>

                                                        </td>
                                                    </tr>
                                                </tbody>
                                            }
                                        </table> */}

                                    </div>

                                    {renderProjectPagination()}
                                </div>

                            </div>
                        </div>
                        {showLoader === 1 ?
                        <div class="loadwrapper">
                            <div class="clock"><div class="circle"></div></div>
                        </div>
                        : '' }
                    </div>
                </section>

            </main>
            <div id="menu3" class="container tab-pane fade">

            </div>
        </>
    )
};

ApprovedTimesheet.propTypes = {};

ApprovedTimesheet.defaultProps = {};

export default ApprovedTimesheet;

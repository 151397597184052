import React, { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import services from "../../../services/services";
import AddClient from "../../modals/add-client";
import AddProject from "../../modals/add-project";
import AddTask from "../../modals/add-task";
import $ from 'jquery';
import Pagination from "../../../utility/Pagination/Pagination";
import { useTable, useSortBy, useExpanded } from 'react-table';
import ProjectManagementHeader from "./project-management-header";

function ConfirmationPopup({ message, onConfirm, onCancel }) {
    return (
        <div className="popup">
            <div className="popup-content" style={{"maxWidth":'420px'}}>
                <p>{message}</p>
                <button onClick={onConfirm} class="btn btn-primary  me-2">Yes</button>
                <button onClick={onCancel} class="btn btn-outline-dark">No</button>
            </div>
        </div>
    );
}

const ProjectManagement = () => {

    const projectTabRef = useRef(null);
    const clientTabRef = useRef(null);
    const taskTabRef = useRef(null);

    const location = useLocation();

    const useridval = useState(sessionStorage.getItem('userIdval'));
    const [loggedUser, setLoggedUser] = useState(sessionStorage.getItem('userRole'));
    const [companyid, setCompanyId] = useState(sessionStorage.getItem('userCompanyId'));
    const [adminCompanyid, setAdminCompanyId] = useState(sessionStorage.getItem('adminCompany'));
    const [clientStatus, setClientStatus] = useState(1);
    const [clientStatusIsChecked, setClientStatusIsChecked] = useState(0);
    const [projectStatusIsChecked, setProjectStatusIsChecked] = useState(0);
    const [clientListInfo, setClientListInfo] = useState([]);
    const [selectedClientIdForEdit, setSelectedClientIdForEdit] = useState(null);
    const [selectedProjectIdForEdit, setSelectedProjectIdForEdit] = useState(null);
    const [selectedTaskIdForEdit, setSelectedTaskIdForEdit] = useState(null);
    const [selectedDynamicValue, setSelectedDynamicValue] = useState(null);
    const [sortClientListConfig, setSortClientListConfig] = useState({ key: 'Week', direction: 'ASC' });

    const [projectListInfo, setProjectListInfo] = useState([]);
    const [ProjectStatus, setProjectStatus] = useState(1);
    const pagenum = 1;
    const pagesize = 20;
    const ITEMS_PER_PAGE = 20; // Per page show the data pagination
    //const Pagesize=5;
    const [totalItems, setTotalItems] = useState(0);
    const [totalpages, settotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);

    const [clientIdforUpdate, setClientIdforUpdate] = useState(null);
    const [clientNameforUpdate, setClientNameforUpdate] = useState(null);
    const [companyIdforUpdate, setCompanyIdforUpdate] = useState(null);
    const [clientStatustoUpdate, setClientStatustoUpdate] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    const [showLoader, setShowLoader] = useState(0);
    const [formMode, setFormMode] = useState(null);
    const [projectidfortask, setprojectidfortask] = useState(null);


    const createNewClient = () => {
       
        setSelectedClientIdForEdit(null)
    }

    function getClientsList(mycompanyid, clientstatusval, pagenumval, itemsperpage, managerid) {
        setShowLoader(1);
        services.getClientsListAdmin(mycompanyid, clientstatusval, pagenumval, itemsperpage, managerid,sortClientListConfig.direction).then((res) => {
            if (res.data.type === 'success') {
                setClientListInfo(res.data.data);
                setTotalItems(res.data.totalRecords);
                settotalPages(Math.ceil(res.data.totalRecords / ITEMS_PER_PAGE));
                setCurrentPage(pagenumval);
            }
            setShowLoader(0);
        });
    }


    const handlePageChange2 = (offset, triggerevent) => {
        setCurrentPage(offset);
    };

    useEffect(() => {
        var clientFilterCompanyVal = '';
        var managerid='';
        if(loggedUser.toLowerCase() === 'manager'){
            clientFilterCompanyVal = companyid;
        }
        if(loggedUser.toLowerCase() === 'admin'){
            clientFilterCompanyVal = adminCompanyid;
        }
       // alert(clientStatus);
        getClientsList(clientFilterCompanyVal, clientStatus, currentPage, ITEMS_PER_PAGE, managerid);
       /* const queryParams = new URLSearchParams(location.search);
        const tab = queryParams.get('tab');

        if (tab === 'client' && clientTabRef.current) {
            clientTabRef.current.click();
        } else if (tab === 'project' && projectTabRef.current) {
            clientTabRef.current.click();
            projectTabRef.current.click();


        } else if (tab === 'task' && taskTabRef.current) {

            taskTabRef.current.click();
        } else {
            clientTabRef.current.click();
            projectTabRef.current.click();
        } */
    }, [location, clientStatus, currentPage,sortClientListConfig]);


    const isEmpty = (value) => {
        return value === null || String(value).trim() === '';
    }

    const handleClientStatus = (event) => {
        //alert(event.target.checked);
        setCurrentPage(1);
        if (event.target.checked) {
            setClientStatusIsChecked(1);
            setClientStatus(0);
        } else {
            setClientStatus(1);
            setClientStatusIsChecked(0);
        }
    }

    const viewClientInfo = (clientid) => {

        setSelectedClientIdForEdit(clientid);
    }

    const changeClientStatus = (clientId, clientName, companyId, newStatus) => {
        setClientIdforUpdate(clientId);
        setClientNameforUpdate(clientName);
        setCompanyIdforUpdate(companyId);
        setClientStatustoUpdate(newStatus);
        setShowPopup(true);
    }

    const handleCancel = () => {
        setShowPopup(false);
    };

    const handleUpdate = () => {

        const requestObject = {
            "client_name": clientNameforUpdate,
            "address1": null,
            "address2": null,
            "city": null,
            "state": null,
            "zip": null,
            "company_id": companyIdforUpdate,
            "status": clientStatustoUpdate
        }
        var accessTokenIn = sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'PUT',
        //     headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer "+accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };

        $(".load-wrap").show(); //console.log(requestObject); return false;
        services.updateClientById(requestObject, clientIdforUpdate).then((res) => {
            //setIsOpen(false);
            $(".load-wrap").show();
            $("#updatesuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
                $("#updatesuccessMessage").slideUp(500);
            });
            setShowPopup(false);
            setTimeout(function () {
                var managerid = ''; var reqCompany = '';
                if (loggedUser.toLowerCase() === 'manager') {
                    managerid = sessionStorage.getItem('userIdval');
                    reqCompany = companyid;
                }

                if (loggedUser.toLowerCase() === 'admin') {
                    reqCompany = adminCompanyid;
                }
                setCurrentPage(1);
                if (clientStatustoUpdate === 1) {
                    setClientStatusIsChecked(0);
                    setClientStatus(1);
                } else {
                    setClientStatusIsChecked(1);
                    setClientStatus(0);
                }

                //getClientsList(reqCompany, clientStatustoUpdate, pagenum, ITEMS_PER_PAGE, managerid);
            }, 1000);
        });
    }

    const getProjects = async (clientlistid, projectstatusval, sortingorder, pagenumval, itemsperpage, managerid) => {
        setShowLoader(1);
        setSearchProj('');
        try {
            var reqCompany = '';
            if (loggedUser.toLowerCase() === 'manager') {
                reqCompany = companyid;
            }

            if (loggedUser.toLowerCase() === 'admin') {
                reqCompany = adminCompanyid;
            }
            const res = await services.getProjectsDetailsList(clientlistid, projectstatusval, sortingorder, pagenumval, itemsperpage, managerid, 1, reqCompany);
            if (res.data.type === 'success') {
                setProjectListInfo(res.data.data);
                //setProjectsFromService(res.data.data); // Store the projects from the service if needed
                setTotalItems(res.data.totalRecords);
                settotalPages(Math.ceil(res.data.totalRecords / itemsperpage));
                setCurrentPage(pagenumval);
            }
        } catch (error) {
            console.error('Error fetching project details:', error);
        }
        finally {
            setShowLoader(0);
        }
    };

    const [searchTxt, setSearchTxt] = useState('');
    const [searchProj, setSearchProj] = useState('');
    const [searchTaskWithProjectId, setSearchTaskWithProjectId] = useState('');
    const [searchTaskWithBillable, setSearchTaskWithBillable] = useState('');
    const [searchTaskWithStatus, setSearchTaskWithStatus] = useState([]);


    const [clientForProject, setClientForProject] = useState('');
    const showClientProjects = (clientid) => {
        setClientForProject(clientid);
        document.getElementsByClassName("projecttab")[0].click();
        var managerid = '';
        /*if(loggedUser.toLowerCase() === 'manager'){
            managerid = sessionStorage.getItem('userIdval');
        } */
        //getProjects(clientid, 1, 'asc', pagenum, ITEMS_PER_PAGE, managerid);
        window.location.href = '/manageprojects?cid='+clientid;
    }

    const handleClientListSort = (columnKey) => {
        let direction = 'ASC';
        if (sortClientListConfig.key === columnKey && sortClientListConfig.direction === 'ASC') {
            direction = 'DESC';
        }
        setCurrentPage(1);
        setSortClientListConfig({ key: columnKey, direction });
    }


    const columns = React.useMemo(
        () => {
            const baseColumns = [
                {
                    //Header: 'Client',
                    Header: (
                        <span onClick={() => handleClientListSort('Week')}>
                            Client{sortClientListConfig.key === 'Week' ? (sortClientListConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}
                        </span>
                    ),
                    id:'client_id',
                    accessor: 'client_name',
                    disableSortBy: true,
                    width: '18%',
                    Cell: ({ value, row }) => (
                        <a href={`/manageprojects?cid=`+row.original.id+`&status=`+row.original.status} onClick={() => showClientProjects(row.original.id)}>
                            {value}
                        </a>
                    ),
                    sortType: 'basic', // Enable sorting
                },
                {
                    Header: 'Project',
                    accessor: 'projects',
                    disableSortBy: true,
                    width: '30%',
                    Cell: ({ value }) => (
                        value.length > 0 ? (

                            value.map((projectInfo, projectIndex) => (

                                <span key={projectIndex} className="badge rounded-pill project-badge text-dark my-2">
                                    {projectInfo.name}
                                </span>

                            ))

                        ) : (
                            <span>No projects available</span>
                        )
                    ),
                    sortType: 'basic', // Enable sorting
                },
            ];

            if (loggedUser.toLowerCase() === 'admin') {
                baseColumns.push(
                    {
                        Header: 'Company',
                        accessor: row => row.company.company_name,
                        width: 'auto',
                        Cell: ({ value }) => <div>{value}</div>,
                        disableSortBy: true,
                        sortType: 'basic', // Enable sorting
                    },
                    {
                        Header: 'Actions',
                        accessor: 'actions',
                        width: '100px',
                        Cell: ({ row }) => (
                            <div className="dropdown table-action-dd">
                                <button className="dropdown-toggle table-action-btn" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="images/dots.svg" alt="" />
                                </button>
                                <ul className="dropdown-menu">
                                    <li>
                                        <a className="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#exampleModal2" onClick={() => viewClientInfo(row.original.id)}>
                                            <img src="images/edit.png" alt="" /> Edit Client
                                        </a>
                                    </li>
                                    {row.original.status === 1 ? (
                                        <li>
                                            <a className="dropdown-item" href="#" onClick={() => changeClientStatus(row.original.id, row.original.client_name, row.original.company_id, 0)}>
                                            <img src="images/disabled.svg" width={18} alt="" /> Disable
                                            </a>
                                        </li>
                                    ) : (
                                        <li>
                                            <a className="dropdown-item" href="#" onClick={() => changeClientStatus(row.original.id, row.original.client_name, row.original.company_id, 1)}>
                                                <img src="images/greencheck.svg" width={19} alt="" /> Enable
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        ),
                        disableSortBy: true, // Disable sorting for this column
                    }
                );
            }

            return baseColumns;
        },
        [loggedUser,sortClientListConfig] // Add loggedUser as a dependency
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
        {
            columns,
            data: clientListInfo,
        },
        useSortBy
    );

    return (
        <>
            <main>


                <section>
                    <div class="container">
                        <h2 class="mainHeadline">
                            Project Management
                        </h2>

                        <div class="tab-primary">
                        <ProjectManagementHeader />

                            <div class="tab-content ">

                                <div id="one" class="container tab-pane fade px-0 active show" role="tabpanel">
                                  
                                    <div class="d-flex justify-content-between align-items-left pt-2 pb-2">
                                        
                                    <div class="col-lg-3">
                                        <button class="btn btn-primary m-0" data-bs-toggle="modal" data-bs-target="#exampleModal2" onClick={() => createNewClient()}><span class="mx-2">+</span> Create Client</button>

                                    </div>
                                        
                                        <div class="d-flex justify-content-end align-items-center ms-auto" style={{ "width": "auto" }}>
                                            <span>Status:</span>
                                            <div class="d-flex align-items-center gap-2 py-2 ps-2 form-switch">
                                                <label class="form-check-label" for="flexSwitchCheckDefault"><b><small>Active</small></b></label>
                                                <input class="form-check-input custtoggle mx-0" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={handleClientStatus} checked={clientStatusIsChecked} />
                                                <label class="form-check-label" for="flexSwitchCheckDefault"><b><small>Inactive</small></b></label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="tbl-container bdr mt-0">
                                        <table {...getTableProps()} className="table mb-0">
                                            <thead className="bg-lightblue tsthwrap">
                                                {headerGroups.map(headerGroup => (
                                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                                        {headerGroup.headers.map(column => (
                                                            <th
                                                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                                                style={{
                                                                    width: column.width,
                                                                    position: 'relative',

                                                                    cursor: 'pointer',
                                                                }}
                                                            >
                                                                {column.render('Header')}
                                                                <span
                                                                    style={{
                                                                        //position: 'absolute',
                                                                        paddingLeft: '10px',
                                                                        right: '10px',
                                                                        top: '50%',
                                                                        //transform: 'translateY(-50%)',
                                                                        display: 'inline-block',
                                                                        fontSize: '0.8em',
                                                                        color: '#888',
                                                                    }}
                                                                >
                                                                    {
                                                                        !column.disableSortBy ? (
                                                                            column.isSorted ? (
                                                                                column.isSortedDesc ? ' 🔽' : ' 🔼'
                                                                            ) : '↕️'
                                                                        ) : null
                                                                    }
                                                                </span>
                                                            </th>
                                                        ))}
                                                    </tr>
                                                ))}
                                            </thead>
                                            <tbody {...getTableBodyProps()}>
                                                {rows.length > 0 ? (
                                                    rows.map(row => {
                                                        prepareRow(row);
                                                        return (
                                                            <tr {...row.getRowProps()}>
                                                                {row.cells.map(cell => (
                                                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                                ))}
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td colSpan="6">
                                                            <div className="d-grid text-center py-3">
                                                                <img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
                                                                <span> No clients available.</span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>




                                    </div>

                                    {showPopup && (() => {
    const message = clientStatustoUpdate === 0
        ? (
            <>
                Are you sure you want to proceed?<br />
                This action will deactivate all projects linked to this client.
            </>
          )
        : 'Are you sure you want to update this client?';

    return (
        <ConfirmationPopup
            message={message}
            onConfirm={handleUpdate}
            onCancel={handleCancel}
        />
    );
})()}



                                    <div class="paginationContent mt-3">
                            <Pagination
                            total={totalItems}
                            itemsPerPage={ITEMS_PER_PAGE}
                            currentPage={currentPage}
                            onPageChange={handlePageChange2}/>

                        </div>

                                </div>

                            </div>
                            {showLoader === 1 ?
                                <div class="loadwrapper">
                                    <div class="clock"><div class="circle"></div></div>
                                </div>
                                : ''}
                        </div>

                    </div>

                    <AddProject editProject={selectedProjectIdForEdit} />

                    <AddTask formMode={formMode} taskProject={projectidfortask} editTask={selectedTaskIdForEdit} dynamic={selectedDynamicValue} />

                    <AddClient editClient={selectedClientIdForEdit} isOpenModal='1' />

                </section>

            </main>

        </>
    )
};

ProjectManagement.propTypes = {};

ProjectManagement.defaultProps = {};

export default ProjectManagement;

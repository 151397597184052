import React,{useEffect} from "react";
import services from "../../services/services";


const ProjectTaskDetails = () => {


    useEffect(() => {


    }, []);

  return(
  <>
   <main>
      <section>
      <div class="container">
        <h2 class="mainHeadline">
            Projects
        </h2>

        <div class="tab-primary">

            <ul class="nav nav-tabs" role="tablist">

                <li class="nav-item">
                    <a class="nav-link bg-none" data-bs-toggle="tab" href="#menu1">Projects</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link active bg-none" data-bs-toggle="tab" href="#menu2">Tasks </a>
                </li>

            </ul>

            <div class="tab-content ">

                <div id="menu1" class="container tab-pane fade px-0">
                    sssssssssss
                </div>
                <div id="menu2" class="container tab-pane px-0 fade active show">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="tab-content w-100">
                                <div class="tab-pane container-fluid active px-0" id="menu1">
                                    <div class="tab-content-bg-primary">

                                        <div class="d-flex justify-content-between">
                                            <div class="billnonbill d-flex gap-4 align-items-center me-5">
                                                <div class="item d-grid">
                                                    <small> Total hour</small>
                                                    <strong class="mainbluetxt ">35:00</strong>
                                                </div>
                                                <span>|</span>
                                                <div class="item d-grid">
                                                    <small class="d-flex gap-1 align-items-center"> <img
                                                            src="images/billed.png" width="20" alt=""/> Billable</small>
                                                    <strong class="mainbluetxt ">35:00</strong>
                                                </div>
                                                <span>|</span>
                                                <div class="item d-grid">
                                                    <small class="d-flex gap-1 align-items-center"> <img
                                                            src="images/dollarred.svg" width="20" alt=""/>
                                                        Non-Billable</small>
                                                    <strong class="mainbluetxt ">35:00</strong>
                                                </div>
                                            </div>

                                            <div class="billnonbill d-flex gap-4 align-items-center ">
                                                <div class="item d-grid">
                                                    <small> Total Allocated Hours</small>
                                                    <strong class="mainbluetxt ">35:00</strong>
                                                </div>
                                                <span>|</span>
                                                <div class="item d-grid">
                                                    <small class="d-flex gap-1 align-items-center">  Burned Hours</small>
                                                    <strong class="mainbluetxt ">35:00</strong>
                                                </div>
                                                <span>|</span>
                                                <div class="item d-grid">
                                                    <small class="d-flex gap-1 align-items-center">
                                                            Remaining Hours</small>
                                                    <strong class="mainbluetxt ">35:00</strong>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="d-flex justify-content-between align-items-center titlewrap p-3">
                                        <div class="d-flex gap-2">
                                            <img src="images/billed.png" width="24" height="24" alt=""/>
                                            <div class="d-grid">
                                                <h2>Covered calls options</h2>
                                                <a href="/projects" class="bluetext"><img src="images/returnArrow.png" style={{"margin-right": "4px; transform: rotate(180deg)"}} alt=""/>Back to List
                                                {/* Back to Task List */}
                                                </a>
                                            </div>
                                        </div>

                                        <div> sprint 2</div>
                                        <div class="d-flex justify-content-end align-items-center">
                                            <span style={{"min-width": "69px"}}>Status:</span>
                                            <select class="form-select" id="inputGroupSelect01" style={{"max-width": "200px"}}>
                                                    <option selected="">InProgress</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                  </select>
                                        </div>


                                    </div>
                                    <div class="discriptionwrap my-3 px-3">
                                        <div class="d-flex justify-content-between mb-2">
                                            <div class="d-flex gap-2">
                                                <span class="nametag">KK</span>
                                                <div class="titlewrap">
                                                    <h2>Kishore</h2>
                                                    <small>1 March 2024</small>
                                                </div>
                                            </div>
                                            <div class="hours scale65">
                                                <span class="hrtime">02:00</span>
                                                <span class="hrlabel">HOURS</span>
                                            </div>
                                        </div>
                                        <p class="ps-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
                                            since the 1500s Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                    </div>



                                    <div class="mb-2">
                                        <label for="exampleFormControlTextarea1" class="form-label">Example textarea</label>
                                        <textarea class="form-control commetformboxshadow" id="exampleFormControlTextarea1" rows="3"></textarea>
                                    </div>
                                    <button class="btn btn-primary mt-1 mb-5">Submit</button>

                                    <div class=" border-bottom pb-1 my-3">
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <div class="d-flex gap-2">
                                                <span class="nametag">KK</span>
                                                <div class="titlewrap">
                                                    <h2>Manger</h2>

                                                </div>
                                            </div>
                                            <div>
                                                <span>24, march 2024</span>
                                            </div>
                                        </div>
                                        <p class="ps-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
                                            since the 1500s Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                    </div>

                                    <div class=" border-bottom pb-1 my-3">
                                        <div class="d-flex justify-content-between align-items-center mb-2">
                                            <div class="d-flex gap-2">
                                                <span class="nametag">KK</span>
                                                <div class="titlewrap">
                                                    <h2>Manger</h2>

                                                </div>
                                            </div>
                                            <div>
                                                <span>24, march 2024</span>
                                            </div>
                                        </div>
                                        <p class="ps-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever
                                            since the 1500s Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                    </div>


                                </div>




                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>

    </div>
         </section>

      </main>
  </>
)};

ProjectTaskDetails.propTypes = {};

ProjectTaskDetails.defaultProps = {};

export default ProjectTaskDetails;

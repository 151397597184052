import React,{history,useEffect, useState, useMemo } from "react";
import services from "../../services/services";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import DateObject from "react-date-object";
import { ErrorMessage } from "@hookform/error-message";
import { useForm } from "react-hook-form";
import $ from 'jquery';

const CreateTask = ({mydate, myweekday1, myweekday2}) => {
    const [datect, setDatect] = useState(new Date());
    const [showCalendarct, setShowCalendarct] = useState(false);
    const [showProjectListct, setShowProjectListct] = useState([]);
    const [isOpenct, setIsOpenct] = useState(false);
    const [isBillablect, setIsBillablect] = useState(1);
    const [taskinputDurationValue, setTaskInputDurationValue] = useState('');
    const [formattedTaskTime, setFormattedTaskTime] = useState('');
    const [taskprojvalidation, setTaskProjvalidation] = useState(0);
    const [tasknamevalidation, setTaskNamevalidation] = useState(0);
    const [newtaskdescriptionvalidation, setNewTaskdescriptionvalidation] = useState(0);
    const [newtaskdurationvalidation, setNewTaskdurationvalidation] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    //const useridfortask = 4;
    const [useridfortask, setuseridval] = useState(0);

    const [showLoader, setShowLoader] = useState(0);

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({
        criteriaMode: "all",mode: "onBlur"
    });

    const handleTaskDurationInputChange = (event) => {
        const durationvalue = event.target.value;

        // Remove non-numeric characters and restrict length to 4
        const cleanedDurationValue = durationvalue.replace(/\D/g, '').slice(0, 4);

        setTaskInputDurationValue(cleanedDurationValue);
        setFormattedTaskTime(formatTimeToHHMM(cleanedDurationValue));
    };

    const formatTimeToHHMM = (value) => {
        if (value === '') return '';

        if (value.length <= 2) {
          return value; // Return as is if only two digits or less
        }

        const firstPart = value.slice(0, 2);
        const secondPart = value.slice(2);

        return `${firstPart}:${secondPart}`;
    };

    function getProjectsct(projstatusct, clientidct, myRole){
        
        if(myRole.toLowerCase() === 'manager'){

            services.getProjectManagersByProjectId('', clientidct, 1,'assigned','').then((res) => {
                
                if(res){
                    if(res.data.data != null){
                        setShowProjectListct(res.data.data);
                    }
                }
            });
        }
        else{

            services.getProjectUsersByProjectId('', clientidct, 1, '', 'assigned', 1,'',1, 'ASC', 'client_name').then((res) => {
                if(res){
                    if(res.data.data != null){
                        setShowProjectListct(res.data.data);
                    }
                }
            });
        }

        
    }

    useEffect(() => {
        
        var loggedUserId = sessionStorage.getItem('userIdval');
        setuseridval(parseInt(loggedUserId));

        var loggedinUserRole = sessionStorage.getItem('userRole');
        getProjectsct('1',loggedUserId, loggedinUserRole);

        $('#successMessagect').hide();
        $('#taskvalidationMessage').hide();
    },[]);  

    const isEmpty = (value) => {
        return value == null || value.trim() === '';
      }

    const submitValue=()=>
    {
        var taskvalidation = 0;
        if(isEmpty($('#tasknamect').val())){
            taskvalidation = 1;
            setTaskNamevalidation(1);
        } else {
            setTaskNamevalidation(0);
        }

        if(isEmpty($('#projectNamect').val())){
            taskvalidation = 1;
            setTaskProjvalidation(1);
        } else {
            setTaskProjvalidation(0); 
        }

        if(isEmpty($('#taskdescriptionct').val())){
            taskvalidation = 1;
            setNewTaskdescriptionvalidation(1);
        } else {
            setNewTaskdescriptionvalidation(0);
        }

        if(!(isEmpty(mydate))){
            if(isEmpty($('#taskdurationct').val())){
                taskvalidation = 1;
                setNewTaskdurationvalidation(1);
            } else {
                setNewTaskdurationvalidation(0);
            }
        }

        if(taskvalidation == 1){
            $('#taskvalidationMessage').show();
            return false;
        } else {
            setShowLoader(1);
            //var mytaskdateval = $('#taskentrydate').val();
            var mytaskdateval = [];
            var mytaskdurationval = [];
            var entry_created_from_day_or_week= '';
            var defaultduration = "00:00";
            if(isEmpty(mydate)){
                //week
                var mytaskduration = "00:00";

                const givenDate = new Date(myweekday1);
                    const dayOfWeek = givenDate.getUTCDay();
                    
                    // Calculate the start of the week (Monday)
                    const startOfWeek = new Date(givenDate);
                    startOfWeek.setUTCDate(givenDate.getUTCDate() - dayOfWeek + 1);
                    startOfWeek.setUTCHours(0, 0, 0, 0);
                
                    // Get the dates for the entire week
                    for (let i = 0; i < 7; i++) {
                        const currentDay = new Date(startOfWeek);
                        currentDay.setUTCDate(startOfWeek.getUTCDate() + i);
                        mytaskdateval.push(formatDate(currentDay));
                        mytaskdurationval.push(defaultduration);
                    }
                entry_created_from_day_or_week = 'week';
            } else {
                //day
                var mytaskduration = $('#taskdurationct').val();
                //var mytaskduration = formattedTimeVal2;
                var dateobjectval = new DateObject(mydate);
                mytaskdateval.push(dateobjectval.format("YYYY-MM-DD"));
                mytaskdurationval.push(mytaskduration);
                entry_created_from_day_or_week = 'day';
            }
            
            const requestObject =
            {
                "name": $('#tasknamect').val(),
                "project_id": $('#projectNamect').val(),
                "sprint": $('#sprintvalue').val(),
                "description": $('#taskdescriptionct').val(),
                "duration": mytaskduration,
                "estimated_hours": "00:00:00",
                "is_billable": isBillablect,
                "task_status": "New",
                "task_started_time": new Date(),
                "task_ended_time": new Date()

            }
            
            var accessTokenIn=sessionStorage.getItem("accToken");
            // const requestOptions = {
            //   method: 'POST',
            //   headers: {
            //     'Content-Type': 'application/json',
            //     'Authorization': "Bearer "+accessTokenIn
            //   },
            //   body: JSON.stringify(requestObject)
            // };

            services.createNewTask(requestObject).then((res) => {
                
                if(res.type == 'success'){
                    var resultdata = res.data;
                    
                    const requestObject1 =
                    {
                        "task_id": resultdata.id,
                        "user_ids": [useridfortask],
                        "task_user_status": "assigned"
                    }
                    
                    var accessTokenIn=sessionStorage.getItem("accToken");
                    // const requestOptions1 = {
                    //     method: 'POST',
                    //     headers: {
                    //     'Content-Type': 'application/json',
                    //     'Authorization': "Bearer "+accessTokenIn
                    //     },
                    //     body: JSON.stringify(requestObject1)
                    // };

                    services.assignTasktoUser(requestObject1).then((res2) => {
                        if(res2.type == 'success'){
                            const requestObject =
                            {
                              "client_id": resultdata.clientId,
                               "project_id": resultdata.project_id,
                               "task_id": resultdata.id,
                               "time_entry_description": resultdata.description,
                               "duration": mytaskdurationval,
                               "user_id": useridfortask,
                               "weekStartDate": myweekday1,
                                "weekEndDate": myweekday2,
                               "days": mytaskdateval,
                               "is_billable": resultdata.is_billable,
                               "week_time_entry_id": 5,
                               "is_holiday": 0,
                               "is_leave": 0,
                               "is_comp_off": 0,
                               "entry_created_from_day_or_week": entry_created_from_day_or_week,
                               "time_sheet_status": "Unsubmitted",
                               "submited_on": "2024-05-31T09:00:00Z",
                               "approved_on": null,
                               "rejected_on": null
                            }
                            
                            var accessTokenIn=sessionStorage.getItem("accToken");
                            // const requestOptions = {
                            //   method: 'POST',
                            //   headers: {
                            //     'Content-Type': 'application/json',
                            //     'Authorization': "Bearer "+accessTokenIn
                            //   },
                            //   body: JSON.stringify(requestObject)
                            // };
                
                            $(".load-wrap").show();
                            services.createTimesheetEntry(requestObject).then((res3) => {
                                
                                if(res3.type=='success'){
                                    setIsOpenct(false);
                                    $(".load-wrap").show();
                                    setShowLoader(0);
                                    $("#successMessagect").fadeTo(2000, 500).slideUp(500, function () {
                                        $("#successMessagect").slideUp(500);
                                    });
                                    setTimeout(function() { $('#closemodalct').click(); 
                                        if(entry_created_from_day_or_week == 'day'){
                                            window.location.href='/timesheet?req='+mytaskdateval[0]+'&view='+entry_created_from_day_or_week;
                                        } else {
                                            window.location.href='/timesheet?req='+mytaskdateval[0]+'&endreq='+mytaskdateval[parseInt(mytaskdateval.length - 1)]+'&view='+entry_created_from_day_or_week;
                                        }
                                       // window.location.href='/timesheet';
                                    }, 1000);
                                } else {
                                    setShowLoader(0);
                                }
                            });
                        } else {
                            setShowLoader(0);
                        }
                    }); 
                } else {
                    setShowLoader(0);
                }
            });
        }
    }

    function formatDate(date) {
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0');
        const day = String(date.getUTCDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    function getDateFormatCalendarDate(calendarDate){
        if (calendarDate == "") {
            return "";
        }
        var daten1 = new DateObject(calendarDate);
        var daten11 = daten1.format("YYYY-MM-DD");
        return daten11;
    }

    const handleChangect = (event) => {
        if(event.target.checked){
            setIsBillablect(1);
        } else{
            setIsBillablect(0);
        }
      };
      
      const getTaskTitleHead = () => {
        if(isEmpty(mydate)){
            //week
            var displaynewdatect1 = new DateObject(myweekday1);
            var displaynewdatect2 = new DateObject(myweekday2);
            
            return 'New Task for the Week: '+displaynewdatect1.format("DD MMM")+' - '+displaynewdatect2.format("DD MMM YYYY"); 
        } else {
            //day
            const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            var displaydate = new DateObject(mydate);
            var displaynewdate = new Date(mydate);
            const dayIndex = displaynewdate.getDay();

            var date = new Date(mydate + 'T00:00:00Z'); // Append 'T00:00:00Z' to ensure it's treated as UTC

            // Array of day names
            var daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            
            // Get the day of the week in UTC
            var dayOfWeekIndex = date.getUTCDay(); // Use getUTCDay() for UTC
            var dayOfWeekName = daysOfWeek[dayOfWeekIndex];
           
            return 'New Task creation on '+dayOfWeekName+', '+displaydate.format("DD MMM");
        }
      }

      const [inputtime2, setInputtime2] = useState('');
  const [formattedTimeVal2, setFormattedTimeval2] = useState('');

  function formatTimeInput(input) {
    let hours, minutes;
  
    // Convert input to string and pad with leading zeros
    let timeString = input.toString().padStart(4, '0');
  
    // Extract hours and minutes
    hours = timeString.slice(0, 2);
    minutes = timeString.slice(2);
  
    // Format time as HH:mm
    setFormattedTimeval2(`${hours}:${minutes}`);
  }

  const handleChangetime2 = (e) => {
    const value = e.target.value;
    setInputtime2(value);
    formatTimeInput(value);
  };

  const checkTimeFormat = () => {
    
    var timeEntered = formattedTimeVal2;
    if(!isNaN(parseInt(timeEntered)) && parseInt(timeEntered) > 0){
        var devidedtime =timeEntered.split(':');
        
        if(devidedtime[0] > 23 || devidedtime[1] > 59){
            setNewTaskdurationvalidation(1);
        } else {
            setNewTaskdurationvalidation(0);
        }
    }
    else{
        setNewTaskdurationvalidation(0);
    }
  }

  const handleTimeInputBlurCustom = (event) => {
    const inputElement = event.target;
    let value = inputElement.value.trim();
  
    // Remove all non-digit characters except for the first colon
    value = value.replace(/[^0-9:]/g, '');
  
    // Allow only one colon
    const colonCount = (value.match(/:/g) || []).length;
    if (colonCount > 1) {
      value = value.split(':')[0] + ':' + value.split(':').slice(1).join('');
    }
  
    // Check if the value is already in a good format
    const isValidTime = (value) => {
      const [hours, minutes] = value.split(':').map(part => parseInt(part, 10));
      return !isNaN(hours) && hours >= 0 && hours <= 23 &&
             !isNaN(minutes) && minutes >= 0 && minutes <= 59 &&
             value.split(':').length === 2;
    };
  
    if (isValidTime(value)) {
      inputElement.value = value;
      return;
    }
  
    // Handle cases for incomplete or incorrect values
    if (value.length === 1 && !value.includes(':')) {
      // Case for single digit (e.g., '1'): append ':00'
      value = value + ':00';
    } else if (value.length === 2 && !value.includes(':')) {
      // Case for two digits (e.g., '10'): append ':00'
      value = value + ':00';
    } else if (value.length === 3 && !value.includes(':')) {
      // Case for 'HMM' (e.g., '238'): format as '0H:MM'
      value = '0' + value[0] + ':' + value.slice(1);
    } else if (value.length === 3 && value.includes(':')) {
      // Case for 'H:MM': no change needed
    } else if (value.length === 4 && !value.includes(':')) {
      // Case for 'HHMM': format as 'HH:MM'
      value = value.slice(0, 2) + ':' + value.slice(2);
    } else if (value.length === 5 && !value.includes(':')) {
      // Case for 'HHMM' if the value is already correct
      value = value.slice(0, 2) + ':' + value.slice(2);
    }
  
    // Validate and format the time
    const [hours, minutes] = value.split(':').map(part => parseInt(part, 10));
    if (isNaN(hours) || hours > 23 || isNaN(minutes) || minutes > 59) {
      value = ''; // Clear the input if the value is invalid
    } else {
      // Ensure hours and minutes are zero-padded
      value = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    }
  
    inputElement.value = value;
  };

  const [textvalue, setTextValue] = useState('');
  const maxLength = 500; // Set your character limit here

  const handleChangeTextarea = (event) => {
      const newValue = event.target.value;
      if (newValue.length <= maxLength) {
          setTextValue(newValue);
      }
  };

  useEffect(() => {
    // Attach the event listener to the modal
    const modalElement = document.getElementById('createTaskModal');
    const handleModalHidden = () => resetForm();
    
    modalElement.addEventListener('hidden.bs.modal', handleModalHidden);

    return () => {
        // Cleanup the event listener when the component unmounts
        modalElement.removeEventListener('hidden.bs.modal', handleModalHidden);
    };
}, []);

useEffect(() => {
    if (!isOpen) {
        resetForm(); // Reset the form when the modal is closed
    }
}, [isOpen]);

const resetForm = () => {
    
    $("#entryform1").trigger("reset");
    setTaskInputDurationValue('');
    setFormattedTaskTime('');
    setTaskProjvalidation(0);
    setTaskNamevalidation(0);
    setNewTaskdescriptionvalidation(0);        
    setNewTaskdurationvalidation(0);  
    $('#successMessagect').hide();
    $('#taskvalidationMessage').hide();

 };
 
  return(
  <>
  <div class="modal fade " id="createTaskModal" tabindex="-1" aria-labelledby="createTaskModalLabel" aria-hidden="true">
    <div class="modal-dialog"  open={isOpenct}>
        <form onSubmit={handleSubmit(submitValue)}>
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{getTaskTitleHead()}</h5>
                <button type="button" id="closemodalct" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>

            {showLoader === 1 ?
    <div class="loadwrapperpopup">
        <div class="clock"><div class="circle"></div></div>
    </div>
    : '' }
            
            <div class="modal-body">
                <div class="form-group mb-2">
                    <label for="recipient-name" class="col-form-label">Task*</label>
                    <input type="text" class={`form-control ${tasknamevalidation === 1 ? 'errorinp' : ''}`} id="tasknamect" name="tasknamect" placeholder="Task Name"/>
                </div>
                <div class="form-group mb-2">
                <input type="hidden" id="taskentrydate" name="taskentrydate" value={getDateFormatCalendarDate(datect)} />
                <label for="recipient-name" class="col-form-label">Project*</label>
                    <select class={`form-select ${taskprojvalidation === 1 ? 'errorinp' : ''}`} aria-label="Default select example" id="projectNamect" name="projectNamect">
                        <option selected value="">Select Project</option>
                        {showProjectListct.map((data, index) => (
                            <option value={data.project.id} >{data.project.name}</option>
                        ))}
                    </select>
                </div>
                <div class="form-group mb-2">
                    <label for="recipient-name" class="col-form-label">Sprint</label>
                    <input type="text" class="form-control" id="sprintvalue" name="sprintvalue" placeholder="Provide Sprint Details"/>
                </div>
                <div class="form-group mb-2">
                    <label for="recipient-name1" class="col-form-label">Task Description*</label>
                    <div class="form-floating">
                        <textarea class={`form-control pt-1 ${newtaskdescriptionvalidation === 1 ? 'errorinp' : ''}`} id="taskdescriptionct" placeholder={`Write task description in max ${maxLength} characters`} value={textvalue} onChange={handleChangeTextarea}></textarea>
                    </div>
                    <small className="textAreaMaxLength">(Max 500 Characters)</small>
                </div>
                <div class="py-2">
                    <div class="d-grid justify-content-end">
                    {!(isEmpty(mydate)) ?
                    <>
                        <div class="d-flex align-items-center">
                            <span>Time*</span>

                             <input placeholder="00:00" type="text" style={{maxWidth:"100px"}} id="taskdurationct" name="taskdurationct" className={`no-icon form-control ms-2 ${newtaskdurationvalidation === 1 ? 'errorinp' : ''}`}  onBlur={handleTimeInputBlurCustom} maxLength="5" /> 
                        </div>

                        {/* {newtaskdurationvalidation === 1 ? 
                        <small style={{"color": "red"}}>Task duration value should be less than 2360</small> : ''} */}
                        </>
                        : ''
                    }

                        <div class="d-flex align-items-center gap-2 py-2 form-switch">
                            <label class="form-check-label" for="flexSwitchCheckDefault">Non-Billable</label>
                            <input class="form-check-input custtoggle mx-0" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={handleChangect} checked={isBillablect}/>
                            <label class="form-check-label" for="flexSwitchCheckDefault">Billable</label>
                        </div>
                    </div>
                </div>
                <div className="validationglobal p-2" id="taskvalidationMessage">
                        <div className="validateitem">
                            {/* <span className="errimg"><img class="icon" src="images/error.svg" alt=""/></span> */}
                            <p>Please fill out all the required fields!</p>
                        </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary">Save</button>
                <button type="button" class="btn btn-outline-dark" data-bs-dismiss="modal">Cancel</button>
            </div>
            <div id="successMessagect" class="alert alert-success" role="alert">Task created successfully!</div>
        </div>
        </form>
    </div>
    </div>
  </>
)};

CreateTask.propTypes = {};

CreateTask.defaultProps = {};

export default CreateTask;

import React, { useEffect, useState, useRef } from "react";
import services from "../../services/services";
import { DeleteConfirmationPopup } from "../../confirmation-dialogue/delete-dialogue";
import $ from 'jquery';
import { useTable, useSortBy } from 'react-table';


const Projects = () => {

    var loggedUserId = sessionStorage.getItem('userIdval');
    const projectLinkRef = useRef(null);
    const taskLinkRef = useRef(null);
    const [useridval, setuseridval] = useState(loggedUserId);
    const [loggedUser, setLoggedUser] = useState('');
    const [projectListDetails, setProjectInfoDetails] = useState([]);
    const [clientInfoDetails, setClientInfoDetails] = useState([]);
    const [clientWiseStatsUser, setClientWiseStatsUser] = useState([]);
    const [taskListDetails, setTaskInfoDetails] = useState([]);
    const [taskHoursListInfo, setTaskHoursListInfo] = useState([]);
    const [mergedData, setMergedData] = useState([]);
    const [projectListFilterDetails, setProjectListFilterDetails] = useState([]);

    const [projectCurrentPage, setProjectCurrentPage] = useState(1);
    const [projectTotalPages, setProjectTotalPages] = useState(1);

    const [taskCurrentPage, setTaskCurrentPage] = useState(1);
    const [taskTotalPages, setTaskTotalPages] = useState(1);

    const pageSize = 15;
    const [showLoader, setShowLoader] = useState(0);
    const [companyid, setCompanyId] = useState(sessionStorage.getItem('userCompanyId'));

    const [sortConfig, setSortConfig] = useState({ key: 'client_name', direction: 'ASC' });
    const [sortTaskConfig, setSortTaskConfig] = useState({ key: 'task_status', direction: 'ASC' });
    const [sortHoursConfig, setSortHoursConfig] = useState({ key: 'task_status', direction: 'ASC' });

    useEffect(() => {
        var loggedinUserRole = sessionStorage.getItem('userRole');
        setLoggedUser(loggedinUserRole);

        var projectid = '';
        if(loggedinUserRole == 'User' || loggedinUserRole == 'Employee'){
            getClientsByUserId(projectid, companyid, useridval, '', 'ASC', 'client_name')
            getProjectsByUserId(projectid, useridval, projectClientSelected, statusForProject, sortConfig.direction, sortConfig.key);
            var tab = getParameterByName('tab');

            if(!isEmpty(tab)){
                if(tab === 'project'){
                    //document.getElementsByClassName("nav-link")[0].click();
                    projectLinkRef.current.click();
                }

                if(tab === 'task'){
                    //document.getElementsByClassName("nav-link")[1].click();
                    taskLinkRef.current.click();
                }
            } else {
               // document.getElementsByClassName("nav-link")[0].click();
                projectLinkRef.current.click();
            }
        }

    }, []);

    useEffect(() => {
        var loggedinUserRole = sessionStorage.getItem('userRole');
        setLoggedUser(loggedinUserRole);
        var projectid = '';
        fetchUserClients();
        getProjectsByClientId('', useridval, '');
        if(loggedinUserRole == 'User' || loggedinUserRole == 'Employee'){
            getProjectsByUserId(projectid, useridval, projectClientSelected, statusForProject, sortConfig.direction, sortConfig.key);
        }
    }, [projectCurrentPage]);

    const fetchUserClients = async () => {
        const requestJsonClient = {
            weekStartDate: '',
            weekEndDate: '',
            userId: useridval,
            projectStatus: '',
            "pageNumber": 1,
            "pageSize": ''
        };

        const result1_fetchClientWiseStatsUser = await services.fetchClientWiseStatsUser(requestJsonClient);
        setClientWiseStatsUser(result1_fetchClientWiseStatsUser);
    }

    const isEmpty = (value) => {
        return value == null || String(value).trim() === '';
    }

    function getParameterByName(name) {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        var results = regex.exec(window.location.href);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    function getProjectsByUserId(projectid, userid, clientFiltered, statusFiltered, sortingOrder, sortingColum) {
        setShowLoader(1);
        services.getProjectUsersByProjectId(projectid, userid,projectCurrentPage, pageSize,'', statusFiltered, clientFiltered,'', sortingOrder, sortingColum).then((res) => {
            
            if(res.data.type == 'success'){
                setProjectInfoDetails(res.data.data);
                //alert(res.data.totalNumberOfPages);
                setProjectTotalPages(res.data.totalNumberOfPages);
            }
            setShowLoader(0);
        });
    }

    function getClientsByUserId(projectidval, companyidval, useridval, clientStatus, sortingOrderClient, sortingColumClient) {
        services.getClientsByUserId(projectidval, companyidval,useridval, clientStatus, sortingOrderClient, sortingColumClient).then((res) => {
            
            if(res.data.type == 'success'){
                setClientInfoDetails(res.data.data);
            }
        });
    }

    const [statusForProject, setStatusForProject] = useState('');
    const handleProjectStatus = (event) => {
        setProjectCurrentPage(1);
        setStatusForProject(event.target.value);
        getProjectsByUserId('', useridval, projectClientSelected, event.target.value, sortConfig.direction, sortConfig.key);
    }

    const [statusForTask, setStatusForTask] = useState('');
    const handleTaskStatus = (event) => {
        setTaskCurrentPage(1);
        setStatusForTask(event.target.value);
        loadTasks(sortTaskConfig.direction, sortTaskConfig.key, sortHoursConfig.direction, sortHoursConfig.key);
    }

    const [clientSelected, setClientSelected] = useState('');
    const handleClientFilter = (event) => {
        setTaskCurrentPage(1);
        setClientSelected(event.target.value);
        getProjectsByClientId('', useridval, event.target.value);
        loadTasks(sortTaskConfig.direction, sortTaskConfig.key, sortHoursConfig.direction, sortHoursConfig.key);
    }

    function getProjectsByClientId(projectidv, userid, clientFiltered) {
        services.getProjectUsersByProjectId(projectidv, userid,projectCurrentPage, pageSize,'','', clientFiltered,'').then((res) => {
            
            if(res.data.type == 'success'){
                setProjectListFilterDetails(res.data.data);
            }
        });
    }

    const [projectClientSelected, setProjectClientSelected] = useState('');
    const handleProjectClientFilter = (event) => {
        setProjectCurrentPage(1);
        setProjectClientSelected(event.target.value);
        getProjectsByUserId('', useridval, event.target.value, statusForProject, sortConfig.direction, sortConfig.key);
    }

    
    const handleSort = (columnKey) => {
        let direction = 'ASC';
        // Toggle sorting direction if the column is already being sorted
        if (sortConfig.key === columnKey && sortConfig.direction === 'ASC') {
            direction = 'DESC';
        }
        setSortConfig({ key: columnKey, direction });
        getProjectsByUserId('', useridval, projectClientSelected, statusForProject, direction, columnKey);
    }

    const handleTaskSort = (columnKey) => {
        let direction = 'ASC';
        // Toggle sorting direction if the column is already being sorted
        if (sortTaskConfig.key === columnKey && sortTaskConfig.direction === 'ASC') {
            direction = 'DESC';
        }
           
           let taskcolumn = columnKey;
        if(columnKey === 'taskName' || columnKey === 'projectName' || columnKey === 'sprint' || columnKey === 'taskStatus'){
             
        } else {
            taskcolumn = 'taskStatus';
        }
        setTaskCurrentPage(1);
        setSortTaskConfig({ key: taskcolumn, direction });

        setSortHoursConfig({ key: columnKey, direction });

        loadTasks(direction, taskcolumn, direction, columnKey);
    }

    const [projectSelected, setProjectSelected] = useState('');
    const handleProjectFilter = (event) => {
        setTaskCurrentPage(1);
        setProjectSelected(event.target.value);
        loadTasks(sortTaskConfig.direction, sortTaskConfig.key, sortHoursConfig.direction, sortHoursConfig.key);
    }

    useEffect(() => {
        loadTasks(sortTaskConfig.direction, sortTaskConfig.key, sortHoursConfig.direction, sortHoursConfig.key)
    }, [taskCurrentPage]);

    const loadTasks = (taskSortingOrder, taskSortingColumn, hoursSortingOrder, hoursSortingColumn) => {
        setShowLoader(1);
        var myTaskStatus = [];
        if(!isEmpty($('#taskStatus').val())){
            myTaskStatus = [$('#taskStatus').val()];
        } else {
            myTaskStatus = ['New', 'In Progress', 'Re-opened','Completed', 'Disabled', 'Deleted'];
        }

        var myProjectIds = [];
        if(!isEmpty($('#projectFilter').val())){
            myProjectIds = [$('#projectFilter').val()];
        } else {
            myProjectIds = [];
        }

        var requestObject = {
            "userId": useridval,
            "taskId": "",
            "pageSize":pageSize,
            "pageNumber":taskCurrentPage,
            "projectIds": myProjectIds,
            "taskUserStatus": "",
            "taskStatus": myTaskStatus,
            "clientId":$('#clientFilter').val(),
            "sortOrder":taskSortingOrder,
            "sortBy":taskSortingColumn
        }


        
        var accessTokenIn=sessionStorage.getItem("accToken");
        // const requestOptions = {
        //     method: 'POST',
        //     headers: {
        //     'Content-Type': 'application/json',
        //     'Authorization': "Bearer "+accessTokenIn
        //     },
        //     body: JSON.stringify(requestObject)
        // };
        services.getTasksforUser(requestObject).then((res) => {
            
            if(res.type == 'success'){
                if(res.data != null){
                    setTaskInfoDetails(res.data);
                    var team_ids = [];
                var teamInfo = res.data;
                
                setTaskTotalPages(res.totalNumberOfPages)
                for (var i = 0; i < teamInfo.length; i++) {
                    team_ids.push(teamInfo[i].task_id);
                }
                const requestObject = {
                    "userId": useridval,
                    "projectId": "",
                    "taskStatus": [],
                    "taskName": "",
                    "taskId": team_ids,
                    "pageNumber": 1,
                    "pageSize": 1000,
                    "sortOrder":hoursSortingOrder,
                    "sortBy":hoursSortingColumn
                  };
                 // console.log(requestObject); //return false;
                var accessTokenIn=sessionStorage.getItem("accToken");
                // const requestOptions = {
                //     method: 'POST',
                //     headers: {
                //     'Content-Type': 'application/json',
                //     'Authorization': "Bearer "+accessTokenIn
                //     },
                //     body: JSON.stringify(requestObject)
                // };
                services.getTaskHours(requestObject).then((res) => {
                    //console.log(res.data);
                    if(res.type == 'success'){

                       setTaskHoursListInfo(res.data);
                       var taskhours = res.data

                       const requestObject = {
                        "taskIds":team_ids
                      };
                     // console.log(requestObject); //return false;
                        var accessTokenIn=sessionStorage.getItem("accToken");
                        // const requestOptions = {
                        //     method: 'POST',
                        //     headers: {
                        //     'Content-Type': 'application/json',
                        //     'Authorization': "Bearer "+accessTokenIn
                        //     },
                        //     body: JSON.stringify(requestObject)
                        // };
                       services.getTeamSizewithTaskId(requestObject).then((res) => {

                            if(res.type == 'SUCCESS'){

                                var teamsize = res.data;

                                // Merge the data
                                var combinedData = [];
                                for (var i = 0; i < teamInfo.length; i++) {
                                    for (var j = 0; j < taskhours.length; j++) {
                                        if(teamInfo[i].task_id == taskhours[j].taskId){
                                            for(var k=0; k< teamsize.length; k++){
                                                if(teamsize[k].taskId == teamInfo[i].task_id){
                                                    combinedData.push({
                                                        "id": teamInfo[i].id,
                                                        "task_id": teamInfo[i].task_id,
                                                        "user_id": teamInfo[i].user_id,
                                                        "task_user_status": teamInfo[i].task_user_status,
                                                        "task": teamInfo[i].task,
                                                        "taskId": taskhours[j].taskId,
                                                        "taskName": taskhours[j].taskName,
                                                        "estimatedHours": taskhours[j].estimatedHours,
                                                        "burnedHours": taskhours[j].burnedHours,
                                                        "remainingHours": taskhours[j].remainingHours,
                                                        "description": taskhours[j].description,
                                                        "sprint": taskhours[j].sprint,
                                                        "projectId": taskhours[j].projectId,
                                                        "projectName": taskhours[j].projectName,
                                                        "taskStatus": taskhours[j].taskStatus,
                                                        "createdTime": taskhours[j].createdTime,
                                                        "updatedTime": taskhours[j].updatedTime,
                                                        "users": taskhours[j].users,
                                                        "teamsize": teamsize[k].teamSize
                                                    });
                                                }
                                            }
                                        }
                                    }
                                }
                                
                                setMergedData(combinedData);
                            }

                       })
                       setShowLoader(0);

                    }
                });
                }
            }
        });
    }

    const getProjectTaskDetails = (taskid, projectid) => {
        window.location.href = '/taskDetail?tab=task&tid='+taskid+'&pid='+projectid;
    }

    const [taskIdforUpdate, setTaskIdforUpdate] = useState(null);
    const [showPopup, setShowPopup] = useState(false);
    const CompleteTaskStatus = (taskid) => {
        setTaskIdforUpdate(taskid);
        setShowPopup(true);
    }

    const handleCancel = () => {
        setShowPopup(false);
    }

    const handleCompleteTask = () => {
        setShowLoader(1);
        for(var i=0; i< mergedData.length; i++){
            if(mergedData[i].task_id == taskIdforUpdate){
                

                var requestObject = {
                    "name": mergedData[i].taskName,
                     "project_id": mergedData[i].projectId,
                     "sprint": mergedData[i].sprint,
                     "description": mergedData[i].description,
                     "duration": mergedData[i].task.duration,
                     "estimated_hours": mergedData[i].estimatedHours,
                     "is_billable": mergedData[i].task.is_billable,
                     "task_status": 'Completed',
                     "task_started_time": mergedData[i].task.task_started_time,
                     "task_ended_time": mergedData[i].task.task_ended_time
                 }

                 var accessTokenIn=sessionStorage.getItem("accToken");
                // const requestOptions = {
                //     method: 'PUT',
                //     headers: {
                //     'Content-Type': 'application/json',
                //     'Authorization': "Bearer "+accessTokenIn
                //     },
                //     body: JSON.stringify(requestObject)
                // };

                $(".load-wrap").show(); //console.log(requestObject); return false;
                services.updateTaskInfoById(requestObject, taskIdforUpdate).then((res) => {
                    //setIsOpen(false);
                    setShowPopup(false);
                    $(".load-wrap").show();
                    $("#updatesuccessMessage").fadeTo(2000, 500).slideUp(500, function () {
                        $("#updatesuccessMessage").slideUp(500);
                    });
                    setTimeout(function() { loadTasks(sortTaskConfig.direction, sortTaskConfig.key, sortHoursConfig.direction, sortHoursConfig.key); }, 1000);
                    setShowLoader(0);
                });
            }
        }
    }

    // Pagination Handlers for Projects
    const handleProjectPrevPage = () => {
        if (projectCurrentPage > 1) {
            setProjectCurrentPage(projectCurrentPage - 1);
        }
    };

    const handleProjectNextPage = () => {
        if (projectCurrentPage < projectTotalPages) {
            setProjectCurrentPage(projectCurrentPage + 1);
        }
    };

    // Pagination Handlers for Tasks
    const handleTaskPrevPage = () => {
        if (taskCurrentPage > 1) {
            setTaskCurrentPage(taskCurrentPage - 1);
        }
    };

    const handleTaskNextPage = () => {
        if (taskCurrentPage < taskTotalPages) {
            setTaskCurrentPage(taskCurrentPage + 1);
        }
    };

    const renderProjectPagination = () => {
        const pageNumbers = generatePageNumbers(projectCurrentPage, projectTotalPages);
            if(!isEmpty(pageNumbers)){
                return (
                    <div className="pagination-controls justify-content-end">
                        <button onClick={handleProjectPrevPage} disabled={projectCurrentPage === 1}>
                            « Prev
                        </button>

                        {pageNumbers.map(page => (
                            <button
                                key={page}
                                onClick={() => setProjectCurrentPage(page)}
                                className={page === projectCurrentPage ? 'active' : ''}
                            >
                                {page}
                            </button>
                        ))}

                        <button onClick={handleProjectNextPage} disabled={projectCurrentPage === projectTotalPages}>
                            Next »
                        </button>
                    </div>
                );
            }
        
    };

    const renderTaskPagination = () => {
        console.log("taskTotalPages:"+taskTotalPages)
        const pageNumbers = generatePageNumbers(taskCurrentPage, taskTotalPages);
        if(!isEmpty(pageNumbers)){
            return (
                <div className="pagination-controls justify-content-end">
                    <button onClick={handleTaskPrevPage} disabled={taskCurrentPage === 1}>
                        « Prev
                    </button>
    
                    {pageNumbers.map(page => (
                        <button
                            key={page}
                            onClick={() => setTaskCurrentPage(page)}
                            className={page === taskCurrentPage ? 'active' : ''}
                        >
                            {page}
                        </button>
                    ))}
    
                    <button onClick={handleTaskNextPage} disabled={taskCurrentPage === taskTotalPages}>
                        Next »
                    </button>
                </div>
            );
        }
        
    };

    // Helper function to generate page numbers
    const generatePageNumbers = (currentPage, totalPages) => {
        const maxPageButtons = 5;
        let startPage, endPage;

        if (totalPages <= maxPageButtons) {
            startPage = 1;
            endPage = totalPages;
        } else {
            const maxPagesBeforeCurrent = Math.floor(maxPageButtons / 2);
            const maxPagesAfterCurrent = Math.ceil(maxPageButtons / 2) - 1;

            if (currentPage <= maxPagesBeforeCurrent) {
                startPage = 1;
                endPage = maxPageButtons;
            } else if (currentPage + maxPagesAfterCurrent >= totalPages) {
                startPage = totalPages - maxPageButtons + 1;
                endPage = totalPages;
            } else {
                startPage = currentPage - maxPagesBeforeCurrent;
                endPage = currentPage + maxPagesAfterCurrent;
            }
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };

    const columns = React.useMemo(
        () => [
            {
                Header: (
                    <span onClick={() => handleSort('project_name')}>
                        Project {sortConfig.key === 'project_name' ? (sortConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}
                    </span>
                ),
                accessor: 'project',
                Cell: ({ row }) => (
                    <>
                        {row.original.project.name}
                        {row.original.project_user_status === 'unassigned' ? (
                            <small className="badge rounded-pill bg-success-orange mx-2 font-500 px-2 py-1">Unassigned</small>
                        ) : (
                            ''
                        )}
                    </>
                ),
            },
            {
                Header: (
                    <span onClick={() => handleSort('client_name')}>
                        Client {sortConfig.key === 'client_name' ? (sortConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}
                    </span>
                ),
                accessor: 'client',
                Cell: ({ row }) => row.original.project.client.client_name,
            },
            {
                Header: (
                    <span onClick={() => handleSort('project_status')}>
                        Status {sortConfig.key === 'project_status' ? (sortConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}
                    </span>
                ),
                accessor: 'status',
                Cell: ({ row }) => (
                    <>
                        {row.original.project.project_status === 1 ? (
                            <>
                               {/* <img src="images/active.svg" alt="Active" /> */} Active
                            </>
                        ) : (
                            <>
                               {/* <img src="images/redcheck.svg" width={15} alt="Inactive" /> */}Inactive
                            </>
                        )}
                    </>
                ),
            },
        ],
        [sortConfig]
    );

    const data = React.useMemo(() => projectListDetails, [projectListDetails]);

    // Data memoization and optional sorting state
    // const data = React.useMemo(() => {
    //     // Sort the data manually using the sortConfig (if required)
    //     if (sortConfig.key) {
    //         return [...projectListDetails].sort((a, b) => {
    //             const aValue = a.project[sortConfig.key];
    //             const bValue = b.project[sortConfig.key];
    //             if (aValue < bValue) {
    //                 return sortConfig.direction === 'asc' ? -1 : 1;
    //             }
    //             if (aValue > bValue) {
    //                 return sortConfig.direction === 'asc' ? 1 : -1;
    //             }
    //             return 0;
    //         });
    //     }
    //     return projectListDetails;
    // }, [projectListDetails, sortConfig]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        { columns, data }
    );


const taskcolumns2 = React.useMemo(
    () => [
        {
            Header: (
                <span onClick={() => handleTaskSort('taskName')}>
                    Task {sortHoursConfig.key === 'taskName' ? (sortHoursConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}
                </span>
            ),
            accessor: 'taskName',
            Cell: ({ row }) => (
                <div className="projecttask">
                    <span>
                        <a href="#" onClick={() => getProjectTaskDetails(row.original.task_id, row.original.projectId)}>
                            {row.original.task.name}
                        </a>
                    </span>
                    {/* <span className="badge rounded-pill project-badge text-dark my-2">
                        {row.original.projectName}
                    </span> */}
                </div>
            ),
            width: '338px',
        },
        {
            Header: (
                <span onClick={() => handleTaskSort('projectName')}>
                    Project {sortHoursConfig.key === 'projectName' ? (sortHoursConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}
                </span>
            ),
            accessor: 'projectName',
            width: '130px',
            Cell: ({ row }) => <div className="text-left">{row.original.projectName}</div>,
        },
        {
            Header: (
                <span onClick={() => handleTaskSort('sprint')}>
                    Sprint {sortHoursConfig.key === 'sprint' ? (sortHoursConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}
                </span>
            ),
            accessor: 'sprint',
            width: '110px',
            Cell: ({ value }) => <div className="text-left">{value}</div>,
        },
        {
            Header: (
                <span onClick={() => handleTaskSort('estimatedHours')}>
                    Estimated Hrs {sortHoursConfig.key === 'estimatedHours' ? (sortHoursConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}
                </span>
            ),
            accessor: 'estimatedHours',
            width: '140px',
            Cell: ({ row }) => (
                <div className="text-left">
                    {parseInt(row.original.teamsize) > 1 ? '' : (parseInt(row.original.estimatedHours) > 0 ? (row.original.estimatedHours).slice(0, -3) : '' )}
                </div>
            ),
        },
        {
            Header: (
                <span onClick={() => handleTaskSort('burnedHours')}>
                    Spent Hrs {sortHoursConfig.key === 'burnedHours' ? (sortHoursConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}
                </span>
            ),
            accessor: 'userBurnedHours',
            width: '110px',
            Cell: ({ row }) => (
                <div className="text-left">{(parseInt(row.original.users[0].userBurnedHours) > 0 ? (row.original.users[0].userBurnedHours).slice(0, -3) : '')}</div>
            ),
        },
        {
            Header: (
                <span onClick={() => handleTaskSort('remainingHours')}>
                    Balance Hrs {sortHoursConfig.key === 'remainingHours' ? (sortHoursConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}
                </span>
            ),
            accessor: 'remainingUserHours',
            width: '120px',
            Cell: ({ row }) => (
                <div className="text-left">
                    {parseInt(row.original.teamsize) > 1 ? '' : (parseInt(row.original.users[0].remainingUserHours) > 0 ? (row.original.users[0].remainingUserHours).slice(0,-3) : '')}
                </div>
            ),
        },
        {
            Header: (
                <span onClick={() => handleTaskSort('task_status')}>
                    Status {sortHoursConfig.key === 'task_status' ? (sortHoursConfig.direction === 'ASC' ? '▲' : '▼') : '↕️'}
                </span>
            ),
            accessor: 'task_status',
            width: '110px',
            Cell: ({ row }) => (
                <div className="d-grid text-left">
                    {/* <img
                              src={`images/${row.original.task.task_status.toLowerCase().replace(/\s+/g, '-')}.svg`}
                              className="m-auto"
                              alt="" height="20"
                          />  */}
                    {/* <span>{row.original.task.task_status !== 'New' ? row.original.task.task_status : ''
 }</span> */}
            <span>{row.original.task.task_status}</span>
                </div>
            ),
        },
        /*{
            Header: 'Actions',
            accessor: 'actions',
            width: '90px',
            Cell: ({ row }) => (
                <div className="text-left">
                    {parseInt(row.original.teamsize) > 1 ? (
                        ''
                    ) : (row.original.task.task_status === 'New' ||
                        row.original.task.task_status === 'Re-opened' ||
                        row.original.task.task_status === 'In Progress' ||
                        row.original.task.task_status === 'In progress') ? (
                        <a href="#" className="btn btn-outline-primary scale85" onClick={() => CompleteTaskStatus(row.original.task.id)}>
                            Complete
                        </a>
                    ) : (
                        ''
                    )}
                </div>
            ),
            sortType: 'basic', // Enable sorting for Task Name
                    disableSortBy: true,
        }, */
    ],
    [sortHoursConfig]
);

const {
    getTableProps: getTaskTableProps2,
        getTableBodyProps: getTaskTableBodyProps2,
        headerGroups: taskHeaderGroups2,
        rows: taskRows2,
        prepareRow: prepareTaskRow2,
} = useTable(
    {
        columns: taskcolumns2,
        data: mergedData, // Replace with your actual data source
    },
    //useSortBy
);

  return(
  <>
   <main>
      <section>
         <div class="container">
            <h2 class="mainHeadline">Projects</h2>

            <div class="tab-primary">
               <ul class="nav nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a ref={projectLinkRef} class="nav-link bg-none active" data-bs-toggle="tab" href="#menu1">Projects</a>
                  </li>
                  <li class="nav-item">
                    <a  ref={taskLinkRef}  class="nav-link bg-none" id="taskLink" data-bs-toggle="tab" href="#menu2">Tasks </a>
                  </li>
               </ul>

               <div class="tab-content ">
                  <div id="menu1" class="container tab-pane fade active px-0 show">
                    <div class="d-flex align-items-center justify-content-between pt-2 pb-2">
                        <div class="d-flex justify-content-end gap-4 align-items-center w-100">
                            <div class="d-flex justify-content-end align-items-center  ">
                                <span style={{"min-width": "69px"}}>Client:</span>
                                <select class="form-select" id="projectClientFilter" onChange={handleProjectClientFilter} value={projectClientSelected} style={{"max-width": "200px"}}>
                                    <option value="" selected>All</option>
                                    {clientInfoDetails.map((clientval, index) => (
                                        <option value={clientval.id}>{clientval.client_name}</option>
                                    ))}

                                </select>
                            </div>
                            <div class="d-flex justify-content-end align-items-center  ">
                                <span style={{"min-width": "130px"}}>Project Status:</span>
                                <select class="form-select" id="projectStatus" onChange={handleProjectStatus} value={statusForProject} style={{"max-width": "200px"}}>
                                    <option value="" selected>All</option>
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>

                                </select>
                            </div>
                        </div>
                    </div>
                     <div class="tbl-container mt-0 bdr mb-3">
                     <table className="table mb-0" {...getTableProps()}>
            <thead className="bg-lightblue">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()} style={{ width: column.width,
                                position: 'relative',

                                cursor: 'pointer', }} // Show pointer cursor for sortable columns
                            >{column.render('Header')}</th>
                        ))}
                    </tr>
                ))}
            </thead>
            {rows.length > 0 ? (
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            ) : (
                <tbody>
                    <tr>
                        <td colSpan={3} className="text-center py-2">
                            <div className="d-grid text-center py-3">
                                <img src="images/emptybox.svg" className="m-auto" alt="" width={128} />
                                <span>No projects available.</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            )}
        </table>
                     {/* <table className="table mb-0" {...getTableProps()}>
            <thead className="bg-lightblue">
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th
                                {...column.getHeaderProps(column.getSortByToggleProps())}
                                style={{ width: column.width,
                                    position: 'relative',

                                    cursor: 'pointer', }} // Show pointer cursor for sortable columns
                            >
                                {column.render('Header')}
                                <span
                                    style={{
                                        //position: 'absolute',
                                        paddingLeft: '10px',
                                        right: '10px',
                                        top: '50%',
                                        //transform: 'translateY(-50%)',
                                        display: 'inline-block',
                                        fontSize: '0.8em',
                                        color: '#888',
                                    }}
                                >
                                    {
                                        !column.disableSortBy ? (
                                            column.isSorted ? (
                                                column.isSortedDesc ? ' 🔽' : ' 🔼'
                                            ) : '↕️'
                                        ) : null
                                    }
                                </span>
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            {rows.length > 0 ? (
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            ) : (
                <tbody>
                    <tr>
                        <td colSpan={3} className="text-center py-2">
                            <div className="d-grid text-center py-3">
                                <img src="images/emptybox.svg" className="m-auto" alt="" width={128} />
                                <span>No projects available.</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            )}
        </table> */}

                    </div>
                    {renderProjectPagination()}
                </div>
                <div id="menu2" class="container tab-pane px-0 fade">
                <div class="d-flex align-items-center justify-content-between pt-2 pb-2">
                        <div class="d-flex justify-content-end gap-4 align-items-center w-100">
                            <div class="d-flex justify-content-end align-items-center  ">
                                <span style={{"min-width": "69px"}}>Client:</span>
                                <select class="form-select" id="clientFilter" onChange={handleClientFilter} value={clientSelected} style={{"max-width": "200px"}}>
                                    <option value="" selected>All</option>
                                    {clientInfoDetails.map((clientval, index) => (
                                        <option value={clientval.id}>{clientval.client_name}</option>
                                    ))}

                                </select>
                            </div>
                            <div class="d-flex justify-content-end align-items-center  ">
                                <span style={{"min-width": "69px"}}>Project:</span>
                                <select class="form-select" id="projectFilter" onChange={handleProjectFilter} value={projectSelected} style={{"max-width": "200px"}}>
                                    <option value="" selected>All</option>
                                    {projectListFilterDetails.map((projectval, index) => (
                                        <option value={projectval.project.id}>{projectval.project.name}</option>
                                    ))}

                                </select>
                            </div>
                            <div class="d-flex justify-content-end align-items-center  ">
                                <span style={{"min-width": "105px"}}>Task Status:</span>
                                <select class="form-select" id="taskStatus" onChange={handleTaskStatus} value={statusForTask} style={{"max-width": "200px"}}>
                                    <option value="" selected>All</option>
                                    <option value="New">New</option>
                                    <option value="In Progress">In Progress</option>
                                    <option value="Re-opened">Re-opened</option>
                                    <option value="Completed">Completed</option>
                                    <option value="Disabled">Disabled</option>
                                    <option value="Deleted">Deleted</option>

                                </select>
                            </div>
                        </div>
                    </div>
                     <div class="tbl-container mt-0 bdr mb-3">


                                <table {...getTaskTableProps2()} className="table mb-0">
                                    <thead className="bg-lightblue">
                                        {taskHeaderGroups2.map(headerGroup => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {headerGroup.headers.map(column => (
                                                    <th
                                                        {...column.getHeaderProps()}
                                                        style={{
                                                            width: column.width,
                                                            position: 'relative',

                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        {column.render('Header')}
                                                        {/* <span
                                                            style={{
                                                                //position: 'absolute',
                                                                paddingLeft: '10px',
                                                                right: '10px',
                                                                top: '50%',
                                                                //transform: 'translateY(-50%)',
                                                                display: 'inline-block',
                                                                fontSize: '0.8em',
                                                                color: '#888',
                                                            }}
                                                        >
                                                            {
                                                    !column.disableSortBy ? (
                                                        column.isSorted ? (
                                                            column.isSortedDesc ? ' 🔽' : ' 🔼'
                                                        ) : '↕️'
                                                    ) : null
                                                }
                                                        </span> */}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                
                                    <tbody {...getTaskTableBodyProps2()}>
                                        {taskRows2.length > 0 ? (
                                            taskRows2.map(row => {
                                                prepareTaskRow2(row);
                                                return (
                                                    <tr {...row.getRowProps()}>
                                                        {row.cells.map(cell => (
                                                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                        ))}
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td colSpan="6">
                                                <div className="d-grid text-center py-3">
                        <img src="images/emptybox.svg" class="m-auto" alt="" width={128} />
                        <span> No tasks available</span>
                        </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                    </div>
                    {renderTaskPagination()}
                    {showPopup && (
                                    <DeleteConfirmationPopup
                                    message="Are you sure you want to Complete this task?"
                                    onConfirm={handleCompleteTask}
                                    onCancel={handleCancel}
                                    />
                              )}

                </div>
                {showLoader === 1 ?
                <div class="loadwrapper">
                    <div class="clock"><div class="circle"></div></div>
                </div>
                : '' }
            </div>
        </div>
    </div>
         </section>

      </main>
  </>
)};

Projects.propTypes = {};

Projects.defaultProps = {};

export default Projects;
